import React, { useState } from "react";
import { Tag, Button } from "antd";
import { HomeIcon } from "@heroicons/react/solid";
import { TestResult } from "generated/schema";

interface Props {
  children: any;
  text: any;
}
export const ShowMore = ({ children, text }: Props) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div>
      <div className=" space-x-2">
        <div>{text}</div>

        {showMore && <>{children}</>}

        <div
          onClick={() => setShowMore(!showMore)}
          className="text-blue-400 hover:text-blue-600 cursor-pointer"
        >
          {showMore ? "weniger" : "Anzeigen"}
        </div>
      </div>
    </div>
  );
};
