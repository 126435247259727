import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/solid";
import { RegisterCustomerForm } from "components/Customer/RegisterCustomerForm";
export const BookPage = () => {
  const r = [{ to: "/test-results" }, { to: "/test-centers" }];
  return (
    <div className="bg-green-50">
      <div className="p-2 h-screen ">
        <div className="p-2 w-full ml-auto mr-auto lg:w-6/12   ">
          {/* <RegisterCustomerForm /> */}

          <Test />
        </div>
      </div>
    </div>
  );
};

const TestDetails = () => {
  const [showBox, setShowBox] = useState(false);
  return (
    <div>
      <div
        onClick={() => setShowBox(!showBox)}
        className="inline-flex items-center text-xs font-normal text-gray-500 hover:underline dark:text-gray-400 hover:text-indigo-400"
      >
        <svg
          className="mr-2 w-3 h-3"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="question-circle"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"
          ></path>
        </svg>
        Weitere Informationen
      </div>

      {showBox && (
        <div className="text-sm  bg-gray-100 p-2 font-normal ">
          <ul className="divide-y-2 divide-white">
            <li>Kinder unter 6 Jahren</li>
            <li>Aus med. Gründen (z.B. Schwangere)</li>
            <li>Studienteilnehmer</li>
            <li>Frei-Testung (Beendigung der Isolierung)</li>
            <li>Besucher (z.B. von Krankenhäusern und Pflegeheimen</li>
            <li>Leistungsberechtigte gemäß § 29 SGB</li>
            <li>Pflegepersonen gemäß § 19 Satz 1 XI SGB</li>
            <li>Personen im selben Haushalt von infizierten</li>
          </ul>
        </div>
      )}
    </div>
  );
};

const Test = () => {
  const tests = [
    { name: "Bürgertest", price: "0,00 €" },
    { name: "Ausnahmen Bürgertest mit Eigenanteil", price: "0,00 €" },
    { name: "PCR-Bestätigungstest-OEG", price: "0,00 €" },
  ];
  return (
    <div>
      <div className="mt-20 mb-6 ">
        <h1 className="text-xl font-medium m-0">Terminbuchung</h1>
        <h1 className="text-lg font-medium text-gray-400">
          Hier können Sie sich ein Termin Buchen
        </h1>
      </div>
      <div className="grid grid-cols-0 md:grid-cols-2 gap-1">
        {tests.map((x) => {
          return (
            <div className="hover:cursor-pointer p-4 flex items-center w-full  bg-white rounded-lg  shadow-sm font-medium hover:bg-indigo-100 duration-100 ">
              <div className="flex-1 text-base">
                <div>{x.name}</div>

                <div>
                  <TestDetails />
                </div>
              </div>
              <div>
                <span className="inline-flex items-center justify-center px-2 py-0.5 ml-3 text-black  bg-gray-200  hover:bg-gray-100 rounded dark:bg-gray-700 dark:text-gray-400">
                  9,00 €
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
