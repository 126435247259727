import { Button, Modal } from "antd";
import React, { useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { UserGroupsSelect } from "./UserGroupsSelect";

import { TestCenterSelect } from "./TestCenterSelect/index";
import { ActiveSelect } from "./ActiveSelect";
import { RoleFilter } from "./RoleFilter";
import { Input } from "antd";
import { TestIdFilter } from "./TestIdFilter";
import { TestCustomerNameFilter } from "./TestCustomerNameFilter";
import { TestOptionTypeSelect } from "./TestTypeOptionSelect";
import { EmailFilter } from './EmailFilter/index';
interface Props {
  filters: any;
  setFilters: any;
}
export const TableFilters = ({ filters, setFilters }: Props) => {
  const onFilterChange = (v: any) => {
    if (v?.delete == true) {
      const newFilters = filters.filter(
        (x: any) => x?.fieldName != v?.fieldName
      );
      setFilters(newFilters);
      return;
    }

    const newFilters = filters.filter((x: any) => x?.fieldName != v?.fieldName);
    newFilters.push(v);

    setFilters(newFilters);
    return;
  };
  return (
    <>
      <div className="flex space-x-2 w-full overflow-auto bg-gray-100 p-2">
        <TestIdFilter onChange={onFilterChange} />
        {/* <TestCustomerNameFilter onChange={onFilterChange} />
        <EmailFilter onChange={onFilterChange} />

        <UserGroupsSelect onChange={onFilterChange} />
        <RoleFilter onChange={onFilterChange} /> */}
        
      </div>
    </>
  );
};
