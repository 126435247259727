import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "layout/Page";

import { PageBreadcrumb } from "components/PageBreadcrumb";
import { AdminMenu } from "components/AdminPageMenu";

import { FilesUploadTableWrapper } from "components/FilesUpload/index";
export const FilesUploadPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <AdminMenu
            defaultValue="logos"
            onChange={() => console.log("")}
          />
          <PageBreadcrumb value={["Files-Upload"]} />

          <FilesUploadTableWrapper />
        </div>
      </PageWrapper>
    </div>
  );
};
