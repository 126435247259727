import { Select } from "antd";
import React from "react";
import { useTestCentersQuery } from "generated/schema";
const { Option } = Select;

interface Props {
  onChange: any;
}
export const LandKreisFilter = ({ onChange }: Props) => {
  const { data, loading, error } = useTestCentersQuery({
    fetchPolicy: "network-only",
  });

  const handleChange = (value: string) => {
    if (!value) {
      onChange({
        fieldName: "testCenter.landkreis",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "testCenter.landkreis",
      term: String(value),
      exact: true,
    };

    onChange(filter);
  };

  const landkreisValues: any =
    data?.testCenters.map((x) => x?.landkreis).filter(Boolean) || [];

  const values = landkreisValues.filter(
    (item: any, index: any) => landkreisValues.indexOf(item) === index
  );

  return (
    <>
      <Select
        allowClear
        placeholder="Landkreis"
        loading={loading}
        style={{ minWidth: 200 }}
        onChange={handleChange}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => {
          return ((option?.children as unknown as string) || "")
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      >
        {(values || []).map((x: any) => {
          return <Option value={x}>{x}</Option>;
        })}
      </Select>
    </>
  );
};
