import { WorkTime } from "generated/schema";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment-duration-format";
import { Divider, List, Typography } from "antd";
import "moment/locale/de";
moment.locale("de");
interface Props {
  workTime: WorkTime;
}
export const WorkTimeSummary = (workTime: Props) => {
  const startAt = workTime?.workTime?.startAt;
  const userName = workTime?.workTime.user?.name;
  const startAtMs = moment(startAt).format("LLL");

  const data = [
    { title: "Mitarbeiter", v: userName },
    { title: "Eingestochen um", v: startAtMs },
    { title: "Testzentrum", v: workTime?.workTime?.testCenter?.name },
  ];

  return (
    <div className="">
      <Divider orientation="left">Information zur Zeiterfassung</Divider>
      <List
        style={{ width: "100%" }}
        size="small"
        bordered={false}
        dataSource={data}
        renderItem={(item) => (
          <div className="w-full">
            <div className="flex">
              <div className="flex-1">{item?.title}</div>
              <div className="flex-1">{item?.v}</div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
