import { useEffect, useState, createContext } from "react";
import {
  RightsEnumType,
  TestResult,
  TestType,
  useLoginLazyQuery,
  useWhoAmILazyQuery,
  useWhoAmIQuery,
  useWorkTimeCurrentUserLazyQuery,
  WorkTime,
} from "generated/schema";

import { useNavigate } from "react-router-dom";
import { WorkTime as WorkTimeComponent } from "components/WorkTime";
import { useGeolocated } from "react-geolocated";
import { RightsMap } from "const/rights";
interface AppContextInterface {
  activeTestCenter: any;
  setActiveTestCenter: any;
  setSelectedTestResult: any;
  user?: any;
  loading?: boolean;
  userRequested?: boolean;
  userRightIds?: any;
  userRightsObj: any;
  lng: any;
  lat: any;
  workTime: WorkTime | null;
  hasUserRight: any;
  selectedTestResult?: TestResult | null;
}

export const AppCtx = createContext<AppContextInterface | null>(null);

export const AppStateProvider = ({ children }: any) => {
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 0,
  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        ...options,
      },
      userDecisionTimeout: 5000,
    });

  const [currentUser, setCurrentUser]: any = useState();
  const [selectedTestResult, setSelectedTestResult]: any = useState();
  const [userRequested, setUserRequested] = useState(false);
  const { data, error, loading } = useWhoAmIQuery({ fetchPolicy: "no-cache" });

  const [
    getData,
    { data: workTimeData, loading: workTimeLoading, error: workTimeError },
  ] = useWorkTimeCurrentUserLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setCurrentUser(data?.whoAmI);
    setUserRequested(true);
    getData();
  }, [data]);

  // Provider in your app
  const activeTestCenterFromStorage = localStorage.getItem("activeTestCenter");

  let json = {};
  if (activeTestCenterFromStorage) {
    try {
      json = JSON.parse(activeTestCenterFromStorage);
    } catch (e) {
      console.log(e);
    }
  }

  const [activeTestCenter, setActiveTestCenter] = useState(json);

  useEffect(() => {
    if (activeTestCenter) {
      localStorage.setItem(
        "activeTestCenter",
        JSON.stringify(activeTestCenter)
      );
    } else {
      localStorage.removeItem("activeTestCenter");
    }
  }, [activeTestCenter]);

  const location = window.location.pathname;

  // if (!loading && data && location != "/login") {
  //   window.location.href = "/login";
  // }

  if (location != "/login" && error) {
    window.location.href = "/login";
  }

  const passwordChangeRequired = data?.whoAmI?.passwordChangeRequired;

  if (location != "/center-select" && passwordChangeRequired) {
    window.location.href = "/center-select";
  }

  if (
    location != "/center-select" &&
    location != "/login" &&
    !activeTestCenter &&
    !workTimeData
  ) {
    window.location.href = "/center-select";
  }

  // if (location != "/login" && userRequestDone) {
  //   window.location.href = "/login";
  // }

  const user = { ...data?.whoAmI };

  const userRightsObj: any = {};
  const userRightIds = user?.role?.rights?.map((x) => {
    userRightsObj[x.id] = x;

    return x.id;
  });

  const workTimes = workTimeData?.workTimeCurrentUser;
  const workTimeCurrentUser = (workTimes?.length && workTimes[0]) || null;

  const hasUserRight = (rightEnumType: RightsEnumType) => {
    return userRightsObj[RightsMap[rightEnumType]];
  };

  const sampleAppContext: AppContextInterface = {
    activeTestCenter,
    setActiveTestCenter,
    user: data?.whoAmI,
    loading,
    userRequested,
    userRightIds,
    userRightsObj,
    lat: coords?.latitude,
    lng: coords?.longitude,
    workTime: workTimeCurrentUser,
    hasUserRight,
    selectedTestResult,
    setSelectedTestResult,
  };

  if (loading) {
    return <div></div>;
  }
  if (loading) return <div>ss</div>;

  return <AppCtx.Provider value={sampleAppContext}>{children}</AppCtx.Provider>;
};
