import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { LogEvent, TestCustomer, User } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { TableTestStateButton } from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { LogDiffModal } from "./LogDiffModal";
export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 75,
  },

  {
    title: "Beschreibung",
    dataIndex: "name",
    key: "name",
    width: 600,
    render: (text: string, row: LogEvent) => {
      const userName = `${row?.user?.name}`;
      const name = `${row?.description}`;
      return <div> [{userName}] {name}</div>;
    },
  },

  {
    title: "User",
    dataIndex: "user",
    key: "user",
    width: 200,
    render: (text: string, row: LogEvent) => {
      const name = `${row?.user?.name}`;
      return <div>{name}</div>;
    },
  },

  {
    title: "Zeitstempel",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 200,
    render: (text: string, row: LogEvent) => {
      const name = `${formatDate(row?.createdAt)}`;
      return <div>{name}</div>;
    },
  },

  {
    title: "Gerät",
    dataIndex: "userAgent",
    key: "userAgent",
    width: 500,

    render: (text: any, row: LogEvent) => {
      const diff = text;

      return <div className="text-xs">{diff}</div>;
    },
  },
  {
    title: "Location",
    dataIndex: "lat",
    key: "lat",
    width: 125,

    render: (text: any, row: LogEvent) => {
      const diff = text;
      const lat = row?.lat;
      const lng = row?.lng;

      if (!lat) return <div>-</div>;
      if (!lng) return <div>-</div>;
      const url = "https://www.google.de/maps/@" + lat + "," + lng + ",19z";

      return (
        <Button size="small" type="default">
          <a target="_blank" href={url}>
            Google Maps
          </a>
        </Button>
      );
    },
  },

  {
    title: "Diff",
    dataIndex: "difference",
    key: "diff",

    render: (text: any, row: LogEvent) => {
      const diff = row?.diff;

      if (!diff) return <div>-</div>;
      return <LogDiffModal diffString={diff} />;
    },
  },
];
