import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
  Radio,
  Alert,
} from "antd";

import styled from "styled-components";

import {
  User,
  useCreateUserMutation,
  useChangePasswordMutation,
} from "generated/schema";

import { validatePassword } from "utils/validatePassword";
const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  user: User;
  onClose?: any;
}

export const ChangePasswordForm = ({ user, onClose }: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [form] = Form.useForm();
  const [createUser, { data, loading }] = useCreateUserMutation();
  const [changePassword, updateReq] = useChangePasswordMutation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    // const fixTestCenterId =
    //   (values?.fixTestCenterId && values?.fixTestCenterId) || 0;

    // values.fixTestCenterId = fixTestCenterId;

    // if (values.workerId) {
    //   values.workerId = String(values.workerId);
    // }

    const abc = await changePassword({
      variables: { input: { password: values?.password } },
    });

    window.location.reload();

    success();
    onClose && onClose();
  
  };

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      scrollToFirstError
    >
      <div className="">
        <Form.Item
          name="password"
          label="Passwort"
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                const {
                  isValid,
                  textLengthValid,
                  containsOneDigit,
                  containsSpecialCharacters,
                  containsCapitalLetter,
                } = validatePassword(value);

                if (isValid) {
                  return Promise.resolve();
                } else {
                  if (!containsOneDigit)
                    return Promise.reject("Fehler : Enthält keine Zahl");

                  if (!containsSpecialCharacters)
                    return Promise.reject("Fehler: Enthält kein Sonderzeichen");

                  if (!containsCapitalLetter)
                    return Promise.reject(
                      "Fehler: Enthält kein Großbuchstaben"
                    );

                  if (!textLengthValid)
                    return Promise.reject("Fehler: Zeichenlänge falsch");
                }
              },
            },
          ]}
        >
          <Input.Password allowClear placeholder="Passwort" />
        </Form.Item>

        <div className="mb-2">
          <Alert
            message={
              <div>
                Das Kennwort muss mind. 12 Zeichen lang sein! Das Kennwort muss
                mindestens eine Ziffer, einen Großbuchstaben, einen
                Kleinbuchstaben und ein Sonderzeichen enthalten!
              </div>
            }
          />
        </div>
      </div>

      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
