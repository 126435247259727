import React, { useEffect, useState, useContext, useRef } from "react";
import { TestCenterSelectTimes } from "./TestCenterSelectTimes";
import moment from "moment";

import { Alert, Button, Checkbox, Space, Spin, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CalenderWeekSelect } from "./CalenderWeekSelect";
import { TestCenter } from "generated/schema";
import { Typography, message } from "antd";

import {
  useCreateOpeningTimeMutation,
  useOpeningTimeLazyQuery,
  useUpdateOpeningTimeMutation,
} from "generated/schema";
import { CreateNextCalenderWeek } from "./CreateCalenderWeek";

const { Title } = Typography;

const info = () => {
  message.success("Erfolgreich");
};

const SlotSelect = ({
  children,
  onChange,
  row,
  day,
  openingTimes,
  checkboxRefs,
}: any) => {
  const ref = useRef(null);
  const dayArray = (openingTimes[day - 1] && openingTimes[day - 1]) || [];

  const defaultChecked = dayArray.find((x: any) => x == row?.slotName);

  const [checked, setChecked] = useState(defaultChecked);
  const handleOnChange = (e: any) => {
    const b = e?.target?.checked;
    setChecked(b);
    const prefix = b ? "BOOKED-" : "NOT_BOOKED-";
    const key = prefix + day + "-" + row?.slotName;

    const data = { key, booked: b, data: row, day };
    onChange(data);
  };

  if (checkboxRefs.current) {
    checkboxRefs.current[day + "-" + row?.slotName] = handleOnChange;
  }

  return (
    <Checkbox ref={ref} checked={checked} onChange={handleOnChange}>
      <div className="text-center w-24">{children}</div>
    </Checkbox>
  );
};

export const OpeningTimesEditor = () => {
  const [createOpeningTimeMutation, { data, loading, error }] =
    useCreateOpeningTimeMutation();

  const [updateOpeningTimes, updatedOpeningTimeReq] =
    useUpdateOpeningTimeMutation({ fetchPolicy: "no-cache" });

  const [
    getOpeningTimes,
    { data: openingTimesData, loading: openingTimeLoading },
  ] = useOpeningTimeLazyQuery({ fetchPolicy: "no-cache" });

  const activeSlots: any = useRef({});
  const checkboxRefs: any = useRef({});

  const cw = moment().format("w");
  const [calenderWeek, setCalenderWeek] = useState(cw);
  const [reloadKey, setReloadKey] = useState(0);
  const [selectedOpeningTime, setSelectedOpeningTime]: any = useState();
  const [selectedTestCenter, setSelectedTestCenter]: any = useState();

  useEffect(() => {
    if (selectedTestCenter) {
      getOpeningTimes({
        fetchPolicy: "no-cache",
        variables: {
          testCenterId: selectedTestCenter.id,
          calendarWeek: parseInt(calenderWeek),
        },
      });
    }
  }, [selectedTestCenter, calenderWeek]);

  var m = moment().utcOffset(0);
  m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const openingSlots = [];

  for (let i = 0; i < 96; i++) {
    const mFormat = m.format("HH:mm");
    openingSlots.push(mFormat);
    m.add(15, "minutes");
  }

  const tableData = openingSlots.map((x) => {
    return {
      slotName: x,
    };
  });

  const openingTimes = openingTimesData?.openingTime?.data || [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ];

  const handleOnSave = () => {
    const slots = activeSlots.current || {};

    const newOpeningTimes = openingTimes;
    Object.values(slots).forEach((x: any) => {
      const day = x?.day;
      const slotName = x?.data?.slotName;
      const booked = x?.booked;

      if (booked) {
        newOpeningTimes[day - 1].push(slotName);
      }

      if (!booked) {
        const d = openingTimes[day - 1].filter((x: any) => x != slotName);
        newOpeningTimes[day - 1] = d;
      }
    });

    if (
      openingTimesData?.openingTime?.id &&
      parseInt(calenderWeek) == openingTimesData?.openingTime?.calendarWeek
    ) {
      // update
      const id = openingTimesData.openingTime.id;
      updateOpeningTimes({
        variables: {
          id,
          input: {
            data: newOpeningTimes,
          },
        },
      });
    } else {
      if (selectedTestCenter) {
        createOpeningTimeMutation({
          variables: {
            input: {
              testCenterId: selectedTestCenter.id,
              calendarWeek: parseInt(calenderWeek),
              data: newOpeningTimes,
            },
          },
        });
      }
    }

    info();
  };

  const today = moment();
  const from_date = today.startOf("week").format("DD.MM.YYYY");
  const to_date = today.endOf("week").format("DD.MM.YYYY");

  const onSelectSlot = (e: any, row: any) => {
    if (activeSlots?.current) {
      if (e?.key) {
        activeSlots.current[e.key] = e;
      }
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Alle",
      dataIndex: "",
      key: "name",

      render: (text, row: any) => {
        return (
          <Checkbox
            onChange={(e: any) => {
              const checked = e?.target?.checked;
              for (let day = 1; day < 8; day++) {
                if (checkboxRefs.current) {
                  const ref = checkboxRefs.current[day + "-" + row?.slotName];

                  if (ref) {
                    const e = { target: { checked } };
                    ref(e);
                  }
                }
              }
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: "Uhrzeit",
      dataIndex: "slotName",
      key: "name",

      render: (text) => <a className="w-24">{text}</a>,
    },
    {
      title: "Montag",
      dataIndex: "age",
      key: "age",

      render: (_, row: any) => {
        return (
          <SlotSelect
            checkboxRefs={checkboxRefs}
            openingTimes={openingTimes}
            day={1}
            row={row}
            onChange={onSelectSlot}
          ></SlotSelect>
        );
      },
    },
    {
      title: "Dienstag",
      dataIndex: "Mittwoch",
      key: "address",

      render: (_, row: any) => {
        return (
          <SlotSelect
            checkboxRefs={checkboxRefs}
            openingTimes={openingTimes}
            day={2}
            row={row}
            onChange={onSelectSlot}
          ></SlotSelect>
        );
      },
    },

    {
      title: "Mittwoch",
      dataIndex: "Mittwoch",
      key: "address",
      render: (_, row: any) => {
        return (
          <SlotSelect
            checkboxRefs={checkboxRefs}
            openingTimes={openingTimes}
            day={3}
            row={row}
            onChange={onSelectSlot}
          ></SlotSelect>
        );
      },
    },

    {
      title: "Donnerstag",
      dataIndex: "Mittwoch",
      key: "address",

      render: (_, row: any) => {
        return (
          <SlotSelect
            checkboxRefs={checkboxRefs}
            openingTimes={openingTimes}
            day={4}
            row={row}
            onChange={onSelectSlot}
          ></SlotSelect>
        );
      },
    },

    {
      title: "Freitag",
      dataIndex: "Mittwoch",
      key: "address",

      render: (_, row: any) => {
        return (
          <SlotSelect
            checkboxRefs={checkboxRefs}
            openingTimes={openingTimes}
            day={5}
            row={row}
            onChange={onSelectSlot}
          ></SlotSelect>
        );
      },
    },

    {
      title: "Samstag",
      dataIndex: "Mittwoch",
      key: "address",

      render: (_, row: any) => {
        return (
          <SlotSelect
            checkboxRefs={checkboxRefs}
            openingTimes={openingTimes}
            day={6}
            row={row}
            onChange={onSelectSlot}
          ></SlotSelect>
        );
      },
    },

    {
      title: "Sonntag",
      dataIndex: "Mittwoch",
      key: "address",

      render: (_, row: any) => {
        return (
          <SlotSelect
            checkboxRefs={checkboxRefs}
            openingTimes={openingTimes}
            day={7}
            row={row}
            onChange={onSelectSlot}
          ></SlotSelect>
        );
      },
    },
  ];

  return (
    <div className="p-2 pt-0 lg:-mt-4">
      <div className=" ml-auto mr-auto  lg:w-8/12">
        <div className="pt-4 mb-6">
          <Title level={3}>Terminplaner</Title>
          <div className="-mt-2">
            Wenn Sie die Zeitslots aktivieren (Kontrollkästchen aktivieren) so
            sind diese über die Termin-App für die Kunden buchbar.
            <div>
              Wenn Sie zum Beispiel Kalenderwoche 1 ausgefüllt haben und
              Kalenderwoche 2 nicht und ein Kunde einen Termin in Kalenderwoche
              2 bucht, gelten die Termine aus der Kalenderwoche 1. Analog mit anderen Kalenderwochen.
            </div>
          </div>

          <div className="mt-2">
            <Alert
              message="Bitte nach Änderung am Ende der Seite auf Speichern drücken"
              type="info"
            />
          </div>
        </div>

        <div className="flex space-x-4">
          <TestCenterSelectTimes
            onChange={(e: TestCenter) => {
              setSelectedTestCenter(e);
              activeSlots.current = {};
            }}
          />

          {true && selectedTestCenter && (
            <div key={reloadKey + +selectedTestCenter.id} className="w-full">
              <CalenderWeekSelect
                testCenterId={selectedTestCenter?.id}
                calendarWeek={calenderWeek}
                onChange={(e: any) => {
                  setCalenderWeek(e?.calendarWeek);
                  setSelectedOpeningTime(e);

                  activeSlots.current = {};
                }}
              />
            </div>
          )}
        </div>

        {!selectedTestCenter && (
          <div className="mt-2">
            <Alert message="Testcenter auswählen!" type="warning" />
          </div>
        )}

        {selectedTestCenter && (
          <div className="my-2">
            <Alert
              message={
                <div className="md:flex">
                  <div className="flex-1 text-center">
                    {selectedTestCenter?.name}
                  </div>
                  <div className="flex-1 text-center">KW: {calenderWeek} </div>
                  {/* <div className="flex-1 text-center">
                    ( {from_date} - {to_date})
                  </div> */}
                </div>
              }
              type="info"
            />
          </div>
        )}

        {selectedTestCenter && (
          <div key={reloadKey} className="flex justify-end">
            <div className="flex pb-2  space-x-2">
              <div className="flex-1">
                <CreateNextCalenderWeek
                  testCenterId={selectedTestCenter?.id}
                  openingTime={selectedOpeningTime}
                  deleteCw={false}
                  onClose={async () => {
                    await getOpeningTimes({
                      variables: { testCenterId: selectedTestCenter.id },
                    });
                    setReloadKey(Math.random());

                    setSelectedOpeningTime(null);
                  }}
                />
              </div>

              {false && selectedOpeningTime && (
                <div className="flex-1">
                  <CreateNextCalenderWeek
                    testCenterId={selectedTestCenter?.id}
                    deleteCw={true}
                    openingTime={selectedOpeningTime}
                    onClose={async () => {
                      await getOpeningTimes({
                        variables: { testCenterId: selectedTestCenter.id },
                      });
                      setReloadKey(Math.random());

                      setSelectedOpeningTime(null);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {!openingTimeLoading && selectedTestCenter && (
          <div key={selectedTestCenter?.id} className="overflow-auto">
            <div>
              <Table
                size="small"
                pagination={false}
                columns={columns}
                dataSource={tableData}
                loading={openingTimeLoading}
              />
            </div>
            <div className="my-6" onClick={handleOnSave}>
              <Button block type="primary">
                Abspeichern
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
