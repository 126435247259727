import { Alert, Button, Divider, Popconfirm, message } from "antd";
import React, { useState } from "react";

import {
  TestResult,
  useCreateTestTransportJobMutation,
  useFinishTestTransportJobMutation,
  useDeleteTestResultMutation,
  useCreateLogEventMutation,
  useGenerateBillMutation,
} from "generated/schema";
export const DeleteTestResultButton = ({ onClose, testResult }: any) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await deleteTestResult({
      variables: { id: testResult.id },
    });

    setOpen(false);
    setConfirmLoading(false);
    onClose && onClose();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const [deleteTestResult, { data, loading, error }] =
    useDeleteTestResultMutation({});

  const [generateBill, billReq] = useGenerateBillMutation({
    fetchPolicy: "no-cache",
  });

  return (
    <div className=" ">
      <Divider plain>Test löschen</Divider>

      <Alert
        message={
          "Achtung, bitte löschen Sie nur Testergebnisse die zur Löschung freigegeben wurden. Gelöschte Elemente können nicht noch einmal wiederhergestellt werden"
        }
        type="warning"
      />

      <div className="mt-2">
        <Popconfirm
          title={"Sind Sie sich sicher?"}
          open={open}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button danger block type="primary" onClick={showPopconfirm}>
            Test löschen
          </Button>
        </Popconfirm>
      </div>

      <div>
        <Divider plain>Rechnung Erstellung</Divider>
        <div className="my-4 font-bold">
          Aktuell nur möglich für STC und Schnelltest-Deutschland Teststationen
        </div>

        <Button
          block
          loading={billReq?.loading}
          type="primary"
          onClick={() => {
            generateBill({ variables: { id: testResult?.id } }).then((x) => {
              message.success("Erfolreich erstellt");
            });
          }}
        >
          Rechnung Erstellen
        </Button>
      </div>
    </div>
  );
};
