import { Alert, Button, Divider, Modal, Result } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import { TestCenterSelectWelcome } from "./TestcenterSelect";
import QRCode from "react-qr-code";
import { Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import { WorkTime } from "components/WorkTime";
import { AppCtx } from "context/AppState";
import {
  useWorkTimeChangedSubscription,
  useWorkTimeCurrentUserLazyQuery,
} from "generated/schema";
import { ChangePasswordForm } from "./ChangeUserPassword";
const { Panel } = Collapse;

const { Title } = Typography;

export const Welcome = () => {
  const context = useContext(AppCtx);
  const passwordChangeRequired = context?.user?.passwordChangeRequired;

  const [getData, { data, loading, error }] = useWorkTimeCurrentUserLazyQuery({
    fetchPolicy: "network-only",
  });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useWorkTimeChangedSubscription({});

  useEffect(() => {
    getData();
  }, [customerChangedData]);

  useEffect(() => {
    const workTimes = data?.workTimeCurrentUser;
    let workTime = null;

    if (workTimes?.length) {
      workTime = workTimes[0];
      const activeTestCenterId = workTime?.testCenter;

      if (activeTestCenterId) {
        context?.setActiveTestCenter(activeTestCenterId);
      }
    }
  }, [data]);

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(true);

    // window.location.href = "/login";
    // window.localStorage.removeItem("jwtToken");
  };

  let showNext = false;

  const trackWorkTimes = context?.user?.trackWorkTimes;
  const userHasWorkTimeOpen = context?.workTime;

  if (trackWorkTimes && userHasWorkTimeOpen) {
    showNext = true;
  }

  if (!trackWorkTimes) {
    showNext = true;
  }

  if (!context?.activeTestCenter) {
    showNext = false;
  }

  return (
    <div className="p-2">
      <Title level={3}>STC - CORONA TESTERGEBNISERFASSUNGS SYSTEM</Title>

      <div className="">
        <Alert
          message={
            <div className="text-center text-base font-bold">
              Bitte halten Sie sich beim Kontakt mit Kunden stets und
              vollumfänglich an die Sicherheits- und Hygienevorschriften!
            </div>
          }
          type="error"
        />
      </div>

      <div className="mt-2">
        <Alert
          message={
            <div className="text">
              Die Anfertigung von Kopien oder Fotos von Dokumenten, die Kunden
              vorlegen, ist grundsätzlich nicht erlaubt. Wenn Sie Zweifel haben,
              was Sie in einem bestimmten Fall tun sollen, fragen Sie Ihren
              Vorgesetzten oder den Datenschutzbeauftragten. Melden Sie jede
              Datenpanne sofort an Ihren Vorgesetzten oder den
              Datenschutzbeauftragten, auch wenn es kurz vor Arbeitsende ist und
              Sie schon Feierabend machen wollen.
              <p className="font-bold">
                Ich bestätige, dass ich mich vor Arbeitsbeginn getestet habe und
                das Testergebnis negativ ist.
              </p>
            </div>
          }
          type="warning"
        />
      </div>

      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6 ">
        <div>
          <Title level={4}>Arbeitsstätte Auswahl</Title>
          {!context?.activeTestCenter?.id && (
            <div>
              Sie gehören einer Arbeitsgruppe mit mehreren Standorten an. Bitte
              wählen Sie ihren eingeteilen Standort aus.
            </div>
          )}
          {context?.activeTestCenter?.id && (
            <div className="font-bold">
              Bitte beachten Sie dass Sie ihren Standort erst wechseln können
              wenn Sie sich ausgestochen haben.
            </div>
          )}

          <div key={context?.activeTestCenter?.id} className="mt-2">
            <TestCenterSelectWelcome
              onChange={(e: any) => {
                console.log("", e);
              }}
            />
          </div>
        </div>

        <div>
          {context?.activeTestCenter && (
            <div key={context?.activeTestCenter?.id}>
              <WorkTime />
            </div>
          )}
        </div>

        {passwordChangeRequired && (
          <Modal
            closable={false}
            open={isModalOpen}
            // onOk={handleOk}
            okText="Bestätige"
            onCancel={handleCancel}
            footer={null}
          >
            <div className="p-2">
              <Title level={4}>Passwört Änderung</Title>
              <div className="mb-4">
                Sie müssen Ihr Passwort ändern um fortfahren zu können.
                <div className="font-bold">
                  Bitte beachten Sie dass Sie Ihr Passwort im Nachgang nicht
                  mehr ändern können.
                </div>
              </div>
              <ChangePasswordForm onClose={handleOk} user={context?.user} />
            </div>
          </Modal>
        )}
      </div>
      <div className="mt-16">
        <Divider plain></Divider>

        <Button
          disabled={!showNext}
          block
          onClick={() => navigate("/test-results")}
          type="primary"
        >
          Weiter
        </Button>
      </div>
    </div>
  );
};
