import { Button, Modal } from "antd";
import React, { useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { UserGroupsSelect } from "./UserGroupsSelect";

import { TestCenterSelect } from "./TestCenterSelect/index";
import { ActiveSelect } from "./ActiveSelect";
import { CourierGroupFilter } from "./CourierGroupFilter";
import { Input } from "antd";
import { TestIdFilter } from "./TestIdFilter";
import { TestCustomerNameFilter } from "./TestCustomerNameFilter";
import { TestOptionTypeSelect } from "./TestTypeOptionSelect";
import { CityFilter } from "./CityFilter/index";
import { ZipCodeFilter } from "./ZipCodeFilter/index";
import { EmailFilter } from "./EmailFilter/index";
import { DateOfBirthFilter } from "./DateOfBirthFilter";
import { StreetFilter } from "./StreetFilter";
import { TelFilter } from "./TelFilter";

import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";

interface Props {
  filters: any;
  setFilters: any;
  onChangeDatePicker: any;
}
export const TableFilters = ({ filters, setFilters, onChangeDatePicker }: Props) => {
  const onFilterChange = (v: any) => {
    if (v?.delete == true) {
      const newFilters = filters.filter(
        (x: any) => x?.fieldName != v?.fieldName
      );
      setFilters(newFilters);
      return;
    }

    const newFilters = filters.filter((x: any) => x?.fieldName != v?.fieldName);
    newFilters.push(v);

    setFilters(newFilters);
    return;
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
    onChangeDatePicker(date)
  };

  return (
    <>
      <div className="flex space-x-2 w-full overflow-auto bg-gray-100 p-2">
        {/* <TestIdFilter onChange={onFilterChange} /> */}
        {/* <DateOfBirthFilter onChange={onFilterChange} /> */}

        <TestCustomerNameFilter onChange={onFilterChange} />

        <DatePicker style={{ width: 250 }} onChange={onChange} picker="month" />

        {/* <StreetFilter onChange={onFilterChange} />
        <EmailFilter onChange={onFilterChange} />

        <ZipCodeFilter onChange={onFilterChange} />

        <CityFilter onChange={onFilterChange} />
        <TelFilter onChange={onFilterChange} /> */}
        {/* <TestCenterSelect onChange={onFilterChange} /> */}

         {/* <UserGroupsSelect onChange={onFilterChange} /> */}
{/*        <CourierGroupFilter onChange={onFilterChange} /> */}
      </div>
    </>
  );
};
