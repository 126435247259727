import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { CustomerTableWrapper } from "components/CustomerTable";
import { TestTableSearch } from "components/TestTable/Search";

import moment from "moment";
import { Breadcrumb, Button, Radio } from "antd";
import { CustomerTableSearch } from "components/CustomerTable/Search";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { WorkTimeTableWrapper } from "components/WorkTimeTable";
import { WorkTimeTableMonthWrapper } from "components/WorkTimeTableMonth";

export const TimeTrackingPage = () => {
  const [activeTab, setActiveTab] = useState("month");
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <PageBreadcrumb value={["Zeiterfassung"]} />

          <div className="mb-4 grid grid-cols-1 md:grid-cols-2">
            <div className="w-full ">
              <Radio.Group
                onChange={(e) => {
                  const v = e?.target?.value;
                  setActiveTab(v);
                  //   setActive(false)
                  //   setInEngine(false)
                  //   setInProgress(false)
                  //   setCompleted(false)
                  //   setActiveFilter(v)
                  //   if(v == "all") {
                  //     setActive(e?.target?.value);
                  //   }

                  //   if(v == "inProgress") {
                  //     setInProgress(true)
                  //   }

                  //   if(v == "inEngine") {
                  //     setInEngine(true)
                  //   }

                  //   if(v == "completed") {
                  //     setCompleted(true)
                  //   }
                }}
                value={activeTab}
                optionType="button"
                buttonStyle="solid"
              >
                <Radio.Button value="month">Monatsübersicht</Radio.Button>
                <Radio.Button value={"single"}>Einzelaufzeichnung</Radio.Button>
              </Radio.Group>
            </div>
          </div>

          {activeTab == "single" && <WorkTimeTableWrapper />}
          {activeTab != "single" && <WorkTimeTableMonthWrapper />}
        </div>
      </PageWrapper>
    </div>
  );
};
