import { Button, Modal } from "antd";
import React, { useState } from "react";
import ReactJson from "react-json-view";

export const LogDiffModal = ({ diffString }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let json = {};

  try {
    json = JSON.parse(diffString);
  } catch (e) {
    console.log(e);
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Log-Details
      </Button>
      <Modal
        width={1000}
        title="Log-Daten"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className=" overflow-auto">
          <ReactJson src={json} />
        </div>
      </Modal>
    </>
  );
};
