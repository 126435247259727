import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { TestResult } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon, PencilIcon } from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { Tooltip } from "antd";
import _get from "lodash/get";
import {
  getTestResultTitle,
  TableTestStateButton,
} from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestResultView } from "components/TestResult";
import formatCurrency from "utils/formatCurrency";
import { TextTableColumn } from "components/TableColumns/TextTableColumn";
import { AppCtx } from "context/AppState";
import { TestResultSignatureForm } from "components/TestResult/TestResultSignature";
import moment from "moment";

export const Columns: ColumnsType<TestResult> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 75,
  },

  {
    title: "Check-In",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 170,
    render: (text: any, row: any) => <div>{formatDate(text)}</div>,
  },

  {
    title: "Ergebnis",
    dataIndex: "Ergebnis",
    key: "Ergebnis",
    width: 265,
    render: (text: string, row: TestResult) => {
      const signatureRequired = row?.testType?.signatureRequired;
      const hasAlreadyCashType = row?.cashType && true;
      const isSchnellTest = row?.testType?.id == 4;
      const customerSignature = row?.customerSignature;
      const hasAlreadySignature =
        customerSignature && customerSignature && true;
      return (
        <div className="flex space-x-2" key={row?.testFinishedAt}>
          <TableTestStateButton testResult={row} />

          <div>
            <TestResultSignatureForm modalTitle="Vorschau" testResult={row} />
          </div>
        </div>
      );
    },
  },

  {
    title: "Geburtsdatum",
    dataIndex: ["customer", "dateOfBirth"],
    key: "dateOfBirth",
    width: 120,
    render: (text: any, row: TestResult) => {
      return <div>{text}</div>;
    },
  },
  {
    title: "Kunde",
    dataIndex: "customer",
    key: "customer",
    width: 200,
    render: (text: any, row: TestResult) => {
      const firstName = row?.customer?.firstName || "";
      const lastName = row?.customer?.lastName || "";
      const name = `${firstName} ${lastName}`;
      return <div className=" overflow-auto whitespace-nowrap">{name}</div>;
    },
  },
  {
    title: "Testcenter",
    dataIndex: "testCenter",
    key: "testCenter",
    render: (text: string, row: TestResult) => {
      return (
        <div className="overflow-x-auto whitespace-nowrap">
          {row?.testCenter?.name || "-"}
        </div>
      );
    },
    width: 250,
  },
  {
    title: "Buchungsoption",
    dataIndex: "testType",
    key: "testType",
    render: (text: string, row: TestResult) => {
      const testTypeOption = row?.testTypeOption?.name;
      const testTypeName = row?.testType?.name;
      const testTypeColor = row?.testType?.color;

      return (
        <div className="flex whitespace-nowrap">
          <div className="flex-1">
            <Tag color={testTypeColor || ""}>{testTypeName || "-"}</Tag>{" "}
          </div>
        </div>
      );
    },
    width: 150,
  },

  {
    title: "Testoption",
    dataIndex: "testType",
    key: "testType",
    render: (text: string, row: TestResult) => {
      const testTypeOption = row?.testTypeOption?.name || "-";
      const testTypeName = row?.testType?.name;
      const testTypeColor = row?.testType?.color;

      return (
        <div className="flex whitespace-nowrap text-clip overflow-hidden">
          <Tooltip title={<div className=""> {testTypeOption}</div>}>
            <span> {testTypeOption}</span>
          </Tooltip>
        </div>
      );
    },
    width: 200,
  },

  {
    title: "Zahlungsart",
    dataIndex: "cashType",
    key: "cashType",
    render: (text: string, row: TestResult) => {
      const labels = { card: "Karte", cash: "Bar", free : "FREI" };

      const label = _get(labels, text, "-");
      return <div>{label}</div>;
    },
    width: 100,
  },

  {
    title: "Preis",
    dataIndex: "price",
    key: "price",
    render: (text: string, row: TestResult) => {
      const label = formatCurrency(text);

      return <div>{label}</div>;
    },
    width: 100,
  },

  {
    title: "",
    dataIndex: "age",
    key: "age",

    render: (text: string, row: TestResult) => {
      return <></>;
      return (
        <div>
          <div>{formatDate(row?.testFinishedAt)}</div>
          <div>{formatDate(row?.testStartAt)}</div>
        </div>
      );
    },
  },

  {
    title: "Action",
    key: "action",
    render: (_, testResult: TestResult) => {
      return <ActionColumn value={_} testResult={testResult} />;
    },
  },
];

interface Props {
  value: any;
  testResult: TestResult;
}
const ActionColumn = ({ value, testResult }: Props) => {
  const context = useContext(AppCtx);

  const roleId = context?.user?.role?.id;

  const laborRequired = testResult?.testType?.laborRequired;

  let allowedToStart = true;
  if (laborRequired) {
    const evaluationHours = testResult?.testTypeOption?.evaluationHours || 9999;

    const now = moment().format("HH");

    if (evaluationHours <= 12) {
      if (parseInt(now) > 12) {
        allowedToStart = false;
      }
    }

    if (roleId != 1 && roleId != 2) {
      if (allowedToStart == false) {
        return <div></div>;
      }
    }
  }

  const onClick = () => {
    context?.setSelectedTestResult(testResult);
  };
  return (
    <div>
      <Button onClick={onClick} size="small" type="primary">
        Edit
      </Button>
    </div>
  );
};
