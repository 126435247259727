import React, { useContext, useEffect, useState } from "react";

import {
  useTestMeetingsQuery,
  Direction,
  useCreateTestResultMutation,
  useTestMeetingChangedSubscription,
  TestMeeting,
  useTestMeetingsLazyQuery,
} from "generated/schema";
import { PlusIcon, CalendarIcon } from "@heroicons/react/solid";
import { Button, Drawer, Radio, Spin, Grid, Tag } from "antd";
import { MyDrawer } from "components/MyDrawer";
import moment from "moment";
import { AppCtx } from "context/AppState";
import { TestMeetings } from "./List";

const { useBreakpoint } = Grid;

export const TestMeetingList = () => {
  const context = useContext(AppCtx);

  const [open, setOpen] = useState(false);
  const screen = useBreakpoint();

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Drawer
        destroyOnClose
        size={screen.md ? "large" : "default"}
        title="Termine"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <TestMeetings />
      </Drawer>

      <Button
        style={{ background: "orange", borderColor: "orange" }}
        loading={false}
        type="primary"
        onClick={() => setOpen(true)}
      >
        <div className="flex items-center">
          <CalendarIcon className="w-4 h-4" />
          Termine
        </div>
      </Button>
    </div>
  );
};
