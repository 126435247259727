import { Select } from "antd";
import { useTestTypesQuery } from "generated/schema";

const { Option } = Select;

interface Props {
  onChange: any;
}
export const TestTypeSelect = ({ onChange }: Props) => {
  const { data, loading, error } = useTestTypesQuery({
    fetchPolicy: "network-only",
  });

  const handleChange = (value: string) => {

    if (!value) {
      onChange({
        fieldName: "testResult.testType.id",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "testResult.testType.id",
      term: String(value),
      exact: true,
    };

    onChange(filter);
  };

  return (
    <>
      <Select
        allowClear
        placeholder="Test-Typ"
        style={{ minWidth: 150 }}
        loading={loading}
        onChange={handleChange}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => {
          return ((option?.children as unknown as string) || "")
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      >
        {data?.testTypes?.map((x) => {
          return <Option value={x?.id}>{x?.name}</Option>;
        })}
      </Select>
    </>
  );
};
