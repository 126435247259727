import { Alert, Button, Divider, Popconfirm } from "antd";
import { AppCtx } from "context/AppState";
import React, { useContext, useState } from "react";
import moment from "moment";
import {
  TestResult,
  useCreateTestTransportJobMutation,
  useFinishTestTransportJobMutation,
  useDeleteTestResultMutation,
  useCreateLogEventMutation,
  useDeleteTestTransportJobMutation,
  useDeleteOpeningTimeMutation,
  useCreateOpeningTimeMutation,
  useOpeningTimesQuery,
  OpeningTime,
} from "generated/schema";
export const CreateNextCalenderWeek = ({
  onClose,
  openingTime,
  deleteCw,
  testCenterId,
}: any) => {
  const context = useContext(AppCtx);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const {
    data: openingTimes,
    loading: openingTimesLoading,
    error: openingTimesError,
  } = useOpeningTimesQuery({
    fetchPolicy: "network-only",
    variables: { testCenterId },
  });

  const times: any = {};
  const currentWeek = moment().week();

  const currentWeek1 = moment()
    .week(currentWeek + 1)
    .format("w");
  let nextCw: any = currentWeek1;
  if (openingTimes?.openingTimes?.length) {
    for (const time of openingTimes?.openingTimes) {
      if (time.calendarWeek) times[time?.calendarWeek] = time;
    }
    let arr: any = Object.keys(times);
    let min = Math.min(...arr);
    let max = Math.max(...arr);

    let nextCalenderWeek = moment().week(max + 1);

    if (max == 52) {
      for (let i = 1; i < 53; i++) {
        if (!times[i]) {
          nextCalenderWeek = moment().week(i);

          break;
        }
      }
    }

    nextCw = nextCalenderWeek.format("w");
  }


  const roleId = context?.user?.role?.id;

  const isAdmin = roleId == 1 || roleId == 2;
  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    if (deleteCw) {
      await deleteOpeningTime({
        variables: { id: openingTime.id },
      });
    }

    if (!deleteCw) {
      const d = openingTime?.data ? openingTime?.data :  [[], [], [], [], [], [], []]
      await createOpeningTime({
        variables: {
          input: {
            calendarWeek: parseInt(nextCw),
            testCenterId,
            data: d,
          },
        },
      });
    }

    setOpen(false);
    setConfirmLoading(false);
    onClose && onClose();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const [deleteOpeningTime, { data, loading, error }] =
    useDeleteOpeningTimeMutation({});

  const [createOpeningTime, req] = useCreateOpeningTimeMutation({});

  if (!isAdmin) {
    return <></>;
  }
  return (
    <div className="">
      <div className="">
        <Popconfirm
          title={"Sind Sie sich sicher?"}
          open={open}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button
            size="small"
            danger={deleteCw}
            type="primary"
            onClick={showPopconfirm}
          >
            {deleteCw && <div>Löschen (KW: {openingTime?.calendarWeek})</div>}
            {!deleteCw && <div>Nächste KW anlegen (KW: {nextCw})</div>}
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};
