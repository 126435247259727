import { Alert } from "antd";
import { TestResult } from "generated/schema";
import formatDate from "utils/formatDate";

interface Props {
  testResult: TestResult;
}
export function EmailSent({ testResult }: Props) {
  const reportSentViaMailAt = testResult?.reportSentViaMailAt;

  if (!reportSentViaMailAt) {
    return <div>Noch keine E-mail Zustellung</div>;
  }

  const emailTo = testResult?.customer?.email || "-";

  const reportSentTo = testResult?.reportSentViaMailTo;
  
  if (reportSentTo) {
    if (reportSentTo != emailTo) {
      return (
        <div className="mt-8">
          <Alert
            message={
              <div>
                E-Mail Adresse abgeändert. Gegebenenfalls E-Mail manuell versenden!
              </div>
            }
            type="error"
          />
        </div>
      );
    }
  }

  return (
    <div className="mt-8">
      <Alert
        message={
          <div>
            Emailzustellung
            <div className="flex space-x-2 ">
              <div>{emailTo}</div>
              <div>{formatDate(reportSentViaMailAt)}</div>
            </div>
          </div>
        }
        type="success"
      />
    </div>
  );
}
