import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { CustomerTableWrapper } from "components/CustomerTable";
import { TestTableSearch } from "components/TestTable/Search";

import moment from "moment";
import { Breadcrumb, Button } from "antd";
import { CustomerTableSearch } from "components/CustomerTable/Search";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { AdminMenu } from "components/AdminPageMenu";
import { StaffTableWrapper } from "components/StaffTable";
import { UserForm } from "components/StaffTable/StaffForm";
import { LaboratoryTableWrapper } from "components/Laboratory";
export const LaboratoryPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <AdminMenu
            defaultValue="laboratories"
            onChange={() => console.log("")}
          />
          <PageBreadcrumb value={["Labore"]} />

          <LaboratoryTableWrapper />
        </div>
      </PageWrapper>
    </div>
  );
};
