import { AdminMenu } from "components/AdminPageMenu";
import { OpeningTimesEditor } from "components/OpeningTimes";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import React, { useEffect, useState, useContext } from "react";

export const OpeningTimePage = () => {
  return (
    <div className="p-4">
      {/* <AdminMenu defaultValue="openingTimes" onChange={() => console.log("")} /> */}
      <PageBreadcrumb value={["Terminplaner"]} />
      <OpeningTimesEditor />
    </div>
  );
};
