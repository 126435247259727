import { CourierGroupsTableWrapper } from "components/CourierGroupsTable";
import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { CustomerTableWrapper } from "components/CustomerTable";
import { TestTableSearch } from "components/TestTable/Search";
import { CourierPlan } from "components/CourierPlan";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { CustomerTableSearch } from "components/CustomerTable/Search";
import { AdminMenu } from "components/AdminPageMenu";
export const CourierGroupPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <AdminMenu
            defaultValue="courier-groups"
            onChange={() => console.log("")}
          />

          <PageBreadcrumb value={["Kurier-Gruppen"]} />

          <CourierGroupsTableWrapper />
        </div>
      </PageWrapper>
    </div>
  );
};
