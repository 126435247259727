import React from "react";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  EmojiHappyIcon,
  ExclamationIcon,
  BeakerIcon,
  ClockIcon,
  CheckCircleIcon
} from "@heroicons/react/solid";
import { Button } from "styled";

import { TestResult } from "generated/schema";
import formatDate from "utils/formatDate";
import { Tooltip } from "antd";
interface Props {
  testResult: TestResult;
}

export const LaborRequiredTest = (props: Props) => {
  return (
    <div>
      <strong className="inline-flex items-center h-8 overflow-hidden text-white bg-violet-800 rounded">
        <span className="px-2  font-normal">
          <div className="flex w-36">
            <div className="flex-1">Ausgewertet</div>
            <Tooltip title={formatDate(props?.testResult?.testFinishedAt)}>
              <div className="">
                {formatDate(props?.testResult?.testFinishedAt, true)}
              </div>
            </Tooltip>
          </div>
        </span>

        <button
          className="inline-flex items-center justify-center w-8 h-8 bg-violet-600 transition-color hover:bg-violet-700 focus:outline-none focus:ring"
          type="button"
        >
          <CheckCircleIcon className="h-5 w-5 text-white" />
        </button>
      </strong>
    </div>
  );
};
