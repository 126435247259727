import { useCountdown } from "hooks/useCountdown";
import { TestResult, useTestResultQuery } from "generated/schema";
import { TestTableTestTimeRunningBtn } from "./TimeRunningButton";

import { EnterResultButton } from "./EnterResultButton";
import { StartTestButton } from "./StartTestButton";
import { TestTableResultNegativeBtn } from "./Negative";
import { TestTableResultPositiveBtn } from "./Positive";
import { TestTableInvalidBtn } from "./InvalidResultButton";
import { TestResultForm } from "components/TestResult/TestResultForm";
import { MyDrawer } from "components/MyDrawer";
import { TestResultView } from "components/TestResult";
import { SpecimenTookBtn } from "./SpecimenTookButton";
import { SpecimenPickedUpByCourierButton } from "./SpecimenPickedUpByCourierButton";
import { SpecimenArrivedLaborButton } from "./SpecimenArrivedLaborButton";
import { useContext } from "react";
import { AppCtx } from "context/AppState";

export const getTestResultTitle = (testResult: TestResult) => {
  const dateOfBirthday = testResult?.customer?.dateOfBirth;
  const customerId = testResult?.customer?.id || "";
  const firstName = testResult?.customer?.firstName;
  const lastName = testResult?.customer?.lastName;
  const testId = testResult?.id;
  const customerName = `TestId: ${testId},  (${customerId}) - ${firstName} ${lastName}, ${dateOfBirthday}  `;

  return customerName;
};
export const TableTestStateButton = (props: any) => {
  const context = useContext(AppCtx);

  const testResult: TestResult = props?.testResult;

  const onClick = () => {
    context?.setSelectedTestResult(testResult);
  };

  const { days, hours, minutes, seconds } = useCountdown(
    testResult?.testFinishedAt
  );

  if (!testResult?.result?.id) {
    if (testResult?.specimenAt) {
      if (testResult?.transportJob?.deliveredAt) {
        if (testResult?.testType?.laborRequired) {
          return (
            <div onClick={onClick}>
              <SpecimenArrivedLaborButton testResult={testResult} />
            </div>
          );

          return (
            <MyDrawer
              title={getTestResultTitle(testResult)}
              triggerElement={
                <SpecimenArrivedLaborButton testResult={testResult} />
              }
            >
              <TestResultView testResult={testResult} />
            </MyDrawer>
          );
        }
      }
    }
  }

  if (!testResult?.result?.id) {
    if (testResult?.specimenAt) {
      if (testResult?.transportJob?.id) {
        if (testResult?.testType?.laborRequired) {
          return (
            <div onClick={onClick}>
              <SpecimenPickedUpByCourierButton testResult={testResult} />
            </div>
          );

          return (
            <MyDrawer
              title={getTestResultTitle(testResult)}
              triggerElement={
                <SpecimenPickedUpByCourierButton testResult={testResult} />
              }
            >
              <TestResultView testResult={testResult} />
            </MyDrawer>
          );
        }
      }
    }
  }

  if (!testResult?.result?.id) {
    if (testResult?.specimenAt) {
      if (testResult?.testType?.laborRequired) {
        return (
          <div onClick={onClick}>
            <SpecimenTookBtn testResult={testResult} />
          </div>
        );

        return (
          <MyDrawer
            title={getTestResultTitle(testResult)}
            triggerElement={<SpecimenTookBtn testResult={testResult} />}
          >
            <TestResultView testResult={testResult} />
          </MyDrawer>
        );
      }
    }
  }

  if (testResult?.result?.id == 1) {
    return (
      <div onClick={onClick}>
        <TestTableResultPositiveBtn testResult={testResult} />
      </div>
    );

    return (
      <MyDrawer
        title={getTestResultTitle(testResult)}
        triggerElement={<TestTableResultPositiveBtn testResult={testResult} />}
      >
        <TestResultView testResult={testResult} />
      </MyDrawer>
    );
  }

  if (testResult?.result?.id == 2) {
    return (
      <div onClick={onClick}>
        <TestTableResultNegativeBtn testResult={testResult} />
      </div>
    );

    return (
      <MyDrawer
        title={getTestResultTitle(testResult)}
        triggerElement={<TestTableResultNegativeBtn testResult={testResult} />}
      >
        <TestResultView testResult={testResult} />
      </MyDrawer>
    );
  }

  if (testResult?.result?.id == 3) {
    return (
      <div onClick={onClick}>
        <TestTableInvalidBtn testResult={testResult} />
      </div>
    );

    return (
      <MyDrawer
        title={getTestResultTitle(testResult)}
        triggerElement={<TestTableInvalidBtn testResult={testResult} />}
      >
        <TestResultView testResult={testResult} />
      </MyDrawer>
    );
  }

  if (!testResult?.testFinishedAt) {
    return <StartTestButton testResult={testResult} />;
  }

  if (minutes == 0 && seconds == 0) {
    return (
      <div onClick={onClick}>
        <EnterResultButton testResult={testResult} />{" "}
      </div>
    );

    return (
      <MyDrawer
        title={getTestResultTitle(testResult)}
        triggerElement={<EnterResultButton testResult={testResult} />}
      >
        <TestResultView testResult={testResult} />
      </MyDrawer>
    );
  }
  const ss = String(seconds).length == 1 ? "0" + seconds : seconds;
  const mm = String(minutes).length == 1 ? "0" + minutes : minutes;

  const durationString = mm + ":" + ss;
  return (
    <div key={testResult?.testFinishedAt}>
      <TestTableTestTimeRunningBtn
        testResult={testResult}
        duration={durationString}
      />
    </div>
  );
};
