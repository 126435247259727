import type { DrawerProps, RadioChangeEvent } from "antd";
import { Button, Drawer, Radio, Space, Grid } from "antd";
import { AppCtx } from "context/AppState";
import { TestResult } from "generated/schema";
import React, { useContext, useState } from "react";

const { useBreakpoint } = Grid;

interface Props {
  triggerElement?: any;
  children: any;
  title: String;
  open?: boolean;
}

export const MyDrawer = (props: Props) => {

  const context = useContext(AppCtx);

  
  const [open, setOpen] = useState(props?.open);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");

  const screen = useBreakpoint();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    context?.setSelectedTestResult(null)
  };

  return (
    <>
      <div onClick={showDrawer}>{props?.triggerElement || <div>Hi</div>}</div>

      <Drawer
        title={props.title}
        placement={placement}
        closable={true}
        onClose={onClose}
        open={open}
        key={placement}
        size={screen.md ? "large" : "default"}
      >
        {open && React.cloneElement(props.children, { onClose })}

        {/* <div>{props.children}</div> */}
      </Drawer>
    </>
  );
};
