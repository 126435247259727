import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Button, Radio } from "antd";
import { MyDrawer } from "components/MyDrawer";
import {
  Direction,
  useTestStaffChangedSubscription,
  useTestStaffsLazyQuery,
  useTestStaffCountLazyQuery,
  useUsersLazyQuery,
  useUserCountLazyQuery,
  useUserChangedSubscription,
} from "generated/schema";
import { useEffect, useState } from "react";
import { UserForm } from "./StaffForm";
import { TestTable } from "./Table";
import { TableFilters } from "./TableFilters";

const TABLE_PAGE_SIZE = 12;

export const StaffTableWrapper = () => {
  const [currentTablePage, setCurrentPageTable] = useState(1);
  const [filters, setFilters] = useState<any>([]);
  const [showTableFilters, setShowTableFilters] = useState(true);

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "user.id",
    direction: Direction.Desc,
  });

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const [getData, { data, loading }] = useUsersLazyQuery({
    fetchPolicy: "network-only",
    variables: { orderBy, take, skip, filters },
  });
  const [getDataCount, { data: customerCountData, loading: l }] =
    useUserCountLazyQuery({
      variables: {filters},
      fetchPolicy: "network-only",
    });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useUserChangedSubscription({});

  useEffect(() => {
    getData();
    getDataCount();
  }, [customerChangedData ]);

  const resultData = data?.users || [];


  return (
    <div>
      <div className="flex  md:justify-end mb-2">
        <MyDrawer
          title={"Mitarbeiter Anlegen"}
          triggerElement={<Button type="primary">Mitarbeiter anlegen</Button>}
        >
          <UserForm />
        </MyDrawer>
      </div>
      <div className="flex space-x-2">
        <div className="flex-1 overflow-auto ">
          {showTableFilters && (
            <TableFilters filters={filters} setFilters={setFilters} />
          )}
        </div>
      </div>
      

      <TestTable
        dataCount={customerCountData?.userCount || 0}
        data={resultData}
        loading={loading}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
      />
    </div>
  );
};
