import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Modal, Grid } from "antd";

const { useBreakpoint } = Grid;
const Base64Image = ({ data }: any) => <img src={`${data}`} />;

function vw(percent: number) {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (percent * w) / 100;
}

export const SignaturePad = ({ onSave, initialValue }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signatureString, setSignatureString] = useState(initialValue);

  const screen = useBreakpoint();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    const base64Data = sigCanvasRef?.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");

    setSignatureString(base64Data);

    base64Data && onSave && onSave(base64Data);

    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sigCanvasRef: any = useRef();

  const clear = () => {
    sigCanvasRef?.current?.clear();
  };

  const trim = () => {
    const base64Data = sigCanvasRef?.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");

    setSignatureString(base64Data);

    onSave && onSave();
    // this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
    //   .toDataURL('image/png')})
  };

  const widthProp = (!screen?.xs && { width: "80vw" }) || {};
  return (
    <div className="p-2">
      <>
        <Button block type="primary" onClick={showModal}>
          Unterschrift
        </Button>
        <div className="mt-6 ">
          {signatureString && <Base64Image data={signatureString} />}
        </div>
      </>
      <Modal
        title="Bestätigung Selbstauskunft"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        {...widthProp}
      >
        <div className="bg-gray-300 p-6">
          <div className="bg-white bordered-2">
            <SignatureCanvas
              /*
      // @ts-ignore */
              canvasProps={{
                width: vw(75),
                height: 150,
                className: "sigCanvas",
              }}
              ref={sigCanvasRef}
            />
          </div>
        </div>

        <Button className="mt-2" onClick={clear} type="default">
          Unterschrift löschen
        </Button>
        {/* <button onClick={trim}>Speichern</button> */}
      </Modal>
    </div>
  );
};
// class App extends Component {
//   state = {trimmedDataURL: null}
//   sigPad = {}
//   clear = () => {
//     this.sigPad.clear()
//   }
//   trim = () => {
//     this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
//       .toDataURL('image/png')})
//   }
//   render () {
//     let {trimmedDataURL} = this.state
//     return <div className={styles.container}>
//       <div className={styles.sigContainer}>
//         <SignaturePad canvasProps={{className: styles.sigPad}}
//           ref={(ref) => { this.sigPad = ref }} />
//       </div>
//       <div>
//         <button className={styles.buttons} onClick={this.clear}>
//           Clear
//         </button>
//         <button className={styles.buttons} onClick={this.trim}>
//           Trim
//         </button>
//       </div>
//       {trimmedDataURL
//         ? <img className={styles.sigImage}
//           src={trimmedDataURL} />
//         : null}
//     </div>
//   }
