import moment from "moment";

export default function formatDate(date: Date, onlyTime?: boolean, onlyDate?: boolean) {
  const locale = (localStorage.getItem("locale") || "de").toLowerCase();
  moment.locale(locale);
 
  if (!moment(date).isValid()) {
    return "-";
  }
  if (onlyTime) {
    return moment(date).format("HH:mm");
  }

  if (onlyDate) {
    return moment(date).format("DD.MM.YYYY");
  }
  return moment(date).format("DD.MM.YYYY HH:mm:ss");
}
