import { PcrPlate, TestResult } from "generated/schema";
import React from "react";

import styled from "styled-components";
import { FilledGridBox } from "./FilledGridBox";
import { ResultInvalidCell } from "./ResultInvalidCell";
import { ResultNegativeCell } from "./ResultNegativeCell";
import { ResultPositiveCell } from "./ResultPositiveCell";

const ColWrapper = styled.div`
  grid-template-columns: repeat(13, minmax(0, 1fr));
`;

function alphabetPosition(index: number) {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  return (alphabet[index] && alphabet[index].toLocaleUpperCase()) || "";
}
interface Props {
  data: any;
  pcrPlate?: PcrPlate;
  testResults: any;
  nextFreeSlot: any;
}

export const PcrPlateGrid = ({
  data,
  pcrPlate,
  testResults,
  nextFreeSlot,
}: Props) => {
  const rowCount = 8;
  const colCount = 13;

  const rows = [...new Array(rowCount + 1)].map((x, i) => i);
  const columns = [...new Array(colCount)].map((x, i) => i);

  const filledSlots = data.filter((x: any) => x?.data);

  return (
    <div>
      {rows.map((x, rowIndex) => {
        const cols = columns.map((c, colIndex): any => {
          if (colIndex == 0 && rowIndex == 0) {
            return (
              <div className="border-b border-r w-8 h-8 bg-gray-100">
                {alphabetPosition(rowIndex - 1)}
              </div>
            );
          }

          if (colIndex == 0) {
            return (
              <div className="border-b border-r w-8 h-20 bg-gray-100 font-bold">
                {alphabetPosition(rowIndex - 1)}
                {colIndex != 0 && colIndex}
              </div>
            );
          }

          if (rowIndex == 0) {
            // header columns
            return (
              <div className="border-b border-r w-20 h-8 bg-gray-100 font-bold">
                {alphabetPosition(rowIndex - 1)}
                {colIndex}
              </div>
            );
          }

          const posIndex = alphabetPosition(rowIndex - 1) + colIndex;

          if (posIndex == "A1") {
            return (
              <div className="border-b border-r w-20 h-20  ">
                <div className="w-full h-full bg-gray-50">Ref-Wert:</div>
              </div>
            );
          }

          if (posIndex == "B1") {
            return (
              <div className="border-b border-r w-20 h-20  ">
                <div className="  w-full h-full bg-gray-50">Ref-Wert:</div>
              </div>
            );
          }

          const filledCell = filledSlots.find(
            (x: any) => x.posIndex == posIndex
          );

          let testResult: any;
          if (filledCell) {
            const testId = filledCell?.data?.testId;

            testResult = testResults?.find((x: TestResult) => x?.id == testId);

            if (pcrPlate?.resultsUploadedAt) {
              if (testResult?.result?.id == 1) {
                // testResult positive
                return (
                  <ResultPositiveCell
                    testResult={testResult}
                    pcrPlate={pcrPlate}
                    cell={filledCell}
                  />
                );
              }

              if (testResult?.result?.id == 2) {
                // testResult negative
                return (
                  <ResultNegativeCell
                    testResult={testResult}
                    pcrPlate={pcrPlate}
                    cell={filledCell}
                  />
                );
              }

              if (testResult?.result?.id == 3) {
                // testResult negative
                return (
                  <ResultInvalidCell
                    testResult={testResult}
                    pcrPlate={pcrPlate}
                    cell={filledCell}
                  />
                );
              }
            }

            // filled but no result
            return (
              <FilledGridBox
                cell={filledCell}
                testResult={testResult}
                pcrPlate={pcrPlate}
              />
            );
          }
          // free cell

          // next free cell
          if (posIndex == nextFreeSlot?.posIndex) {
            return (
              <div className="border-b border-r w-20 h-20  ">
                <div className="border-2 border-black  w-full h-full">
                  {posIndex} {testResult?.id}
                </div>
              </div>
            );
          }

          // free cell
          return (
            <div className="border-b border-r w-20 h-20">
              {posIndex} {testResult?.id}
            </div>
          );
        });

        return (
          <div>
            <div className="flex">{cols}</div>
          </div>
        );
      })}
    </div>
  );
};
