import React, { useContext, useState } from "react";
import { Tag, Button, Divider } from "antd";
import { HomeIcon, XIcon } from "@heroicons/react/solid";
import { TestCenter, TestResult } from "generated/schema";
import { AppCtx } from "context/AppState";

interface Props {
  testCenter: TestCenter;
  setShow: any;
}
export const TestCenterMenuDetails = ({ testCenter, setShow }: Props) => {
  const testCenterId = testCenter?.id || "-";
  const testCenterName = testCenter?.name || "-";
  const testCenterNameStreet = testCenter?.street || "-";
  const testCenterCity = testCenter?.city || "-";
  const testCenterZipCode = testCenter?.zipCode || "-";
  const [showMore, setShowMore] = useState(false);

  const appContext = useContext(AppCtx);

  return (
    <div>
      <div className="flex justify-end items-center">
      <div className="flex-1">
          <Divider plain>
            <div>
              {appContext?.user?.role?.name} (ID: {appContext?.user?.id})
            </div>
          </Divider>
        </div>
        <div>
          <Button
            onClick={() => setShow(false)}
            size="small"
            type="text"
            danger
          >
            <XIcon className="w-4 h-4"></XIcon>
          </Button>
        </div>
        
      </div>

      <div className="flex space-x-1 items-center">
        <div>
          <HomeIcon className="w-4 h-4" />
        </div>
        <div className="font-bold"> Standort</div>
      </div>
      <div className="">
        <div>
          {testCenterName} (ID: {testCenterId})
        </div>

        {showMore && (
          <>
            <div>{testCenterNameStreet}</div>
            <div>
              {testCenterZipCode} {testCenterCity}
            </div>
          </>
        )}

        <div
          onClick={() => setShowMore(!showMore)}
          className="text-blue-400 hover:text-blue-600 cursor-pointer"
        >
          {showMore ? "weniger" : "mehr"}
        </div>
      </div>
      <div className="font-bold">Termin-App</div>
      <a href="https://termin.app-tc.de/" target="_blank">
        Hier
      </a>
    </div>
  );
};
