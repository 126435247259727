import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { CustomerTableWrapper } from "components/CustomerTable";
import { TestTableSearch } from "components/TestTable/Search";

import moment from "moment";
import { Breadcrumb, Button } from "antd";
import { CustomerTableSearch } from "components/CustomerTable/Search";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { PageBreadcrumb } from "components/PageBreadcrumb";

export const CustomerPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <PageBreadcrumb value={["Kunden"]}/>
  

          <CustomerTableWrapper />
        </div>
      </PageWrapper>
    </div>
  );
};
