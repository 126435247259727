import React from "react";
import { PcrPlate } from "generated/schema";
import formatDate from "utils/formatDate";

import { Typography } from "antd";
import { UploadPcrPlateResult } from "../UploadPcrResult";
import { CheckCircleIcon } from "@heroicons/react/solid";
const { Title } = Typography;

interface Props {
  pcrPlate: PcrPlate;
}
export const PlateCompleted = ({ pcrPlate }: Props) => {
  const id = pcrPlate?.id;

  const pcrPlateFinishedByUser = pcrPlate?.finishedBy?.name || "-"

  
  

  const getFilledStatus = () => {
    try {
      const jsonString = pcrPlate?.entriesJson || "[]";
      const json = JSON.parse(jsonString);

      return json.length;
    } catch (e) {
      console.log(e);
    }
  };

  const filledCount = getFilledStatus();

  return (
    <div>
      <div className="mt-6">
        <div className="bg-green-300">
        <Title level={3}>PCR-Platte Bestückung Abgeschloßen</Title>

        </div>

        <div className="w-72 text-xs">
          <div className="grid grid-cols-2">
            <div>Abgeschloßen am</div>
            <div className="text-indigo-500 font-bold">
              {formatDate(pcrPlate?.finishedAt)}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div>Abgeschloßen von</div>
            <div className="text-indigo-500 font-bold">{pcrPlateFinishedByUser}</div>
          </div>

          <div className="grid grid-cols-2">
            <div>Anzahl Proben</div>
            <div className="text-indigo-500 font-bold">{filledCount}</div>
          </div>
        </div>
      </div>



<UploadPcrPlateResult pcrPlate={pcrPlate} />


    </div>
  );
};
