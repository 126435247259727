import { TestTransportJob } from "generated/schema";
import { Button, Card, Divider, Radio, Spin } from "antd";

import {
  useTestResultsLazyQuery,
  Direction,
  useTestTransportJobsLazyQuery,
  useTestTransportJobChangedSubscription,
} from "generated/schema";
import { useContext, useEffect, useState } from "react";

import formatDate from "utils/formatDate";
import {
  CourierListHeader,
  CourierListEntry,
  CourierListDataHandler,
  TextExpand,
} from "components/CourierPlan/CourierList";
import moment from "moment";
import { ApproveDeliveredButton } from "./ApproveDeliveredButton";
export const DeliveredTransportJobs = ({ courierGroupId }: any) => {
  const [getTransportJobs, { data, loading }] = useTestTransportJobsLazyQuery({
    fetchPolicy: "network-only",
  });

  const {
    data: dataChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useTestTransportJobChangedSubscription({ fetchPolicy: "network-only" });

  useEffect(() => {
    getTransportJobs({
      variables: {
        filters: [
          {
            fieldName: "testCenter.courierGroup.id",
            term: String(courierGroupId),
            exact: true,
          },
          {
            fieldName: "testTransportJob.deliveredAt",
            term: "not null",
            exact: true,
          },
          // { fieldName: "testType.transportJob.id", term: "null", exact: true },
        ],
        orderBy: { fieldName: "testTransportJob.id", direction: Direction.Asc },
      },
    });
  }, [courierGroupId, dataChangedData]);

  const ongoingTransportJobsData = data?.testTransportJobs || [];

  return (
    <div>
      <CourierListHeader>
        Kurierplanung
        <div className="text-xs font-normal">
          Anzahl Transportjobs: {ongoingTransportJobsData.length}
        </div>
      </CourierListHeader>
      <div>
        <CourierListDataHandler
          loading={loading}
          data={ongoingTransportJobsData}
        />
        {ongoingTransportJobsData.map((x: TestTransportJob) => {
          const name = `${x?.testCenter?.name}`;
          const contactPerson = x?.testCenter?.contactPerson;
          const telStation = x?.testCenter?.tel;
          const address = `${x?.testCenter?.street}, ${x?.testCenter?.zipCode} ${x?.testCenter?.city}`;
          const openingTimes = x?.testCenter?.openingTimes;
          const pickedUpByName = x?.pickedUpBy?.name || "-";

          const testResults = x?.testResults || [];
          debugger;
          return (
            <CourierListEntry>
              <div className="font-bold"> {name} </div>

              <div className="bg-red-50">
                <TextExpand
                  show={openingTimes || contactPerson || telStation}
                  lastRow={
                    address && (
                      <div className="whitespace-pre-line">{address}</div>
                    )
                  }
                >
                  <div className="whitespace-pre-line">{openingTimes}</div>

                  {contactPerson && (
                    <div className="whitespace-pre-line">
                      Ansprechpartner: {contactPerson}
                    </div>
                  )}
                  {telStation && (
                    <div className="whitespace-pre-line">
                      Tel. Station : {telStation}
                    </div>
                  )}
                </TextExpand>
              </div>
              <div className="text-xl">{testResults?.length} x OEDG</div>
              <div className="">Auslieferung spätestens: </div>
              <div className="text-xl text-black font-bold">
                {formatDate(new Date())}{" "}
              </div>
              <div className=" ">
                <Divider orientation="left" plain>
                  Abholdetails
                </Divider>
                <div>
                  <div className="grid grid-cols-2">
                    <div>Fahrauftragkennung</div>
                    <div>{x?.id}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>Abgeholt von</div>
                    <div className="font-bold">
                      <div className="flex">
                        <div>{pickedUpByName}</div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2">
                    <div>Abgeholt um</div>
                    <div>{moment().format("LLL")}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>Geliefert von</div>
                    <div>ali</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>Geliefert um</div>
                    <div>{moment().format("LLL")}</div>
                  </div>
                </div>
              </div>
              {/* <ApproveDeliveredButton transportJob={x} /> */}
            </CourierListEntry>
          );
        })}
      </div>
    </div>
  );
};
