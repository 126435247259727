import { Button, Select } from "antd";
import { AppCtx } from "context/AppState";
import {
  TestCustomer,
  TestType,
  useCreateTestResultMutation,
  useTestTypesQuery,
} from "generated/schema";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

interface Props {
  testCustomer: TestCustomer;
  onChange: any;
}
export const TestTypeSelectCheckIn = ({ onChange, testCustomer }: Props) => {
  const context = useContext(AppCtx);
  const navigate = useNavigate();

  const { data: testTypes } = useTestTypesQuery({
    fetchPolicy: "no-cache",
  });

  const [selectedTestTypeId, setSelectedTestTypeId]: any = useState();
  const [selectedTestTypeOption, setSelectedTestTypeOption]: any = useState();

  const services: TestType[] = context?.activeTestCenter?.services || [];

  const [createTest, { data, loading, error }] = useCreateTestResultMutation({
    fetchPolicy: "no-cache",
  });

  const handleChange = (value: string) => {
    // console.log("value", value);

    setSelectedTestTypeId(value);
    setSelectedTestTypeOption(null);
  };

  const onCreateTest = async () => {
    const testCenterId = context?.activeTestCenter?.id;
    const customerId = testCustomer?.id;
    await createTest({
      variables: {
        input: {
          testCenterId,
          customerId,
          testTypeId: selectedTestTypeId,
          testTypeOptionId: selectedTestTypeOption,
        },
      },
    });

    navigate("/test-results");
  };

  const selectedTestOptions: TestType | undefined = selectedTestTypeId
    ? testTypes?.testTypes?.find((x) => x?.id == selectedTestTypeId)
    : undefined;

  const options = selectedTestOptions ? selectedTestOptions?.options : [];

  const testTypeOptions = options ? options?.filter((x) => x?.active == true) : []

  return (
    <>
      <div className="text-xs">Kunde</div>

      <div className="font-bold mb-2">{testCustomer?.name}</div>
      <div className="mb-2">
        {" "}
        <span className="text-red-500">*</span> TestType Auswahl
      </div>
      <Select
        allowClear
        placeholder="Test-Typ"
        style={{ width: "100%" }}
        loading={false}
        onChange={handleChange}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => {
          return ((option?.children as unknown as string) || "")
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      >
        {services?.map((x) => {
          return <Option value={x?.id}>{x?.name}</Option>;
        })}
      </Select>
      <div className="my-2">
        {selectedTestOptions && <span className="text-red-500">* </span>}
        {selectedTestOptions?.optionsLabel || ""}
      </div>
      {selectedTestTypeId && (
        <Select
          allowClear
          value={selectedTestTypeOption}
          placeholder="Bitte Auswählen"
          style={{ width: "100%" }}
          loading={false}
          onChange={(v: string) => {
            setSelectedTestTypeOption(v);
          }}
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) => {
            return ((option?.children as unknown as string) || "")
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
        >
          {testTypeOptions?.map((x) => {
            return <Option value={x?.id}>{x?.name}</Option>;
          })}
        </Select>
      )}

      <div className="mt-8">
        <Button
          onClick={onCreateTest}
          disabled={!selectedTestTypeId || !selectedTestTypeOption}
          block
          type="primary"
          loading={loading}
        >
          Check-In
        </Button>
      </div>
    </>
  );
};
