import { TestCustomer } from "generated/schema";
import { CustomerForm } from "./Form";
interface Props {
  customer?: TestCustomer;
  onClose?: any;
}

export const TestCustomerForm = (props: Props) => {
  return (
    <div key={props?.customer?.id}>
      <CustomerForm onClose={props?.onClose} customer={props?.customer} />
    </div>
  );
};
