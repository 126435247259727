import { WorkTime } from "generated/schema";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import "moment-duration-format";
import { Button, Divider, List, Popconfirm, Typography } from "antd";
import { useCreateWorkTimeMutation } from "generated/schema";
import { AppCtx } from "context/AppState";
interface Props {
  workTime: WorkTime;
}
export const CheckInButton = () => {
  const context = useContext(AppCtx);
  const lat = context?.lat
  const lng = context?.lng

  const [createWorkTimeMutation, { data, loading, error }] =
    useCreateWorkTimeMutation({fetchPolicy : 'no-cache'});

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    const testCenterId = context?.activeTestCenter?.id;
    createWorkTimeMutation({ variables: { input: { testCenterId, lat : String(lat), lng : String(lng) } } });
    setConfirmLoading(true);

    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <div>
      <Popconfirm
        title={"Sind Sie sich sicher?"}
        open={open}
        onConfirm={handleOk}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
      >
        <Button
          style={{ background: "green", borderColor: "green" }}
          block
          type="primary"
          loading={loading}
          onClick={showPopconfirm}
        >
          Einchecken
        </Button>
      </Popconfirm>
    </div>
  );
};
