import { Menu, Dropdown, Grid, Button } from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { BeakerIcon, MenuAlt1Icon, XIcon } from "@heroicons/react/solid";
import { menuDefinition } from "./menuDef";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/solid";
import _get from "lodash/get";
import { useContext, useEffect, useState } from "react";
import { AppCtx } from "context/AppState";

const { useBreakpoint } = Grid;
export default () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const context = useContext(AppCtx);

  useEffect(() => {
    setShow(false);
  }, [location]);

  const ActiveButton = ({ x, showDownIcon }: any) => {
    return (
      <button
        type="button"
        className="text-white   bg-blue-500 hover:bg-blue-400 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
        onClick={() => {
          x.href && navigate(x.href);
        }}
      >
        <div className="mr-2">{x?.icon}</div>
        <div className="whitespace-nowrap"> {x.name}</div>
        <div> {showDownIcon && <ChevronDownIcon className="w-5 h-5" />}</div>
      </button>
    );
  };

  const InactiveButton = ({ x, showDownIcon }: any) => {
    return (
      <button
        type="button"
        className=" hover:bg-blue-400 focus:bg-blue-500 focus:text-white hover:text-white font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  "
        onClick={() => {
          x.href && navigate(x.href);
        }}
      >
        <div className="mr-2">{x?.icon}</div>
        <div className="whitespace-nowrap"> {x.name}</div>
        <div> {showDownIcon && <ChevronDownIcon className="w-5 h-5" />}</div>
      </button>
    );
  };

  let pathname = location.pathname;

  const split = pathname.split("/").reverse();
  const urlId = Number.isInteger(parseInt(_get(split, 0)));

  if (urlId) {
    split.shift();
    split.reverse();
    pathname = split.join("/");
  }

  const userActiveMenu = menuDefinition.filter((x) => {
    const userRights = context?.userRightIds || [];

    return userRights.includes(x.rightId);
  });

  if (window.location.pathname == "/center-select") {
    return <div></div>;
  }
  const Menus = userActiveMenu.map((x: any) => {
    if (x?.items?.length) {
      // has subroutes

      const isItemActive = x?.items.map((y: any) => y?.key);

      const subKeyActive = isItemActive.includes(pathname);

      return (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu
              selectedKeys={[pathname]}
              onClick={(menu) => {
                //setShow(false);
                menu.key && navigate(menu.key);
              }}
              items={x?.items || []}
            />
          }
        >
          <div>
            {(pathname == x.href || subKeyActive) && (
              <ActiveButton showDownIcon={true} x={x} />
            )}
            {pathname != x.href && !subKeyActive && (
              <InactiveButton showDownIcon={true} x={x} />
            )}
          </div>
        </Dropdown>
      );
    } else {
      return (
        <div>
          {pathname == x.href && <ActiveButton x={x} />}
          {pathname != x.href && <InactiveButton x={x} />}
        </div>
      );
    }
  });

  if (screens.xs) {
    return (
      <div
        // onClick={() => setShow(!show)}
        className="absolute bg-white left-24 "
      >
        <div onClick={() => setShow(!show)} className="flex mt-4 space-x-4">
          <MenuAlt1Icon className="w-6 h-6"></MenuAlt1Icon>{" "}
          <span className="text-base">Menü</span>
        </div>
        {show && <div className=" mt-4 space-y-2 shadow-xl p-4 ">{Menus}</div>}
      </div>
    );
  }

  return (
    <div className="space-x-4 flex font-bold  items-center  w-full ml-4  ">
      {Menus}
    </div>
  );
};
