import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { TestCustomer, WorkTime } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { TableTestStateButton } from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import formatDate from "utils/formatDate";
import moment from "moment";
import "moment-duration-format";
import { WorkTimeFormWrapper } from './Form/index';

export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 75,
  },
  {
    title: "Name",
    dataIndex: ["user"],
    key: "userId",
    width: 150,
    render: (text: any, row: WorkTime) => {
      const userName = row?.user?.name;
      const userId = row?.user?.id;
      return (
        <div>
          ({userId})-{userName}
        </div>
      );
    },
  },
  {
    title: "Testcenter",
    dataIndex: "name",
    key: "testCenter",
    width: 200,
    render: (text: string, row: WorkTime) => {
      const name = `${row?.testCenter?.name} `;
      return <div>{name}</div>;
    },
  },

  {
    title: "Start Zeitpunkt",
    dataIndex: "street",
    key: "street",
    width: 220,
    render: (text: any, row: WorkTime) => {
      const startAt = formatDate(row?.startAt);

      return (
        <div className="overflow-x-auto ">
          <div className="w-48">{startAt}</div>
        </div>
      );
    },
  },
  {
    title: "End Zeitpunkt",
    dataIndex: "street",
    key: "street",
    width: 220,
    render: (text: any, row: WorkTime) => {
      const startAt = formatDate(row?.endAt);

      return (
        <div className="overflow-x-auto ">
          <div className="w-48">{startAt}</div>
        </div>
      );
    },
  },
  {
    title: "Dauer",
    dataIndex: "duration",
    key: "duration",
    width: 220,
    render: (text: any, row: WorkTime) => {
      const startAt = row?.startAt;
      const endAt = row?.endAt;

      if (!endAt) return <div>-</div>;

      const startUnix = moment(startAt).unix();
      const endUnix = moment(endAt).unix();

      const duration = moment
        .duration(endUnix - startUnix, "seconds")
        .format("hh:mm:ss");

      return (
        <div className="overflow-x-auto ">
          <div className="w-48">{duration}</div>
        </div>
      );
    },
  },

  {
    title: "Start Location",
    dataIndex: "lat",
    key: "lat",
    width: 125,

    render: (text: any, row: WorkTime) => {
      const diff = text;
      const lat = row?.startLat;
      const lng = row?.startLng;

      if (!lat) return <div>-</div>;
      if (!lng) return <div>-</div>;
      const url = "https://www.google.de/maps/@" + lat + "," + lng + ",19z";

      return (
        <Button size="small" type="default">
          <a target="_blank" href={url}>
            Google Maps
          </a>
        </Button>
      );
    },
  },
  {
    title: "Ende Location",
    dataIndex: "lat",
    key: "lat",
    width: 125,

    render: (text: any, row: WorkTime) => {
      const diff = text;
      const lat = row?.endLat;
      const lng = row?.endLng;

      if (!lat) return <div>-</div>;
      if (!lng) return <div>-</div>;
      const url = "https://www.google.de/maps/@" + lat + "," + lng + ",19z";

      return (
        <Button size="small" type="default">
          <a target="_blank" href={url}>
            Google Maps
          </a>
        </Button>
      );
    },
  },
  {
    title: "Gerät Start",
    dataIndex: "phone",
    key: "phone",
    width: 400,
    render: (text: any, row: WorkTime) => {
      const startUserAgent = row?.startUserAgent;
      return <div className="text-xs">{startUserAgent}</div>;
    },
  },

  {
    title: "Gerät Ende",
    dataIndex: "phone",
    key: "phone",
    width: 400,
    render: (text: any, row: WorkTime) => {
      const startUserAgent = row?.endUserAgent;
      return <div className="text-xs">{startUserAgent}</div>;
    },
  },

  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text: string, row: TestCustomer) => {
      const email = text || "-";
      return <div>{email}</div>;
    },
    width: 250,
  },
  {
    title: "Standort",
    dataIndex: "-",
    key: "testCenter",
    render: (text: string, row: TestCustomer) => {
      const testCenter = row?.testCenter?.name;
      const email = testCenter || "-";
      return <div>{email}</div>;
    },
    width: 250,
  },

  {
    title: "Action",
    key: "action",
    render: (_, row: WorkTime) => {

      const id = row?.id;
      const customerFirstName = row?.user?.firstName || "";
      const customerLastName = row?.user?.lastName || "";
      const title = `Editiere Arbeitszeit: ${customerFirstName} ${customerLastName} (${row?.user?.id})`;
      return (
        <Space size="middle">
          <Space size="middle">
            <MyDrawer
              title={title}
              triggerElement={
                <Button size="small" type="primary">
                  Edit
                </Button>
              }
            >
              <WorkTimeFormWrapper workTime={row} />
            </MyDrawer>
          </Space>
        </Space>
      );
    },
  },
];
