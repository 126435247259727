import { PcrPlate } from "generated/schema";
import React, { useState } from "react";
import { PcrPlateGrid } from "./PlateGrid";

import { useTestResultsByIdsQuery } from "generated/schema";
interface Props {
  data: any;
  pcrPlate?: PcrPlate;
  nextFreeSlot: any;
}
export const PcrPlateView = ({ data, pcrPlate, nextFreeSlot }: Props) => {
  let testResultIds = [];
  try {
    const entriesJson = pcrPlate?.entriesJson;

    if (entriesJson) {
      const testIdsArr = JSON.parse(entriesJson);
      const testIds = testIdsArr.map((x: any) => x?.testId);

      testResultIds = testIds;
    }
  } catch (e) {
    console.log(e);
  }

  const {
    data: testResultsData,
    loading,
    error,
  } = useTestResultsByIdsQuery({
    variables: {
      ids: testResultIds,
      lite: true,
    },
    skip: testResultIds.length == 0,
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <div className="grid grid-cols-6">

      <div className="flex space-x-2">
          <div className="w-5 h-5 bg-white border"></div>
          <div>Nächste Beleg. Pos</div>
        </div>
      
        <div className="flex space-x-2">
          <div className="w-5 h-5 bg-blue-200 border"></div>
          <div>Belegt, ohne Ergebnis</div>
        </div>

        <div className="flex space-x-2">
          <div className="w-5 h-5 bg-green-200 border"></div>
          <div>Negativ</div>
        </div>

        <div className="flex space-x-2">
          <div className="w-5 h-5 bg-red-300 border"></div>
          <div>Positive</div>
        </div>

        <div className="flex space-x-2">
          <div className="w-5 h-5 bg-white border"></div>
          <div>Leere Zelle</div>
        </div>

        <div className="flex space-x-2">
          <div className="w-5 h-5 bg-yellow-200 border"></div>
          <div>Erg. Ungültig</div>
        </div>
      </div>
      <PcrPlateGrid
        testResults={testResultsData?.testResultsByIds}
        pcrPlate={pcrPlate}
        data={data}
        nextFreeSlot={nextFreeSlot}
      />
    </div>
  );
};
