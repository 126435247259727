import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
  Radio,
  Alert,
  Divider,
} from "antd";

import styled from "styled-components";
import {
  IdentificationIcon,
  CalendarIcon,
  AtSymbolIcon,
  PhoneIcon,
  ClipboardListIcon,
  InformationCircleIcon,
  CurrencyEuroIcon,
  PencilIcon,
} from "@heroicons/react/solid";

import { Modal } from "antd";

import {
  CashType,
  TestResult,
  useUpdateTestResultMutation,
} from "generated/schema";
import { SignaturePad } from "components/SignaturePad";

import { validatePassword } from "utils/validatePassword";
import formatCurrency from "utils/formatCurrency";
import { TestFormLite } from "../TestResultForm/TestResultFormLite";
const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  testResult: TestResult;
  onClose?: any;
  modalTitle: any;
}

export const TestResultSignatureForm = ({
  testResult,
  onClose,
  modalTitle,
}: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [form] = Form.useForm();
  const [updateTest, updateReq] = useUpdateTestResultMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signature1, setSignature1] = useState();
  const [signature2, setSignature2] = useState();
  const [cashType, setCashType] = useState();

  const signatureRequired = testResult?.testType?.signatureRequired;
  const isSchnellTest = testResult?.testType?.id == 4;
  const customerSignature = testResult?.customerSignature;
  const hasAlreadySignature = customerSignature && customerSignature && true;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: any) => {
    // console.log("Received values of form: ", values);

    // updateTest({ variables: { id: testResult.id, input: values } });

    setIsModalOpen(false);

    success();
    // onClose && onClose();
  };

  let initValues = {
    ...testResult,
  };

  const showPrice =
    testResult?.testTypeOption?.price != null &&
    testResult?.testTypeOption?.price > 0;

  const showTestProofText = testResult?.testType?.showProofTextInput;

  const showPencil = testResult?.testFinishedAt;

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        danger
        size="small"
        type="primary"
      >
        {!showPencil && <PencilIcon className="h-5 w-5" />}
        {false && !isSchnellTest && <PencilIcon className="h-5 w-5" />}
        {false && isSchnellTest && <CurrencyEuroIcon className="h-5 w-5" />}
      </Button>
      <Modal
        title={modalTitle + " - TestID: " + testResult?.id}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        {/* {!isSchnellTest && (
          <div className="space-y-2">
            <div>
              <div className="font-bold">Kunde:</div>
              <div>
                Hiermit bestätige ich die Richtigkeit der gemachten Angaben
              </div>
            </div>
            <div>
              <div className="font-bold">Mitarbeiter:</div>
              <div>
                Die durch die Testperson vorgenommenen personenbezogenen Angaben
                wurden seitens der Teststelle auf Richtigkeit überprüft
              </div>
            </div>
          </div>
        )} */}

        <div className="mb-2">
          <Alert
            message={
              <div className="text-xs">
                Hier haben Sie die Möglichkeit bereits bekannte Informationen
                einzutragen. Sie können bevor Sie den Test starten auch noch den Test-Typ ändern.
              </div>
            }
            type="info"
          />
        </div>

        <TestFormLite onClose={onFinish} testResult={testResult} />

        {/* <SForm
          onFieldsChange={(e) => {
            setFormValuesChanged(true);
          }}
          layout="vertical"
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={initValues}
          scrollToFirstError
        >
          <>
            {showPrice && (
              <>
                <div className="flex  space-x-2 mb-6 ">
                  <div className="flex-1">Offener Betrag:</div>
                  <div className="text-xl font-bold">
                    {formatCurrency(testResult?.testTypeOption?.price)}
                  </div>
                </div>
                <div>
                  <Form.Item
                    name={"cashType"}
                    label="Zahlungsart"
                    rules={[{ required: true, message: "Pflichtfeld" }]}
                  >
                    <Radio.Group
                      onChange={(v) => {
                        const value = v.target.value;
                        form.setFieldsValue({ cashType: value });
                        setCashType(value);
                      }}
                    >
                      <Radio value={CashType.Card}>Kartenzahlung</Radio>
                      <Radio value={CashType.Cash}>Barzahlung</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </>
            )}

            {signatureRequired && (
              <>
                <Divider plain>Selbstauskunft</Divider>

                {showTestProofText && (
                  <Form.Item
                    label="Nachweis Info"
                    name="testProofText"
                    rules={[
                      { required: true, message: "Nachweis Info (Pflicht)" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                )}

                <div className="grid grid-cols-2">
                  <Form.Item
                    label="Kunde Unterschrift"
                    name="customerSignature"
                    rules={[{ required: true, message: "Pflichtfeld" }]}
                  >
                    <SignaturePad
                      initialValue={null}
                      onSave={(base64: any) => {
                        form.setFieldsValue({ customerSignature: base64 });
                        setFormValuesChanged(true);
                        setSignature1(base64);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mitarbeiter Unterschrift"
                    name="userSignature"
                    rules={[{ required: true, message: "Pflichtfeld" }]}
                  >
                    <SignaturePad
                      initialValue={null}
                      onSave={(base64: any) => {
                        form.setFieldsValue({ userSignature: base64 });
                        setFormValuesChanged(true);
                        setSignature2(base64);
                      }}
                    />
                  </Form.Item>
                </div>
              </>
            )}
          </>

          <Form.Item>
            {signatureRequired && (
              <Button
                disabled={!(signature1 && signature2)}
                block
                type="primary"
                htmlType="submit"
                className="mt-4"
              >
                Speichern
              </Button>
            )}

            {!signatureRequired && (
              <Button
                disabled={!cashType}
                block
                type="primary"
                htmlType="submit"
                className="mt-4"
              >
                Speichern
              </Button>
            )}
          </Form.Item>
        </SForm> */}
      </Modal>
    </>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
