import { PcrPlate, TestResult } from "generated/schema";
import React, { useState } from "react";

import { useTestResultsByIdsQuery } from "generated/schema";
import { PcrTestResultWrapper } from "./PcrTestResultWrapper";
import { MyDrawer } from "components/MyDrawer";
import { Alert, Button, Divider, Modal } from "antd";
import { RemoveFromPlateButton } from "./RemoveFromPlateButton";
interface Props {
  testResult: TestResult;
  pcrPlate?: PcrPlate;
  cell?: any;
}
export const ResultInvalidCell = ({ testResult, pcrPlate, cell }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const customerName = `${testResult?.customer?.firstName || "-"} ${
    testResult?.customer?.lastName || "-"
  } `;

  const dateOfBirthday = testResult?.customer?.dateOfBirth;
  const customerId = testResult?.customer?.id || "";
  const firstName = testResult?.customer?.firstName;
  const lastName = testResult?.customer?.lastName;
  const testId = testResult?.id;
  const title = `TestId: ${testId},  (${customerId}) - ${firstName} ${lastName}, ${dateOfBirthday}  `;
  return (
    <div className=" border-b border-r w-20 h-20 text-xs flex flex-col cursor-pointer">
      <div
        onClick={showModal}
        className="flex-1  bg-yellow-300  hover:bg-yellow-100 "
      >
        {customerName}
      </div>
      <MyDrawer
        title={title}
        triggerElement={
          <div className="bg-gray-300 w-full">TestId {testResult?.id}</div>
        }
      >
        <PcrTestResultWrapper testResultId={testResult?.id} />
      </MyDrawer>
      <div>
        <Modal
          destroyOnClose
          title="Platten Zelle"
          open={isModalOpen}
          // onOk={handleOk}
          onCancel={closeModal}
          footer={null}
        >
          {/* <Title level={4}>Kundendetails</Title> */}
          <div className="flex flex-col ">
            <div className="flex-1">
              <div>
                <div className="grid grid-cols-2">
                  <div>Kunden ID:</div>
                  <div>{testResult?.customer?.id}</div>
                </div>
              </div>

              <div>
                <div className="grid grid-cols-2">
                  <div>Kunde:</div>
                  <div>{customerName}</div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div>
                <div className="grid grid-cols-2">
                  <div>Test ID:</div>
                  <div>{testResult?.id}</div>
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div>Position</div>
                <div>{cell?.posIndex}</div>
              </div>
            </div>
          </div>

          <Divider plain>Optionen</Divider>

          <div>
            {pcrPlate && (
              <RemoveFromPlateButton
                closeModal={closeModal}
                testResult={testResult}
                pcrPlate={pcrPlate}
              />
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};
