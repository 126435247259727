import React from "react";
import { TestResult } from "generated/schema";
import { DownloadDocumentButton } from "./DownloadDocumentButton";
import { Button, Card, Divider } from "antd";
import { EmailSent } from "./EmailSent";
import QRCode from "react-qr-code";
import { Collapse } from "antd";
import formatDate from "utils/formatDate";
import { CwaAgainButton } from "./CwaAgainButton";
import { DownloadDemisReportButton } from "./DownloadDemisReportButton/index";
const { Panel } = Collapse;
interface Props {
  testResult: TestResult;
}
export const Documents = ({ testResult }: Props) => {
  const pcrOegdTest = testResult?.testType?.id == 2;
  const pcrTest = testResult?.testType?.id == 3;

  let slipReportName =
    testResult?.id + "_" + testResult?.customer?.id + "_TEST_LZ.pdf";

  if (pcrOegdTest) {
    slipReportName =
      testResult?.id + "_" + testResult?.customer?.id + "_PCR_OEGD_LZ.pdf";
  }

  if (pcrTest) {
    slipReportName =
      testResult?.id + "_" + testResult?.customer?.id + "_PCR_LZ.pdf";
  }

  const isSTD = testResult?.testCenter?.userGroup?.id == 50


  const isBillEnabled = testResult?.testCenter?.userGroup?.vita1 || isSTD
  const hasBillId = testResult?.billId;

  const showBill = isBillEnabled && hasBillId;

  return (
    <div className="">
      <Divider plain>Laufzettel</Divider>

      <div className="flex flex-col space-y-2">
        <div className="grid md:grid-cols-2">
          <div className="">
            <DownloadDocumentButton
              fileName={slipReportName}
              text={"Laufzettel"}
              testResult={testResult}
              endpoint="slip"
            />
          </div>
        </div>

        {/* <div className="grid grid-cols-2">
          <div className="">
            <DownloadDocumentButton
              fileName="slipReportName"
              text={"Demis Bericht"}
              testResult={testResult}
              endpoint="slip"
            />
          </div>
        </div> */}
      </div>

      {testResult?.testType?.id == 1 && (
        <div>
          <Divider plain>Selbstauskunft</Divider>

          <div className="flex flex-col space-y-2 mt-2">
            <div className="grid md:grid-cols-2">
              <div className="">
                <DownloadDocumentButton
                  fileName={
                    testResult?.id + "_" + testResult?.customer?.id + "_SA.pdf"
                  }
                  text={"Selbstauskunft"}
                  testResult={testResult}
                  endpoint="selbstauskunft"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {testResult?.testType?.id == 2 && (
        <div>
          <Divider plain>OEGD-Schein</Divider>

          <div className="flex flex-col space-y-2 mt-2">
            <div className="grid md:grid-cols-2">
              <div className="">
                <DownloadDocumentButton
                  fileName={
                    testResult?.id +
                    "_" +
                    testResult?.customer?.id +
                    "_OEGD.pdf"
                  }
                  text={"OEGD-Schein"}
                  testResult={testResult}
                  endpoint="oegdSlip"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Divider plain>Attest</Divider>

      <div className="flex flex-col space-y-2 mt-2">
        <div className="grid md:grid-cols-2">
          <div className="">
            <DownloadDocumentButton
              fileName={
                testResult?.id + "_" + testResult?.customer?.id + "_RESULT.pdf"
              }
              text={"Attest"}
              testResult={testResult}
              endpoint="result"
            />

            <div>
              <EmailSent testResult={testResult} />
            </div>
          </div>
        </div>
      </div>

      {showBill && (
        <div>
          <Divider plain>Rechnung</Divider>

          <div className="flex flex-col space-y-2 mt-2">
            <div className="grid md:grid-cols-2">
              <div className="">
                <DownloadDocumentButton
                  fileName={
                    testResult?.id +
                    "_" +
                    testResult?.customer?.id +
                    "_RECHNUNG.pdf"
                  }
                  text={"Rechnung"}
                  testResult={testResult}
                  endpoint="bill"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {testResult?.result && (
        <div>
          <Divider plain>Corona-Warn-App</Divider>

          <div className="flex flex-col space-y-2 mt-2">
            <div className="grid md:grid-cols-1">
              <div className="">
                <div>
                  <Collapse>
                    <Panel header="CWA-Anbindung Details" key="1">
                      {testResult?.cwaReport?.url && !testResult?.cwaNone && (
                        <div style={{ background: "white" }}>
                          <p>CWA-QR-CODE</p>
                          <QRCode
                            size={128}
                            value={testResult?.cwaReport?.url}
                          />
                          <div className="mt-8 grid grid-cols-2">
                            <div>Meldungsart:</div>
                            {testResult?.cwaAnonym && (
                              <div>Anonyme Meldung</div>
                            )}
                            {testResult?.cwaNotAnonym && (
                              <div>Namentliche Meldung</div>
                            )}
                          </div>
                          <div className="grid grid-cols-2">
                            <div>gemeldet am:</div>
                            <div>
                              {formatDate(testResult?.cwaReport?.createdAt)}
                            </div>
                          </div>

                          <div className="mt-2">
                            <CwaAgainButton testResult={testResult} />
                          </div>
                        </div>
                      )}

                      {testResult?.cwaNone && (
                        <div style={{ background: "white" }}>
                          Der Kunde hat CWA Meldung nicht akzeptiert.
                        </div>
                      )}
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {testResult?.result?.id == 1 && (
        <div>
          <Divider plain>Demis-Report</Divider>

          <div className="flex flex-col space-y-2 mt-2">
            <div className="grid md:grid-cols-1">
              <div className="">
                <div>
                  <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Demis Report Details" key="1">
                      <div style={{ background: "white" }}>
                        <div className="mt-2">
                          <DownloadDemisReportButton testResult={testResult} />
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
