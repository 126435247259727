import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { TestPage } from "pages/Test";
import { TestResultWrapper } from "components/TestResult";
import { TestCenterPage } from "pages/TestCenterPage";
import { BookPage } from "pages/BookPage";
import { CustomerPage } from "pages/Customer";
import { StaffPage } from "pages/StaffPage";
import { HomePage } from "pages/Home/index";
import MenuNav from "components/MenuNav";
import { NavTab } from "components/NavTab";
import TestForm from "pages/Test/TestForm/index";
import { RolesPage } from "pages/RolesPage";
import { UserGroupPage } from "pages/UserGroupPage";
import { CourierPlanPage } from "pages/CourierPlanPage";
import { FilesUploadPage } from "pages/FilesUploadPage";
import { CourierGroupPage } from "pages/CourierGroupsPage";
import { CreatePcrPlatePage } from "pages/CreatePcrPlatePage";
import { PcrPlatePage } from "pages/PcrPlatePage";
import { LaboratoryPage } from "pages/LaboratoryPage";
import { LoginPage } from "pages/LoginPage/index";
import useToken from "hooks/useJwtToken";
import { AppCtx } from "context/AppState";
import { useContext } from "react";
import TestCenterSelectPage from "./../pages/TestcenterSelectPage/index";
import { AnalyticsPage } from "pages/AnalyticsPage";
import { LogEventsPage } from "pages/LogEventsPage";
import { TimeTrackingPage } from "pages/TimeTrackingPage";
import { RightsEnumType } from "generated/schema";
import { RightsMap } from "const/rights";
import { CreateTestResultCockpit } from "pages/Cockpit";
import { OpeningTimePage } from "pages/OpeningTimesPage";
export const AppRoutes = () => {
  const appContext = useContext(AppCtx);

  const hasUserRight = appContext?.hasUserRight
    ? appContext?.hasUserRight
    : () => {};

  if (!appContext?.userRequested) {
    return (
      <Routes>
        <Route path="/loading" element={<>Loading</>} />
      </Routes>
    );
  }

  if (window.location.pathname == "/login") {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    );
  }

  if (window.location.pathname == "/book") {
    return (
      <Routes>
        <Route path="/book" element={<BookPage />} />
      </Routes>
    );
  }

  if (!appContext?.user) {
    if (window.location.pathname !== "/login") {
      return (
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      );
    }
  }

  return (
    <>
      <div className="  pb-1 bg-gray-100">
        <header className="shadow-md sticky inset-x-0 top-0 z-50 bg-white border-b-2 border-gray-100">
          <div className="flex items-center justify-between h-16  px-4 mx-auto">
            <NavTab breadCrumbLocation={""} />
          </div>
        </header>

        <div className="m-2  bg-white">
          <div>
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/center-select" replace />}
              />

              {hasUserRight(RightsEnumType.RightTestResultView) && (
                <Route path="/test-results" element={<TestPage />} />
              )}
              {hasUserRight(RightsEnumType.RightTestResultView) && (
                <Route
                  path="/test-results/:id"
                  element={<TestResultWrapper />}
                />
              )}

              {hasUserRight(RightsEnumType.MasterDataView) && (
                <Route path="/test-centers" element={<TestCenterPage />} />
              )}
              {hasUserRight(RightsEnumType.CustomerView) && (
                <Route path="/customers" element={<CustomerPage />} />
              )}
              <Route path="/book" element={<BookPage />} />
              <Route path="/center-select" element={<TestCenterSelectPage />} />
              {hasUserRight(RightsEnumType.MasterDataView) && (
                <Route path="/employees" element={<StaffPage />} />
              )}
              {hasUserRight(RightsEnumType.MasterDataView) && (
                <Route path="/roles" element={<RolesPage />} />
              )}
              {hasUserRight(RightsEnumType.MasterDataView) && (
                <Route path="/user-groups" element={<UserGroupPage />} />
              )}
              {hasUserRight(RightsEnumType.CourierPageView) && (
                <Route
                  path="/courier-transport"
                  element={<CourierPlanPage />}
                />
              )}
              {hasUserRight(RightsEnumType.MasterDataView) && (
                <Route path="/logos" element={<FilesUploadPage />} />
              )}
              {hasUserRight(RightsEnumType.MasterDataView) && (
                <Route path="/courier-groups" element={<CourierGroupPage />} />
              )}
              {hasUserRight(RightsEnumType.PcrLaborViewRight) && (
                <Route path="/pcr-plates" element={<PcrPlatePage />} />
              )}
              {hasUserRight(RightsEnumType.PcrLaborViewRight) && (
                <Route
                  path="/pcr-fill-plates"
                  element={<CreatePcrPlatePage />}
                />
              )}
              {hasUserRight(RightsEnumType.MasterDataView) && (
                <Route path="/laboratories" element={<LaboratoryPage />} />
              )}
              {hasUserRight(RightsEnumType.PcrLaborViewRight) && (
                <Route
                  path="/pcr-fill-plates/:id"
                  element={<CreatePcrPlatePage />}
                />
              )}

              {hasUserRight(RightsEnumType.AnalyticsRight) && (
                <Route path="/analytics" element={<AnalyticsPage />} />
              )}

              {hasUserRight(RightsEnumType.LogEventsRight) && (
                <Route path="/log-events" element={<LogEventsPage />} />
              )}

              {hasUserRight(RightsEnumType.TimeTrackingViewRight) && (
                <Route path="/time-tracking" element={<TimeTrackingPage />} />
              )}

              <Route path="/cockpit" element={<CreateTestResultCockpit />} />

              {hasUserRight(RightsEnumType.AnalyticsRight) && (
                <Route path="/openingTimes" element={<OpeningTimePage />} />
              )}

              {/*
      <Route
        path="/reports"
        element={<Navigate replace to="/reports/buerger-test" />}
      />
      <Route path="/reports/pcr" element={<ReportsPage />} />
      <Route path="/reports/pcr-oegd" element={<ReportsPage />} />
      <Route path="/reports/buerger-test" element={<ReportsPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/test-centers" element={<TestCenterPage />} />
      <Route path="/roles" element={<RolePage />} />
      <Route path="/employees" element={<EmployeePage />} />
      <Route path="/user-groups" element={<UserGroupPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/intern" element={<CustomerPage />}>
        <Route path="new" element={<App />} />
        <Route index element={<App />} />
      </Route>
 */}

              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                    <button
                      className="text-2xl text-blue-400"
                      onClick={() => window.history.back()}
                    >
                      Go Back
                    </button>
                  </main>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};
