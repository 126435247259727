import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Image, Button, Form, Input, message, Upload } from "antd";

import React, { useRef, useState } from "react";
import {
  useUploadFileMutation,
  useCreateFilesUploadMutation,
  useUpdateFilesUploadMutation,
  FilesUpload,
  useCreateTestCourierGroupMutation,
  useUpdateTestCourierGroupMutation,
  TestCourierGroup,
} from "generated/schema";
import { resolve } from "path";

interface Props {
  onClose?: any;
  courierGroup?: TestCourierGroup;
}

export const CourierGroupForm = ({ onClose, courierGroup }: Props) => {
  const [form] = Form.useForm();

  const [createTestCourierGroupMutation, createReq] =
    useCreateTestCourierGroupMutation({});

  const [updateTestCourierGroupMutation, updateReq] =
    useUpdateTestCourierGroupMutation({});

  const onFinish = async (values: any) => {
    if (courierGroup?.id) {
      updateTestCourierGroupMutation({
        variables: {
          id: courierGroup?.id,
          input: { name: values.name },
        },
      });
    } else {
      createTestCourierGroupMutation({
        variables: { input: { name: values.name } },
      });
    }

    onClose && onClose();
  };

  return (
    <Form
      initialValues={courierGroup}
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Pflichtfeld",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Speichern
        </Button>
      </Form.Item>
    </Form>
  );
};
