import React from "react";
import { PcrPlate } from "generated/schema";
import formatDate from "utils/formatDate";

import { Button, Divider, Typography } from "antd";
import { MyDrawer } from "components/MyDrawer";
import { UploadPcrPlateResultForm } from "./UploadPcrPlateResultForm";

const { Title } = Typography;

interface Props {
  pcrPlate: PcrPlate;
}
export const UploadPcrPlateResult = ({ pcrPlate }: Props) => {
  const resultUploaded = pcrPlate?.resultsUploadedAt;

  const pcrPlateFinishedByUser = pcrPlate?.finishedBy?.name || "-"



  if (resultUploaded) {
    return (
      <div>
        <Divider plain>PCR-Test Ergebnis hochladen</Divider>
        <div className="mt-6 ">
          <Title level={3}>PCR-TEST AUSWERTUNG-UPLOAD</Title>

          <div>CSV Datei wurde hochgeladen</div>

          <div className="w-72 text-xs">
            <div className="grid grid-cols-2">
              <div>Hochgeladen am</div>
              <div className="text-indigo-500 font-bold">
                {formatDate(pcrPlate?.resultsUploadedAt)}
              </div>
            </div>
            {/* <div className="grid grid-cols-2">
              <div>Hochgeladen von</div>
              <div className="text-indigo-500 font-bold">Deniz Peterson</div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Divider plain>PCR-Test Ergebnis hochladen</Divider>
      <div className="mt-6 bg-yellow-100">
        <Title level={3}>PCR-TEST AUSWERTUNG-UPLOAD AUSSTEHEND</Title>

        <div></div>
      </div>

      <div className="mt-6">
        <MyDrawer
          title={"CSV-Upload - Nummer PCR-Platte: " + pcrPlate?.id}
          triggerElement={
            <Button block={true} type="primary">
              CSV-Hochladen
            </Button>
          }
        >
          <UploadPcrPlateResultForm pcrPlateId={pcrPlate?.id} />
        </MyDrawer>
      </div>
    </div>
  );
};
