import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { TestTableWrapper } from "components/TestTable";
import { TestTableSearch } from "components/TestTable/Search";
// import { TestPageMenu } from "./TestMenu";
import {
  useTestResultsLazyQuery,
  useTestResultChangedSubscription,
  useTestResultCountLazyQuery,
} from "generated/schema";
import moment from "moment";
import Analytics from "components/Analytics";

export const AnalyticsPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4 h-full">
          {/* <TestPageMenu defaultValue={"/user-groups"} /> */}
          {/* <div className="mb-4">
            <TestTableSearch />
          </div> */}

          <Analytics />
        </div>
      </PageWrapper>
    </div>
  );
};
