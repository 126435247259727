import { PcrPlate, TestResult } from "generated/schema";
import React, { useState } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { useTestResultsByIdsQuery } from "generated/schema";
import { Alert, Button, Divider, Modal } from "antd";
import { Typography } from "antd";
import { message, Popconfirm } from "antd";
import { RemoveFromPlateButton } from "./RemoveFromPlateButton";
import { MyDrawer } from "components/MyDrawer";
import { PcrTestResultWrapper } from "./PcrTestResultWrapper";

const { Title } = Typography;
interface Props {
  testResult: TestResult;
  pcrPlate?: PcrPlate;
  cell?: any;
}
// filled but not in machine

export const FilledGridBox = ({ testResult, pcrPlate, cell }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const customerName = `${testResult?.customer?.firstName || "-"} ${
    testResult?.customer?.lastName || "-"
  } `;

  const dateOfBirthday = testResult?.customer?.dateOfBirth;
  const customerId = testResult?.customer?.id || "";
  const firstName = testResult?.customer?.firstName;
  const lastName = testResult?.customer?.lastName;
  const testId = testResult?.id;
  const title = `TestId: ${testId},  (${customerId}) - ${firstName} ${lastName}, ${dateOfBirthday}  `;

  return (
    <div>
      <div className=" border-b border-r w-20 h-20 text-xs flex flex-col cursor-pointer">
        <div
          onClick={showModal}
          className="flex-1  bg-blue-200  hover:bg-blue-100 "
        >
          {customerName}
        </div>

        <MyDrawer
          title={title}
          triggerElement={
            <div className="bg-gray-300 w-full">TestId {testResult?.id}</div>
          }
        >
          <PcrTestResultWrapper testResultId={testResult?.id} />
        </MyDrawer>
      </div>

      <Modal
        destroyOnClose
        title="Platten Zelle"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={closeModal}
        footer={null}
      >
        <Title level={4}>Kundendetails</Title>
        <div className="flex flex-col ">
          <div className="flex-1">
            <div>
              <div className="grid grid-cols-2">
                <div>Kunden ID:</div>
                <div>{testResult?.customer?.id}</div>
              </div>
            </div>

            <div>
              <div className="grid grid-cols-2">
                <div>Kunde:</div>
                <div>{customerName}</div>
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div>
              <div className="grid grid-cols-2">
                <div>Test ID:</div>
                <div>{testResult?.id}</div>
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div>Position</div>
              <div>{cell?.posIndex}</div>
            </div>
          </div>
        </div>

        <Divider plain>Optionen</Divider>

        <div>
          {pcrPlate && !pcrPlate?.finishedAt && (
            <RemoveFromPlateButton
              closeModal={closeModal}
              testResult={testResult}
              pcrPlate={pcrPlate}
            />
          )}

          {pcrPlate && pcrPlate?.finishedAt && (
            <>
              <div className="mb-2">
                <Alert
                  message={
                    <div>
                      Das Löschen einer Probe ist nicht mehr möglich, da die
                      Platte breits abgeschloßen wurde
                    </div>
                  }
                  type="error"
                />
              </div>

              <Button type="primary" disabled={true}>
                Löschen
              </Button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
