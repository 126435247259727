import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";

interface Props {
  value: string[];
}
export const PageBreadcrumb = ({ value }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="mb-2">
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => {
            navigate("/");
          }}
        >
          <a>Home</a>
        </Breadcrumb.Item>
        {value.map((x) => {
          return <Breadcrumb.Item>{x}</Breadcrumb.Item>;
        })}
      </Breadcrumb>
    </div>
  );
};
