import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
  Radio,
  Alert,
} from "antd";
// import { AppCtx } from "context/AppState";
// import {
//   useCreateCustomerMutation,
//   namedOperations,
//   useUpdateCustomerMutation,
// } from "generated/schema";
import styled from "styled-components";
import {
  IdentificationIcon,
  CalendarIcon,
  AtSymbolIcon,
  PhoneIcon,
  ClipboardListIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import {
  TestCenter,
  useUpdateTestCenterOpenTimesMutation,
} from "generated/schema";

import moment from "moment";

import { TimePicker } from "antd";
import type { Moment } from "moment";
const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  testCenter?: TestCenter;
  onClose?: any;
}

export const TestCenterOpeningTimesForm = ({ testCenter, onClose }: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const moStartInit = testCenter?.moStart
    ? moment(testCenter?.moStart, "HH:mm:ss")
    : null;

  debugger;
  const moEndInit = testCenter?.moEnd
    ? moment(testCenter?.moEnd, "HH:mm:ss")
    : null;

  const saStartInit = testCenter?.satStart
    ? moment(testCenter?.satStart, "HH:mm:ss")
    : null;

  const satEndInit = testCenter?.satEnd
    ? moment(testCenter?.satEnd, "HH:mm:ss")
    : null;

  const sunStartInit = testCenter?.sunStart
    ? moment(testCenter?.sunStart, "HH:mm:ss")
    : null;

  const sunEndInit = testCenter?.sunEnd
    ? moment(testCenter?.sunEnd, "HH:mm:ss")
    : null;

  const [moStart, setMoStart]: any = useState(moStartInit);
  const [moEnd, setMoEnd]: any = useState(moEndInit);

  const [saStart, setSaStart]: any = useState(saStartInit);
  const [saEnd, setSaEnd]: any = useState(satEndInit);

  const [sunStart, setSunStart]: any = useState(sunStartInit);
  const [sunEnd, setSunEnd]: any = useState(sunEndInit);

  const [form] = Form.useForm();

  const [updateTestCenter, updateReq] = useUpdateTestCenterOpenTimesMutation();

  if (!testCenter?.id) {
    return <div>Bitte zuerst Testcenter erstellen</div>;
  }
  const onFinish = async (values: any) => {
    updateTestCenter({
      variables: {
        id: testCenter.id,
        input: {
          moStart: moStart.format("HH:mm"),
          moEnd: moEnd.format("HH:mm"),

          tueStart: moStart.format("HH:mm"),
          tueEnd: moEnd.format("HH:mm"),

          wedStart: moStart.format("HH:mm"),
          wedEnd: moEnd.format("HH:mm"),

          thuStart: moStart.format("HH:mm"),
          thuEnd: moEnd.format("HH:mm"),

          friStart: moStart.format("HH:mm"),
          friEnd: moEnd.format("HH:mm"),

          satStart: saStart.format("HH:mm"),
          satEnd: saEnd.format("HH:mm"),
          sunStart: sunStart.format("HH:mm"),
          sunEnd: sunEnd.format("HH:mm"),
        },
      },
    });

    success();
    onClose && onClose();
  };

  let initValues = {
    ...testCenter,
  };

  const enableSubmit =
    moStart && moEnd && saStart && saEnd && sunStart && sunEnd && true;

  const onChange = (time: any, timeString: string) => {
    setMoStart(moment(timeString, "HH:mm:ss"));
  };

  const onChangeEnd = (time: any, timeString: string) => {
    setMoEnd(moment(timeString, "HH:mm:ss"));
  };

  const onChangeSatStart = (time: any, timeString: string) => {
    setSaStart(moment(timeString, "HH:mm:ss"));
  };

  const onChangeSatEnd = (time: any, timeString: string) => {
    setSaEnd(moment(timeString, "HH:mm:ss"));
  };

  const onChangeSunStart = (time: any, timeString: string) => {
    setSunStart(moment(timeString, "HH:mm:ss"));
  };

  const onChangeSunEnd = (time: any, timeString: string) => {
    setSunEnd(moment(timeString, "HH:mm:ss"));
  };
  return (
    <div className="w-full">
      <Alert
        message={
          <div>
            Falls ein Tag geschlossen ist, bitte setzen Sie Start und Endzeit
            auf 00:00.
          </div>
        }
        type="info"
      />
      <div className="flex space-x-4 justify-center items-center mb-4 mt-4">
        <div className="flex-1"></div>
        <div className="flex-1">
          <div>Öffnet um</div>
        </div>
        <div className="flex-1">
          <div>Schließt um</div>
        </div>
      </div>

      <div className="flex space-x-4 justify-center items-center">
        <div className="flex-1">Mo-Fr</div>
        <div className="flex-1">
          <TimePicker
            format="HH:mm"
            minuteStep={15}
            onChange={onChange}
            defaultOpenValue={moment("07:00:00", "HH:mm:ss")}
            value={moStart}
            style={{ width: "100%" }}
            showNow={false}
          />
        </div>
        <div className="flex-1">
          <TimePicker
            format="HH:mm"
            minuteStep={15}
            onChange={onChangeEnd}
            defaultOpenValue={moment("18:00:00", "HH:mm:ss")}
            value={moEnd}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="flex space-x-4 justify-center items-center mt-4">
        <div className="flex-1">Samstag</div>
        <div className="flex-1">
          <TimePicker
            format="HH:mm"
            minuteStep={15}
            onChange={onChangeSatStart}
            defaultOpenValue={moment("07:00:00", "HH:mm:ss")}
            value={saStart}
            style={{ width: "100%" }}
          />
        </div>
        <div className="flex-1">
          <TimePicker
            format="HH:mm"
            minuteStep={15}
            onChange={onChangeSatEnd}
            defaultOpenValue={moment("18:00:00", "HH:mm:ss")}
            value={saEnd}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="flex space-x-4 justify-center items-center mt-4">
        <div className="flex-1">Sonntag</div>
        <div className="flex-1">
          <TimePicker
            format="HH:mm"
            minuteStep={15}
            onChange={onChangeSunStart}
            defaultOpenValue={moment("07:00:00", "HH:mm:ss")}
            value={sunStart}
            style={{ width: "100%" }}
          />
        </div>
        <div className="flex-1">
          <TimePicker
            format="HH:mm"
            minuteStep={15}
            onChange={onChangeSunEnd}
            defaultOpenValue={moment("18:00:00", "HH:mm:ss")}
            value={sunEnd}
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div className="mt-8">
        <Button
          onClick={onFinish}
          disabled={!enableSubmit}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </div>
    </div>
  );

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
      // labelCol={{ span: 10 }}
      // wrapperCol={{ span: 14  }}
    >
      <div className="flex">
        <div>Mo</div>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: errorMessage,
              whitespace: true,
            },
          ]}
        >
          <Input allowClear autoComplete="off" placeholder="Standort Name" />
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
