import React from "react";

import { useTestResultQuery } from "generated/schema";
import { Spin } from "antd";
import { TestResultView } from "components/TestResult";

interface Props {
  testResultId: number;
}
export const PcrTestResultWrapper = ({ testResultId }: Props) => {
  const { data, loading, error } = useTestResultQuery({
    variables: {
      id: testResultId,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Spin />;
  }
  const testResult = data?.testResult;

  return <div>{testResult && <TestResultView testResult={testResult} />}</div>;
};
