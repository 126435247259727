import React from "react";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  EmojiHappyIcon,
  ExclamationIcon,
  BeakerIcon,
  ClockIcon,
} from "@heroicons/react/solid";
import { Button } from "styled";

import { TestResult } from "generated/schema";
import { Tooltip } from "antd";
import formatDate from "utils/formatDate";

interface Props {
  testResult: TestResult;
}

export const EnterResultButton = (props: Props) => {

  const laborRequired = props?.testResult?.testType?.laborRequired

  const text = laborRequired ? "Probeentnahme Eintragen" : "Ergebnis Eintragen"
  return (
    <div>
      <div className="text-black shadow-md bg-yellow-200 hover:bg-yellow-100 py-1 px-2 border-b-4 border-black hover:border-gray-400 rounded inline-block w-48">
        <div className="relative">
          <div className="absolute -top-2 right-0">
            <div className="animate-bounce">
              <ExclamationCircleOutlined style={{ color: "black" }} />{" "}
            </div>
          </div>
        </div>
        <div className="flex-1">{text}</div>
        {/* <Tooltip title={formatDate(props?.testResult?.testFinishedAt)}>
          <div className="flex-1">{text}</div>
        </Tooltip> */}
      </div>
    </div>
  );
};
