import { Divider, DrawerProps, RadioChangeEvent } from "antd";
import { Button, Drawer, Radio, Space, Grid } from "antd";
import { TestResult } from "generated/schema";
import React, { useEffect, useRef, useState } from "react";

import { TestResultForm } from "components/TestResult/TestResultForm";

import { CustomerCard } from "components/Customer/CustomerCard";

import { TestResultCard } from "./TestResultCard/index";
import { TestFormMenu } from "./TestResultForm/TestResultFormMenu";
import { TestCenterDetails } from "./TestResultTestCenter";

import { TestCenterCustomerDetails } from "./CustomerDetails/index";
import { useTestResultQuery } from "generated/schema";
import { Collapse } from "antd";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { TestResultActivityLog } from "./CreateTestTimeline/index";
import { Documents } from "./Documents";
import { LogTableWrapper } from "./../LogTable/index";
import { DeleteTestResultButton } from "./DeleteTestResultButton";
interface Props {
  testResult: TestResult;
  onClose?: any;
}

export const TestResultWrapper = () => {
  const { id } = useParams();

  if (!id) return <div>no test id</div>;
  const testId: any = parseInt(id);

  return <TestResultQueryWrapper testId={testId} />;
};
export const TestResultQueryWrapper = ({ testId }: any) => {
  const { data, error, loading } = useTestResultQuery({
    variables: { id: testId },
  });
  const testResult = data?.testResult;

  if (loading) {
    return <Spin />;
  }
  if (!testResult || error) {
    return <div>Test nicht gefunden</div>;
  }

  return (
    <div className="max-w-4xl">
      <TestResultView testResult={testResult} />
    </div>
  );
};

export const TestResultView = ({ testResult, onClose }: Props) => {
  const [activeTab, setActiveTab] = useState("testResult");

  return (
    <div key={testResult?.id}>
      <div>
        <div className="mb-4">
          <TestFormMenu
            defaultValue={activeTab}
            onChange={(e: any) => {
              setActiveTab(e);
            }}
          />
        </div>

        {activeTab == "testResult" && (
          <>
            {/* <div className="grid grid-cols-2 gap-1">
              <TestCenterCustomerDetails testCustomer={testResult?.customer} />
              <TestCenterDetails />
            </div> */}

            <Divider plain>Testbogen</Divider>

            <TestResultForm onClose={onClose} testResult={testResult} />
          </>
        )}

        {activeTab == "customer" && testResult?.customer && (
          <TestCustomerForm onClose={onClose} customer={testResult?.customer} />
        )}

        {activeTab == "activityLog" && (
          <TestResultActivityLog testResult={testResult} />
        )}

        {activeTab == "logTable" && (
          <LogTableWrapper
            fixFilters={[
              {
                fieldName: "testResult.id",
                term: String(testResult?.id),
                exact: true,
              },
            ]}
          />
        )}

        {activeTab == "testCenter" && (
          <TestCenterDetails testResult={testResult} />
        )}

        {activeTab == "documents" && <Documents testResult={testResult} />}
        {activeTab == "deleteTest" && (
          <DeleteTestResultButton onClose={onClose} testResult={testResult} />
        )}
      </div>
      {/* <TestResultCard testResult={testResult} /> */}
    </div>
  );
};
