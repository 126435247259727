import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { CustomerTableWrapper } from "components/CustomerTable";
import { TestTableSearch } from "components/TestTable/Search";

import moment from "moment";
import { Breadcrumb, Button } from "antd";
import { CustomerTableSearch } from "components/CustomerTable/Search";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { AdminMenu } from "components/AdminPageMenu";
import { StaffTableWrapper } from "components/StaffTable";
import { RolesTableWrapper } from "components/RolesTable";
import { RolesForm } from "components/RolesTable/RolesForm";
export const RolesPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <AdminMenu defaultValue="roles" onChange={() => console.log("df")} />
          <PageBreadcrumb value={["Rollen"]} />

          <div className="mb-4 grid grid-cols-2">
            <div className="w-full md:w-1/2 ">
              {/* <CustomerTableSearch /> */}
            </div>

            <div className="flex justify-end">
              <MyDrawer
                title={"Rolle Anlegen"}
                triggerElement={
                  <Button type="primary">Rolle anlegen</Button>
                }
              >
                <RolesForm />
              </MyDrawer>
            </div>
          </div>

          <RolesTableWrapper />
        </div>
      </PageWrapper>
    </div>
  );
};
