import { Button, Checkbox, Form, Input } from "antd";
import React, { useEffect } from "react";
import { Alert } from "antd";
import { useLoginLazyQuery } from "generated/schema";
import useToken from "hooks/useJwtToken";
export const LoginForm = ({lat, lng} : any) => {
  const { setToken } = useToken();
  const [login, { data, loading, error }] = useLoginLazyQuery({});

  const onFinish = (values: any) => {
    console.log("Success:", values);

    login({
      variables: {
        input: { username: values?.username, password: values?.password, lat : String(lat), lng : String(lng) },
      },
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (data?.login?.jwtToken) {
      setToken(data?.login?.jwtToken);
      window.location.href = "/";
    }
  }, [data]);

  return (
    <div>
      <div className=" mb-4">
        {error && <Alert message="Falsche Logindaten" type="error" />}
        {!error && (
          <Alert
            message="Bitte melden Sie sich mit ihren Zugangsdaten an:"
            type="info"
          />
        )}
      </div>

      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Bitte Username eingeben!" }]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item
          label="Passwort"
          name="password"
          rules={[{ required: true, message: "Bitte Ihr Passwort eingeben!" }]}
        >
          <Input.Password allowClear />
        </Form.Item>

        {/* <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
