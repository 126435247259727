import React, { useState } from "react";
import {
  TestResult,
  useTestResultPdfLazyQuery,
  useCwaManuallyMutation,
} from "generated/schema";
import { Alert, Button, Dropdown, Menu, Popconfirm, Popover } from "antd";
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";

import { MoreOutlined } from "@ant-design/icons";
interface Props {
  testResult: TestResult;
}
export const CwaAgainButton = ({ testResult }: Props) => {
  //TODO
  const [isLoading, setIsLoading] = useState(false);
  const tokenString = localStorage.getItem("jwtToken");

  const [cwaManuallyMutation, { data, loading, error }] =
    useCwaManuallyMutation({});

  const confirm = async () => {
    setIsLoading(true);
    await cwaManuallyMutation({ variables: { id: testResult.id } });
    setIsLoading(false);
  };

  const cancel = () => {
    console.log("-");
  };

  return (
    <div className="flex">
      <Popconfirm
        title="Sind Sie sich sicher?"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Ja"
        cancelText="Nein"
      >
        <Button loading={isLoading} block type="primary">
          Neu melden
        </Button>
      </Popconfirm>
    </div>
  );
};
