import { Button, Radio, Space } from "antd";
import { CreateTestResult } from "components/TestResult/CreateTestResult";
import {
  Direction,
  Filter,
  useTestResultChangedSubscription,
  useTestResultCountLazyQuery,
  useTestResultsLazyQuery,
} from "generated/schema";
import { useEffect, useMemo, useState } from "react";
import { TestTableSearch } from "./Search";
import { TestTable } from "./Table";
import { TableFilters } from "./TableFilters";
import { FilterOutlined } from "@ant-design/icons";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  RefreshIcon,
} from "@heroicons/react/solid";
import { TestMeetingList } from "components/TestMeeting";
import { QrCodeScanner } from "components/MyQrCodeScanner";
import { useContext } from "react";
import { AppCtx } from "context/AppState";

const TABLE_PAGE_SIZE = 50;
export const TestTableWrapper = () => {
  const context = useContext(AppCtx);
  const [currentTablePage, setCurrentPageTable] = useState(1);
  const [showTableFilters, setShowTableFilters] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "testResult.id",
    direction: Direction.Desc,
  });

  const isUserAdmin =
    context?.user?.role?.id == 1 || context?.user?.role?.id == 2;
  let defaultFilters: any = [];

  if (!isUserAdmin) {
    const filter = {
      fieldName: "testResult.testCenter.id",
      term: String(context?.activeTestCenter?.id),
      exact: true,
    };

    defaultFilters = [filter];
  }

  const [filters, setFilters] = useState<any>(defaultFilters);
  const [active, setActive]: any = useState("all");

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const activeVariable = active === true || active === false ? { active } : {};
  const variables = { orderBy, take, skip, ...activeVariable, filters };

  const [getTests, { data, loading }] = useTestResultsLazyQuery({
    fetchPolicy: "network-only",
    variables,
  });
  const [getTestCount, { data: testCountData, loading: l }] =
    useTestResultCountLazyQuery({
      fetchPolicy: "network-only",
      variables: { ...activeVariable, filters },
    });
  const {
    data: testResultChanged,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useTestResultChangedSubscription({});

  useEffect(() => {
    const loadAsync = async () => {
      await getTests();
      await getTestCount();

      setDataLoading(false);
    };

    loadAsync();
  }, [testResultChanged]);

  useEffect(() => {
    if (!data) setDataLoading(true);
    else setDataLoading(false);
  }, [currentTablePage, filters, data]);

  const resultData = data?.testResults || [];
  const adminView =
    context?.user?.role?.id == 1 || context?.user?.role?.id == 2;

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 ">
        <div className="w-full flex space-x-2">
          <Radio.Group
            onChange={(e) => {
              const v = e?.target?.value;

              if (v == "demisError") {
                const filter = {
                  fieldName: "testResult.demisError",
                  term: "true",
                  exact: true,
                };

                const newFilters = [...filters];
                newFilters.push(filter);

                setFilters(newFilters);
              } else {
                const newFilters = filters.filter(
                  (x: any) => x?.fieldName != "testResult.demisError"
                );
                setFilters(newFilters);
              }
              setActive(e?.target?.value);
            }}
            value={active}
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button value="all">Alle</Radio.Button>
            <Radio.Button value={false}>Offen</Radio.Button>
            <Radio.Button value={true}>Abgeschlossen</Radio.Button>
            {adminView && (
              <Radio.Button value={"demisError"}>Demis Problem</Radio.Button>
            )}
          </Radio.Group>

          {/* <Button
            onClick={() => {
              if(showTableFilters) {
                setFilters([])
              }
              setShowTableFilters(!showTableFilters)
              
            }}
            type={showTableFilters ? "primary" : "default"}
            className="flex"
          >
            <div className="flex justify-center items-center">
              <div>Filter</div>
              {!showTableFilters && <ChevronDownIcon className="w-4 h-4" />}
              {showTableFilters && <ChevronUpIcon className="w-4 h-4" />}
            </div>
          </Button> */}
        </div>

        <div className="flex  md:justify-end space-x-1 mt-2 md:mt-0">
          <div>
            <Button
              onClick={() => {
                window.location.reload();
              }}
              type="primary"
            >
              <RefreshIcon className="w-4 h-4"></RefreshIcon>
            </Button>
          </div>

          <div className="">
            <TestMeetingList />
          </div>

          <div>
            <QrCodeScanner />
          </div>

          <div className="">
            <CreateTestResult />
          </div>

          {/* <div className="w-full md:w-1/2   ">
            <TestTableSearch filters={filters} setFilters={setFilters} />
          </div> */}
        </div>
      </div>

      <div className="mb-4 mt-2">
        {showTableFilters && (
          <TableFilters
            filters={filters}
            setFilters={(filtersArr: any) => {
              setCurrentPageTable(1);
              setFilters(filtersArr);
            }}
          />
        )}
      </div>
      <TestTable
        dataCount={testCountData?.testResultCount || 0}
        data={resultData}
        loading={dataLoading}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
        showTableFilters={showTableFilters}
      />
    </div>
  );
};
