import React, { useState } from "react";
import {
  TestResult,
  useTestResultPdfLazyQuery,
  useSendTestResultEmailAgainMutation,
  useCreateLogEventMutation,
} from "generated/schema";
import { Alert, Button, Dropdown, Menu, Popover } from "antd";
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";

import { MoreOutlined } from "@ant-design/icons";
import useToken from "hooks/useJwtToken";

interface Props {
  testResult: TestResult;
  text: string;
  fileName: string;
  endpoint: string;
}
export const DownloadDocumentButton = ({
  testResult,
  text,
  fileName,
  endpoint,
}: Props) => {
  const [token, setToken] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const tokenString = localStorage.getItem("jwtToken");

  const [sendTestResultEmailAgainMutation, { data, loading, error }] =
    useSendTestResultEmailAgainMutation({});

  const [createLogEventMutation] = useCreateLogEventMutation({
    fetchPolicy: "no-cache",
  });

  // return the headers to the context so httpLink can read them
  const authHeader = tokenString ? `Bearer ${tokenString}` : "";

  const makeLogEvent = async (redo: boolean) => {
    const message = redo
      ? `hat Attest: ${testResult?.id} neu generiert und heruntergeladen`
      : `hat Attest: ${testResult?.id} heruntergeladen`;

    createLogEventMutation({
      variables: {
        input: { testId: testResult?.id, description: message },
      },
    });
  };

  const getData = async (redo: boolean) => {
    let redoString = redo ? "/redo" : "";
    setIsLoading(true);

    const response = await fetch(
      "/api/v1/documents/" + testResult?.id + "/" + endpoint + redoString,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          authorization: authHeader,
        },
      }
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      setIsLoading(false);
      throw new Error(message);
    }

    const message = redo
      ? `hat Attest: ${testResult?.id} neu generiert und heruntergeladen`
      : `hat Attest: ${testResult?.id} heruntergeladen`;

    createLogEventMutation({
      variables: {
        input: { testId: testResult?.id, description: message },
      },
    });

    const pdfBlob = await response.blob();

    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([pdfBlob]));
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setIsLoading(false);
  };

  let show = !!testResult?.result?.id;

  let noTestResultText = !show ? " - Testergebnis liegt nicht vor" : "";

  if (
    endpoint == "slip" ||
    endpoint == "selbstauskunft" ||
    endpoint == "oegdSlip" ||
    endpoint == "bill"
  ) {
    noTestResultText = "";
    show = true;
  }

  const content = (
    <div className="space-y-2">
      {/* <Button
        block
        disabled={!show}
        loading={isLoading}
        onClick={() => getData(true)}
        type="primary"
        icon={<DownloadOutlined />}
      >
        Report neu generieren {noTestResultText}
      </Button> */}

      <a
        className="w-32"
        href={
          "/api/v1/documents/p/" +
          testResult?.id +
          "/" +
          endpoint +
          "/" +
          fileName +
          ".html?accessToken=" +
          tokenString
        }
        target="_blank"
      >
        {/* currently this has no function */}
        <Button
          block
          disabled={!show}
          loading={isLoading}
          onClick={() => makeLogEvent(true)}
          type="primary"
          icon={<DownloadOutlined />}
        >
          Report neu generieren {noTestResultText}
        </Button>
      </a>

      {endpoint == "result" && (
        <Button
          block
          disabled={!show}
          loading={loading}
          onClick={() => {
            const testResultId = testResult?.id;

            sendTestResultEmailAgainMutation({
              variables: { id: testResultId },
            });
          }}
          type="primary"
          icon={<MailOutlined />}
        >
          Email nochmal senden
        </Button>
      )}
    </div>
  );

  return (
    <div className="flex">
      <a
        className="w-64"
        href={
          "/api/v1/documents/p/" +
          testResult?.id +
          "/" +
          endpoint +
          "/" +
          fileName +
          ".html?accessToken=" +
          tokenString
        }
        target="_blank"
      >
        <Button
          block
          disabled={!show}
          loading={isLoading}
          onClick={() => makeLogEvent(false)}
          type="primary"
          icon={<DownloadOutlined />}
        >
          {text} {noTestResultText}
        </Button>
      </a>

      <Popover content={content} title="Dokument neu erstellen">
        <Button icon={<MoreOutlined />}></Button>
      </Popover>
    </div>
  );

  return (
    <div className="flex">
      <Button
        block
        disabled={!show}
        loading={isLoading}
        onClick={() => getData(false)}
        type="primary"
        icon={<DownloadOutlined />}
      >
        {text} {noTestResultText}
      </Button>

      <Popover content={content} title="Dokument neu erstellen">
        <Button icon={<MoreOutlined />}></Button>
      </Popover>
    </div>
  );
};
