import { Button } from "antd";
import { MyDrawer } from "components/MyDrawer";
import { TestResultView } from "components/TestResult";
import { getTestResultTitle } from "components/TestTableResultButton";
import { RightsEnumType, TestResult, TestTransportJob } from "generated/schema";
import React, { useContext } from "react";
import moment from "moment";
import _get from "lodash/get";
import formatDate from "utils/formatDate";
import { AppCtx } from "context/AppState";
interface Props {
  testResults?: TestResult[];
  transportJob?: TestTransportJob;
}
const LABORATORY_BUFFER_HOURS = 3;

export const CourierTestDetailsButton = ({ testResults }: Props) => {
  const appContext = useContext(AppCtx);

  const hasUserRight = appContext?.hasUserRight
    ? appContext?.hasUserRight
    : () => {};

  const testGroupedByType: any = {};

  testResults?.forEach((x) => {
    const testTypeName = x?.testType?.id;

    if (testTypeName && !testGroupedByType[testTypeName]) {
      testGroupedByType[testTypeName] = {
        testType: x?.testType,
        results: [],
      };
    }

    if (testTypeName) {
      testGroupedByType[testTypeName]["results"].push(x);
    }
  });

  const testsGroupedByTypeArr = Object.values(testGroupedByType);

  const oegdTests = testResults?.filter((x) => {
    return x?.testType?.id == 2;
  });

  const pcrTests = testResults?.filter((x) => {
    return x?.testType?.id == 3;
  });

  const testTimesOegd = oegdTests?.map((x: TestResult) => {
    const specimenAt = x?.specimenAt;

    const testTypeEvaluationTime = x?.testTypeOption?.evaluationHours;

    const maxValidTime = moment
      .utc(specimenAt)
      .add(testTypeEvaluationTime, "hours")
      .format();

    const latestInLaboratoryAt = moment
      .utc(maxValidTime)
      .subtract(LABORATORY_BUFFER_HOURS, "hours")
      .format();

    return {
      maxValidTime,
      specimenAt,
      latestInLaboratoryAt,
      testTypeEvaluationTime,
    };
  });

  const testTimesPcr = pcrTests?.map((x: TestResult) => {
    const specimenAt = x?.specimenAt;

    const testTypeEvaluationTime = x?.testTypeOption?.evaluationHours;

    const maxValidTime = moment
      .utc(specimenAt)
      .add(testTypeEvaluationTime, "hours")
      .format();

    const latestInLaboratoryAt = moment
      .utc(maxValidTime)
      .subtract(LABORATORY_BUFFER_HOURS, "hours")
      .format();

    return {
      maxValidTime,
      specimenAt,
      latestInLaboratoryAt,
      testTypeEvaluationTime,
    };
  });

  const latestDeliveryAtArrPcr = testTimesPcr
    ?.map((x) => x?.latestInLaboratoryAt)
    .sort((a: string, b: string) => {
      const aa = moment.utc(a).unix();
      const bb = moment.utc(b).unix();

      return aa - bb;
    });

  const latestDeliveryAtArrOegd = testTimesOegd
    ?.map((x) => x?.latestInLaboratoryAt)
    .sort((a: string, b: string) => {
      const aa = moment.utc(a).unix();
      const bb = moment.utc(b).unix();

      return aa - bb;
    });

  const latestDeliveryAtPcr = _get(latestDeliveryAtArrPcr, 0);
  const latestDeliveryAtPcrFormatted =
    moment(latestDeliveryAtPcr).format("DD.MM.YYYY HH:mm");

  const latestDeliveryAtOegd = _get(latestDeliveryAtArrOegd, 0);
  const latestDeliveryAtOegdFormatted =
    moment(latestDeliveryAtOegd).format("DD.MM.YYYY HH:mm");

  return (
    <div>
      <div className="text-base  space-y-2 ">
        {testsGroupedByTypeArr.map((x: any) => {
          const countOfTests = x?.results?.length || 0;
          const testTypeName = x?.testType?.name || "";

          const color = x?.testType?.color || "gray";
          const testTypeId = x?.testType?.id;

          const testResultsArr: TestResult[] = x?.results;

          return (
            <div className=" bg-gray-50">
              <div className="   mr-2 p-0.5">
                <div className="font-bold">
                  {countOfTests} x {testTypeName}
                </div>
                <div className="  ">
                  <div>Tests:</div>
                  <div className="flex flex-wrap  ">
                    {testResultsArr.map((testResult, i) => {
                      const testR: TestResult = testResult;
                      const title =
                        "Probeentnahme: " +
                        moment(testResult?.specimenAt).format(
                          "DD.MM.YYYY HH:mm"
                        );

                      let testTypeOptionName = "";
                      if (testTypeId == 3) {
                        testTypeOptionName = testR?.testTypeOption?.name || "";
                      }

                      return (
                        <div className="mr-2 mt-1">
                          {hasUserRight(RightsEnumType.RightTestResultView) && (
                            <MyDrawer
                              title={getTestResultTitle(testResult)}
                              triggerElement={
                                <Button
                                  type="primary"
                                  title={title}
                                  size="small"
                                  style={{
                                    background:
                                      testR?.testType?.color || "blue",
                                    borderColor:
                                      testR?.testType?.color || "blue",
                                  }}
                                >
                                  <div>
                                    {testTypeOptionName && (
                                      <div className="">
                                        {testResult?.id}{" "}
                                        <span className="text-xs">
                                          ({testTypeOptionName})
                                        </span>
                                      </div>
                                    )}
                                    {!testTypeOptionName && (
                                      <div> {testResult?.id}</div>
                                    )}
                                  </div>
                                </Button>
                              }
                            >
                              <TestResultView testResult={testResult} />
                            </MyDrawer>
                          )}

                          {!hasUserRight(
                            RightsEnumType.RightTestResultView
                          ) && (
                            <Button
                              style={{
                                background: testR?.testType?.color || "blue",
                                borderColor: testR?.testType?.color || "blue",
                              }}
                              type="primary"
                              title={title}
                              size="small"
                            >
                              <div>
                                {testTypeOptionName && (
                                  <div className="">
                                    {testResult?.id}{" "}
                                    <span className="text-xs">
                                      ({testTypeOptionName})
                                    </span>
                                  </div>
                                )}
                                {!testTypeOptionName && (
                                  <div> {testResult?.id}</div>
                                )}
                              </div>
                            </Button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-2">Auslieferung spätestens: </div>
                <div className="text-2xl text-black font-bold  p-1 ">
                  {testTypeId == 3 && latestDeliveryAtPcrFormatted}
                  {testTypeId == 2 && latestDeliveryAtOegdFormatted}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
