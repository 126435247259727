import { Select } from "antd";
import React from "react";
import { useUserGroupsQuery } from "generated/schema";
const { Option } = Select;

interface Props {
  onChange: any;
}
export const UserGroupsSelect = ({ onChange }: Props) => {
  const { data, loading, error } = useUserGroupsQuery({
    fetchPolicy: "network-only",
  });

  const handleChange = (value: string) => {

    if (!value) {
      onChange({
        fieldName: "userGroup.id",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "userGroup.id",
      term: String(value),
      exact: true,
    };

    onChange(filter);
  };

  return (
    <>
      <Select
        allowClear
        loading={loading}
        placeholder="Benutzergruppen"
        style={{ width: 200 }}
        onChange={handleChange}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => {
          return ((option?.children as unknown as string) || "")
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      >
        {data?.userGroups?.map((x) => {
          return <Option value={x?.id}>{x?.name}</Option>;
        })}
      </Select>
    </>
  );
};
