import { useEffect, useState } from "react";
import AnalyticsTable from "./AnalyticsTable";
import axios from "axios";
import AnalyticsSearch from "./AnalyticsSearch";
import moment from "moment";
import { LikeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Row, Statistic } from "antd";
import formatCurrency from "utils/formatCurrency";
import StatisticBox from "./StatisticBox";
import AnalyticsSearchWrapper from "./AnalyticsSearch/Wrapper";
import { Collapse } from "antd";
import _get from "lodash/get";
import { AnalyticsTableSearch } from "./AnalyticsTable/Search";
import GroupFilter from "./GroupFilter";
import { useTestResultsGroupedLazyQuery } from "generated/schema";
import { TableFilters } from "./TableFilters";
import { ExportExcelAnalytics } from "./ExportAnalyticsExcelButton";

const { Panel } = Collapse;

function Analytics() {
  const [filters, setFilters] = useState<any>([]);

  const now = moment().format("MM-YYYY");
  const [apiResponse, setApiResponse]: any = useState();

  const [filteredData, setFilteredData] = useState();

  const [searchTerm, setSearchTerm]: any = useState(false);
  const [filterDate, setFilterDate] = useState(now);
  const [filterGroup, setFilterGroup]: any = useState();
  const [filterDateString, setFilterDateString] = useState(
    moment().format("MMMM YYYY ")
  );
  const [activeFilterType, setActiveFilterType]: any = useState("month");
  const [onlyQuickTest, setOnlyQuickTest]: any = useState(false);

  const [getData, { data, loading, error }] = useTestResultsGroupedLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getData({ variables: { filters: filters, onlyQuickTest} });
  }, [filters, filterDate, filterGroup, onlyQuickTest]);

  return (
    <div>
      <div className="my-2">
        <Breadcrumb>
          <Breadcrumb.Item>Auswertungen</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="">Tabellenübersicht</a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="text-right">
        <ExportExcelAnalytics
          filters={filters}
          tableData={data?.testResultsGrouped || []}
          onOnlyQuickTestChange={setOnlyQuickTest}
          loading={loading}
        />
      </div>
      <div className="mb-4 mt-2">
        <TableFilters
          filters={filters}
          setFilters={(filtersArr: any) => {
            setFilters(filtersArr);
          }}
        />
      </div>

      <div key={filterDate + searchTerm}>
        <AnalyticsTable
          searchActive={searchTerm}
          dataFromApi={data?.testResultsGrouped?.result || []}
          loading={loading}
          testTypes={data?.testResultsGrouped?.testTypes || []}
          testTypeOptions={data?.testResultsGrouped?.testTypeOptions || []}
        />
      </div>
    </div>
  );
}

export default Analytics;
