import React from "react";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  EmojiHappyIcon,
  ExclamationIcon,
  BeakerIcon,
  ClockIcon,
} from "@heroicons/react/solid";
import { Button } from "styled";
import { Tooltip } from "antd";

import { useCountdown } from "hooks/useCountdown";
import { TestResult } from "generated/schema";
import formatDate from "utils/formatDate";
import { LaborRequiredTest } from "./LaborRequiredTest";

interface StartButtonProps {
  testResult: TestResult;
}

export const TestTableResultNegativeBtn = (props: StartButtonProps) => {

  const testResult = props?.testResult;

  const laborRequired = testResult?.testType?.laborRequired;

  if (laborRequired) {
    return <LaborRequiredTest testResult={testResult} />;
  }
  
  return (
    <div>
      <strong className="inline-flex items-center h-8 overflow-hidden text-white bg-green-500 rounded">
        <span className="px-2  font-normal">
          <div className="flex w-36">
            <div className="flex-1">Negativ</div>
            <div className="flex-1">
                {formatDate(props?.testResult?.testResultEnteredAt, true)}
              </div>
            {/* <Tooltip title= {formatDate(props?.testResult?.testFinishedAt)}>
              <div className="flex-1">
                {formatDate(props?.testResult?.testFinishedAt, true)}
              </div>
            </Tooltip> */}
          </div>
        </span>

        <button
          className="inline-flex items-center justify-center w-8 h-8 bg-green-600 transition-color hover:bg-green-700 focus:outline-none focus:ring"
          type="button"
        >
          <EmojiHappyIcon className="h-5 w-5 text-white" />
        </button>
      </strong>
    </div>
  );
};
