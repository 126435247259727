import React from "react";
import { Welcome } from "components/Welcome";
import { PageWrapper } from "layout/Page";
import { AdminMenu } from "components/AdminPageMenu";
import { PageBreadcrumb } from "components/PageBreadcrumb";

export default function TestCenterSelectPage() {
  return (
    <PageWrapper>
      <div className="p-4  h-full">
        <PageBreadcrumb value={["Start"]} />

        <Welcome />
      </div>
    </PageWrapper>
  );
}
