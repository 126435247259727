import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { TestCustomer, WorkTime } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { TableTestStateButton } from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import formatDate from "utils/formatDate";
import moment from "moment";
import "moment-duration-format";
import { WorkTimeFormWrapper } from "./Form/index";

export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "MA-ID",
    dataIndex: ["user"],
    key: "userId",
    width: 150,
    sorter: (a: any, b: any) => {
      return a.userId - b.userId;
    },

    render: (text: any, row: any) => {
      const { userId } = row;
      return <div>{userId}</div>;
    },
  },

  {
    title: "Personal-Nr.",
    dataIndex: ["user"],
    key: "userId",
    width: 150,
    sorter: (a: any, b: any) => {
      return a.workerId - b.workerId;
    },

    render: (text: any, row: any) => {
      const { workerId } = row;
      return <div>{workerId || "-"}</div>;
    },
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "testCenter",
    sorter: (a: any, b: any) => {
      return ("" + a.user).localeCompare(b.user);
    },
    render: (text: any, row: any) => {
      const { user, userName, ...hours } = row;

      return <div>{user}</div>;
    },
  },

  {
    title: "Arbeitsstunden",
    dataIndex: ["user"],
    key: "userId",
    width: 150,
    sorter: (a: any, b: any) => {
      const hoursA = parseInt(a.hours) || 0;
      const hoursB = parseInt(b.hours) || 0;
      return hoursA- hoursB
    },
    render: (text: any, row: any) => {
      const { user, userName, hours } = row;

      return <div>{hours.toFixed(2)}</div>;
    },
  },
];
