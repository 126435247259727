import { RightsEnumType } from "generated/schema";

export const RightsMap = {
  [RightsEnumType.RightTestResultView]: 1,
  [RightsEnumType.RightTestOptionsEmail]: 2,
  [RightsEnumType.RightTestsPageView]: 3,
  [RightsEnumType.CourierPageView]: 4,
  [RightsEnumType.PcrLaborViewRight]: 5,
  [RightsEnumType.MasterDataView]: 6,
  [RightsEnumType.CustomerView]: 7,
  [RightsEnumType.DemisView]: 8,
  [RightsEnumType.AnalyticsRight]: 9,
  [RightsEnumType.LogEventsRight]: 10,
  [RightsEnumType.TimeTrackingViewRight]: 11,
  [RightsEnumType.LaborRequiredTestEnterResultRight]: 12,
  [RightsEnumType.DeleteTestRight]: 13,
  [RightsEnumType.TestResultAllOptionRight]: 14,
  [RightsEnumType.TestResultTableCountRight]: 15,
};
