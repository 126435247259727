import { Select } from "antd";
import { useTestTypesQuery, useTestTypeOptionsQuery } from "generated/schema";
import formatCurrency from './../../../../utils/formatCurrency';

const { Option } = Select;

interface Props {
  onChange: any;
}
export const TestOptionTypeSelect = ({ onChange }: Props) => {
  const { data, loading, error } = useTestTypeOptionsQuery({
    fetchPolicy: "network-only",
  });

  const handleChange = (value: string) => {

    if (!value) {
      onChange({
        fieldName: "testTypeOption.id",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "testTypeOption.id",
      term: String(value),
      exact: true,
    };

    onChange(filter);
  };

  return (
    <>
      <Select
        allowClear
        placeholder="Test-Type-Option"
        style={{ minWidth: 200 }}
        loading={loading}
        onChange={handleChange}
      >
        {data?.testTypeOptions.map((x) => {
          return <Option value={x?.id}>{formatCurrency(x?.price)}{" "}{x?.name}</Option>;
        })}
      </Select>
    </>
  );
};
