import { NavTabs } from "components/SubMenu";
import {
  UsersIcon,
  PlusIcon,
  ClockIcon,
  HomeIcon,
  FolderRemoveIcon,
} from "@heroicons/react/solid";
import { useContext } from "react";
import { AppCtx } from "context/AppState";
import { RightsEnumType } from "generated/schema";

interface Props {
  defaultValue: string;
  onChange: any;
}
export const TestCenterMenu = ({ defaultValue, onChange }: Props) => {
  const appContext = useContext(AppCtx);

  const hasUserRight = appContext?.hasUserRight
    ? appContext?.hasUserRight
    : () => {};

  return (
    <div>
      <div className="mb-4">
        <NavTabs
          defaultValue={defaultValue}
          items={[
            {
              value: "testCenter",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <UsersIcon className="h-4 w-4 " />
                  </div>
                  <div>Standort </div>
                </div>
              ),
            },
            {
              value: "openingTimes",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <ClockIcon className="h-4 w-4 " />
                  </div>
                  <div>Öffnungszeiten</div>
                </div>
              ),
            },
          ]}
          onChange={(activeTab: any) => {
            onChange(activeTab);
          }}
        />
      </div>
    </div>
  );
};
