import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  concat,
  split,
  HttpLink,
  from,
} from "@apollo/client";

import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

//  import useToken from "hooks/useJwtToken";
const SERVER = "localhost:5009";
//const SERVER = "49.12.214.104:8068";
//const SERVER = "176.9.24.156:8068";
let graphQlEndpoint = "http://" + SERVER + "/graphql"; //process.env.REACT_APP_GRAPHQL_URI;
let graphQlEndpointWs = SERVER + "/graphql"; // process.env.REACT_APP_GRAPHQL_WS_URI;
let tenantId = process.env.REACT_APP_APP_TENANT_ID;

if (process.env.NODE_ENV === "production") {
  const host = window.location.host;
  const protocol = window.location.protocol;

  graphQlEndpoint = `${protocol}//${host}/graphql`;
  // needs to changed when tenant id prod changes
  // tenantId = "a1c93ec1-a275-4b65-bacb-6d8ca7e7dfcc";

  if (protocol == "https:") {
    graphQlEndpointWs = "wss://" + `${host}/graphql`;
  } else {
    graphQlEndpointWs = "ws://" + `${host}/graphql`;
  }
} else {
  graphQlEndpointWs = "ws://" + `${graphQlEndpointWs}`;
}

// console.log("graphQlEndpoint", graphQlEndpoint);
// console.log("graphQlEndpointWs", graphQlEndpointWs);
// console.log("tenantId", tenantId);

const httpLink = new HttpLink({
  uri: graphQlEndpoint,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");

  const tokenString = localStorage.getItem("jwtToken");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: tokenString ? `Bearer ${tokenString}` : "",
      "x-tenant-id": tenantId,
    },
  };
});

let activeSocket: any, timedOut: any;
const tokenString = localStorage.getItem("jwtToken");

const wsLink = new GraphQLWsLink(
  createClient({
    connectionParams: {
      authorization: tokenString ? `Bearer ${tokenString}` : "",
    },

    url: graphQlEndpointWs,
    keepAlive: 10_000, // ping server every 10 seconds
    on: {
      connected: (socket) => {
        activeSocket = socket;
        console.log("connected");
      },
      ping: (received) => {
        console.log("ping", received);
        if (!received)
          // sent
          timedOut = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN)
              activeSocket.close(4408, "Request Timeout");
          }, 5_000); // wait 5 seconds for the pong and then close the connection
      },
      pong: (received) => {
        if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
      },
    },
  })
);

const uploadLink = createUploadLink({
  uri: graphQlEndpoint,
  // credentials: RequestCredentials,
}) as unknown as ApolloLink;
const httpAndUploadLink = concat(uploadLink, httpLink);
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpAndUploadLink
);

const link = from([authLink, splitLink]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});
export default client;
