import React from "react";
import { Button, Spin } from "antd";
import { Radio } from "antd";
import { Select } from "antd";
import { RefreshIcon } from "@heroicons/react/solid";

enum TransportJobState {
  ReadyToPickUp,
  InTransport,
  Delivered,
}

export const TransportJobStatesFilter = ({ onChange, defaultValue }: any) => {
  return (
    <div className="">
      <div className="w-full mt-2">
        <Radio.Group
          onChange={(e) => {
            onChange && onChange(e?.target?.value);
          }}
          defaultValue={defaultValue}
          optionType="button"
          buttonStyle="solid"
        >
          <Radio.Button value={TransportJobState.ReadyToPickUp}>
            {"Bereit zur Abholung"}
          </Radio.Button>
          <Radio.Button value={TransportJobState.InTransport}>
            {"Unterwegs"}
          </Radio.Button>
          {/* <Radio.Button value={TransportJobState.Delivered}>
            {"Im Labor"}
          </Radio.Button> */}
        </Radio.Group>

        <div className="mt-2 flex justify-end">
          <Button
            onClick={() => {
              window.location.reload();
            }}
            size="small"
            type="primary"
          >
            <div className="flex justify-center items-center">
              Seite neu laden
              <RefreshIcon className="w-4 h-4 ml-4"></RefreshIcon>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
