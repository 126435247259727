// @ts-nocheck

// Html5QrcodePlugin.jsx

import { Html5QrcodeScanner } from "html5-qrcode";
import React from "react";
import * as ZXing from "html5-qrcode/third_party/zxing-js.umd";
import { Modal } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  && {
    #html5qr-code-full-region {
      border:none !important;
    }
    #html5-qrcode-anchor-scan-type-change {
      display: none !important;
    }

    #html5-qrcode-select-camera {
      border: 1px solid gray;
      padding: 2px;
    }

    #html5-qrcode-button-camera-stop {
      display: none;
    }

    #html5-qrcode-button-camera-start {
      width: 100%;
      margin-top:24px;
      line-height: 1.5715;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      background-image: none;
      border: 1px solid transparent;
      box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      touch-action: manipulation;
      height: 32px;
      padding: 4px 15px;
      font-size: 14px;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.85);
      border-color: #d9d9d9;
      background: #fff;

      color: #fff;
      border-color: #1890ff;
      background: #1890ff;
      text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
      box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    }
    #html5-qrcode-button-camera-permission {
      line-height: 1.5715;
      position: relative;
      display: inline-block;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      background-image: none;
      border: 1px solid transparent;
      box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      touch-action: manipulation;
      height: 32px;
      padding: 4px 15px;
      font-size: 14px;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.85);
      border-color: #d9d9d9;
      background: #fff;

      color: #fff;
      border-color: #1890ff;
      background: #1890ff;
      text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
      box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    }
  }
`;
export function performMonkeyPatches() {
  console.log("html5qr-code - Monkey patching");

  // Monkey-patch based on https://github.com/zxing-js/library/pull/420/commits/7644e279df9fd2e754e044c25f450576d2878e45
  const oldFunc = ZXing.HTMLCanvasElementLuminanceSource.toGrayscaleBuffer;
  let inverterToggle = true;
  ZXing.HTMLCanvasElementLuminanceSource.toGrayscaleBuffer = function (
    imagebuffer,
    width,
    height
  ) {
    const grayscaleBuffer = oldFunc(imagebuffer, width, height);
    const len = grayscaleBuffer.length;
    inverterToggle = !inverterToggle;
    if (inverterToggle) {
      for (let i = 0; i < len; i++) {
        grayscaleBuffer[i] = 0xff - grayscaleBuffer[i];
      }
    }
    return grayscaleBuffer;
  };
}

performMonkeyPatches();
const qrcodeRegionId = "html5qr-code-full-region";

class Html5QrcodePlugin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
    };

    this.lastScannedDataRef = React.createRef(null);
  }

  render() {
    return (
      <Modal
        footer={null}
        onCancel={() => {
          this.stopCamera();
          this.props.onHide();
        }}
        destroyOnClose
        title="Termin QR-Code Scanner"
        open={true}
      >
        <>
          <Wrapper>
            <div id={qrcodeRegionId} />
          </Wrapper>
        </>
      </Modal>
    );
  }

  componentWillUnmount() {
    this.stopCamera();
  }

  stopCamera = () => {
    this.html5QrcodeScanner.clear().catch((error) => {
      console.error("Failed to clear html5QrcodeScanner. ", error);
    });
  };

  handleOnScan = (text, scanResult) => {
    if (this.lastScannedDataRef?.current == text) {
      console.log("bereits gescannt");
      return;
    }

    this.lastScannedDataRef.current = text;

    // console.log(this.lastScannedDataRef.current)

    this.props.qrCodeSuccessCallback(text, scanResult);
    // this.stopCamera();
    // this.props.onHide();
  };

  componentDidMount() {
    // Creates the configuration object for Html5QrcodeScanner.
    function createConfig(props) {
      var config = { fps: 10, qrbox: 250, disableFlip: false };

      if (props.fps) {
        config.fps = props.fps;
      }
      if (props.qrbox) {
        config.qrbox = props.qrbox;
      }
      if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
      }
      if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
      }

      return { ...config, rememberLastUsedCamera: true };
    }

    var config = createConfig(this.props);
    var verbose = this.props.verbose === true;

    // Suceess callback is required.
    if (!this.props.qrCodeSuccessCallback) {
      throw "qrCodeSuccessCallback is required callback.";
    }

    this.html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );
    this.html5QrcodeScanner.render(
      this.handleOnScan,
      this.props.qrCodeErrorCallback,
      { facingMode: "environment" }
    );
  }
}

const MyQrCodeScanner = ({ onHide, onDataScan }) => {
  return (
    <div>
      <Html5QrcodePlugin
        fps={10}
        qrbox={250}
        disableFlip={false}
        onHide={onHide}
        qrCodeSuccessCallback={(e) => {
          onDataScan(e);
        }}
      />
    </div>
  );
};

export default MyQrCodeScanner;
