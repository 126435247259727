import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
  Radio,
} from "antd";
// import { AppCtx } from "context/AppState";
// import {
//   useCreateCustomerMutation,
//   namedOperations,
//   useUpdateCustomerMutation,
// } from "generated/schema";
import styled from "styled-components";
import {
  IdentificationIcon,
  CalendarIcon,
  AtSymbolIcon,
  PhoneIcon,
  ClipboardListIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import {
  TestCustomer,
  TestCenter,
  useCreateTestCustomerMutation,
  useUpdateTestCustomerMutation,
  useTestCenterTypeOptionsQuery,
  useUpdateTestCenterMutation,
  useCreateTestCenterMutation,
  useTestCourierGroupsQuery,
  FederalStateType,
  useFilesUploadsQuery,
  useUserGroupsQuery,
  useTestTypesQuery,
  useLaboratoriesLazyQuery,
  useLaboratoriesQuery,
} from "generated/schema";

import { feralStateTypeMapping } from "./index";
const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  testCenter?: TestCenter;
  onClose?: any;
}

export const TestCenterForm = ({ testCenter, onClose }: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [form] = Form.useForm();

  const req = useTestCenterTypeOptionsQuery({ fetchPolicy: "network-only" });
  const reqLabor = useLaboratoriesQuery({ fetchPolicy: "network-only" });
  const reqCouriers = useTestCourierGroupsQuery({
    fetchPolicy: "network-only",
  });
  const reqFileUploads = useFilesUploadsQuery({ fetchPolicy: "network-only" });
  const reqUserGroups = useUserGroupsQuery({ fetchPolicy: "network-only" });
  const reqTestTypesQuery = useTestTypesQuery({
    fetchPolicy: "network-only",
  });

  const testCenterTypeOptions = req?.data?.testCenterTypeOptions || [];
  const testCenterTypeOptionLoading = req?.loading;

  const courierGroupData = reqCouriers?.data?.testCourierGroups || [];
  const courierGroupDataLoading = reqCouriers?.loading;

  const [createTestCenter, { data, loading }] = useCreateTestCenterMutation();
  const [updateTestCenter, updateReq] = useUpdateTestCenterMutation();

  const uploadedFilesData = reqFileUploads?.data?.filesUploads || [];
  const laborData = reqLabor?.data?.laboratories || [];

  const onFinish = async (values: any) => {
    if (values.pricePcr6) {
      values.pricePcr6 = parseFloat(values.pricePcr6);
    }

    if (values.pricePcr12) {
      values.pricePcr12 = parseFloat(values.pricePcr12);
    }

    if (values.pricePcr24) {
      values.pricePcr24 = parseFloat(values.pricePcr24);
    }

    if (values.priceSchnelltest) {
      values.priceSchnelltest = parseFloat(values.priceSchnelltest);
    }

    if (testCenter?.id) {
      updateTestCenter({ variables: { id: testCenter.id, input: values } });
    } else {
      createTestCenter({ variables: { input: values } });
    }

    success();
    onClose && onClose();
  };

  let initValues = {
    ...testCenter,
    testCenterTypeId: testCenter?.testCenterType?.id,
    testCourierGroupId: testCenter?.courierGroup?.id,
    logoId: testCenter?.logo?.id,
    userGroupId: testCenter?.userGroup?.id,
    servicesIds: testCenter?.services?.map((x) => x?.id),
  };

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
      // labelCol={{ span: 10 }}
      // wrapperCol={{ span: 14  }}
    >
      <div className="sm:grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <div>
          <Form.Item
            name="testCenterTypeId"
            label="Standort-Type"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Select loading={testCenterTypeOptionLoading} allowClear>
              {testCenterTypeOptions.map((x) => {
                return <Option value={x?.id}>{x?.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: errorMessage,
                whitespace: true,
              },
            ]}
          >
            <Input allowClear autoComplete="off" placeholder="Standort Name" />
          </Form.Item>

          <Form.Item
            name="operator"
            label="Betreiber"
            rules={[
              {
                required: false,
                message: errorMessage,
                whitespace: true,
              },
            ]}
          >
            <Input allowClear autoComplete="off" placeholder="Betreiber" />
          </Form.Item>

          <Form.Item
            name="street"
            label="Strasse und Hausnummer"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              autoComplete="off"
              allowClear
              placeholder="Strasse und Hausnummer"
            />
          </Form.Item>

          <Form.Item
            name="zipCode"
            label="PLZ"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="PLZ" />
          </Form.Item>

          <Form.Item
            name="city"
            label="Stadt"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="Stadt" />
          </Form.Item>

          <Form.Item
            name="federalState"
            label="Bundesland"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Select
              showSearch
              placeholder="Bundesland auswählen"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Option value={FederalStateType.BadenWuerttemberg}>
                {feralStateTypeMapping[FederalStateType.BadenWuerttemberg]}
              </Option>
              <Option value={FederalStateType.Bayern}>
                {feralStateTypeMapping[FederalStateType.Bayern]}
              </Option>
              <Option value={FederalStateType.Berlin}>
                {feralStateTypeMapping[FederalStateType.Berlin]}
              </Option>
              <Option value={FederalStateType.Brandenburg}>
                {feralStateTypeMapping[FederalStateType.Brandenburg]}
              </Option>
              <Option value={FederalStateType.Bremen}>
                {feralStateTypeMapping[FederalStateType.Bremen]}
              </Option>
              <Option value={FederalStateType.Hamburg}>
                {feralStateTypeMapping[FederalStateType.Hamburg]}
              </Option>
              <Option value={FederalStateType.Hessen}>
                {feralStateTypeMapping[FederalStateType.Hessen]}
              </Option>
              <Option value={FederalStateType.MecklenburgVorpommern}>
                {feralStateTypeMapping[FederalStateType.MecklenburgVorpommern]}
              </Option>
              <Option value={FederalStateType.Niedersachsen}>
                {feralStateTypeMapping[FederalStateType.Niedersachsen]}
              </Option>
              <Option value={FederalStateType.NordrheinWestfalen}>
                {feralStateTypeMapping[FederalStateType.NordrheinWestfalen]}
              </Option>
              <Option value={FederalStateType.RheinlandPfalz}>
                {feralStateTypeMapping[FederalStateType.RheinlandPfalz]}
              </Option>
              <Option value={FederalStateType.Saarland}>
                {feralStateTypeMapping[FederalStateType.Saarland]}
              </Option>
              <Option value={FederalStateType.Sachsen}>
                {feralStateTypeMapping[FederalStateType.Sachsen]}
              </Option>
              <Option value={FederalStateType.SachsenAnhalt}>
                {feralStateTypeMapping[FederalStateType.SachsenAnhalt]}
              </Option>
              <Option value={FederalStateType.SchleswigHolstein}>
                {feralStateTypeMapping[FederalStateType.SchleswigHolstein]}
              </Option>
              <Option value={FederalStateType.Thueringen}>
                {feralStateTypeMapping[FederalStateType.Thueringen]}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="landkreis"
            label="Landkreis"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="Landkreis" />
          </Form.Item>

          <Form.Item
            name="testCenterId"
            label="Teststellen Id"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="Teststellen ID" />
          </Form.Item>

          <Form.Item
            name="tel"
            label="Telefon Station"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              autoComplete="off"
              allowClear
              placeholder="Telefon Station"
              addonBefore={<PhoneIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="contactPerson"
            label="Kontaktperson"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="testCourierGroupId"
            label="Kurrier-Gruppe"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Select loading={courierGroupDataLoading} allowClear>
              {courierGroupData.map((x) => {
                return <Option value={x?.id}>{x?.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="courierPickOrder"
            label="Kurrier-Abhol-Position"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              autoComplete="off"
              allowClear
              placeholder="Kurrier Abholreihenfolge"
            />
          </Form.Item>

          <Form.Item
            name="healthDepartmentEmail"
            label="Email Gesundheitsamt"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="E-Mail" />
          </Form.Item>

          <Form.Item name="noPcrPickup" label={""} valuePropName="checked">
            <Checkbox>
              PCR-Test's sollen <b>NICHT</b> vom Kurier abgeholt werden:
            </Checkbox>
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="userGroupId"
            label="Benutzergruppe"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Select loading={reqUserGroups?.loading} allowClear>
              {reqUserGroups?.data?.userGroups.map((x) => {
                return <Option value={x?.id}>{x?.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="logoId"
            label="Logo"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Select loading={reqFileUploads?.loading} allowClear>
              {uploadedFilesData.map((x) => {
                return <Option value={x?.id}>{x?.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="laboratoryId"
            label="Labor"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Select loading={reqLabor?.loading} allowClear>
              {laborData.map((x) => {
                return <Option value={x?.id}>{x?.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="mapsUrl"
            label="Google-Maps Link"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              allowClear
              autoComplete="off"
              placeholder="Google-Maps Link"
            />
          </Form.Item>

          <Form.Item
            name="impressumUrl"
            label="Impressum Link"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Impressum Link" />
          </Form.Item>

          <Form.Item
            name="agbsUrl"
            label="AGBs Link"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="AGBs Link" />
          </Form.Item>

          <Form.Item
            name="datenschutzUrl"
            label="Datenschutz Link"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              allowClear
              autoComplete="off"
              placeholder="Datenschutz Link"
            />
          </Form.Item>

          <Form.Item
            name="openingTimes"
            label="Öffnungszeiten"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item name="servicesIds" label="Angebotene Testtypen">
            <Checkbox.Group>
              <div className="">
                {reqTestTypesQuery?.data?.testTypes?.map((testType) => {
                  const testTypeId = testType?.id;
                  const testTypeName = testType?.name;
                  return (
                    <div className="w-48">
                      <Checkbox
                        value={testTypeId}
                        style={{ lineHeight: "32px" }}
                      >
                        {testTypeName}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            name="pricePcr6"
            label="PCR-6 Preis (0 = Standard-Preis)"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="0,00" />
          </Form.Item>

          <Form.Item
            name="pricePcr12"
            label="PCR-12 Preis (0 = Standard-Preis)"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="0,00" />
          </Form.Item>

          <Form.Item
            name="pricePcr24"
            label="PCR-24 Preis (0 = Standard-Preis)"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="0,00" />
          </Form.Item>

          <Form.Item
            name="priceSchnelltest"
            label="Schnelltest Preis (0 = Standard-Preis)"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input autoComplete="off" allowClear placeholder="0,00" />
          </Form.Item>

          <Form.Item
            name="disableDigitalSelbstauskunft"
            label="Digitale Selbstauskunft (Bürgertest)"
          >
            <Radio.Group>
              <Radio value={false}> Erlaubt </Radio>
              <Radio value={true}> Nicht Erlaubt </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="note"
            label="Notiz"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input.TextArea placeholder="Freitext" />
          </Form.Item>

          <Form.Item name="active" label="Aktiv">
            <Radio.Group>
              <Radio value={true}> Aktiv </Radio>
              <Radio value={false}> Inaktiv </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
