import { Table } from "styled";

import useWindowSize from "hooks/useWindowSize";

import { Columns } from "./Columns";

export const TestTable = ({
  data = [],
  loading = false,
  dataCount = 0,
  onPaginationChange,
  currentTablePage,
  pageSize,
}: any) => {
  const size = useWindowSize();

  const height = size?.height ? size.height : 0;

  let scroll = { y: height - 375, x: 1500 };

  if (!height) return <></>;

  return (
    <div
      // key={JSON.stringify(scroll)}

      // className=" h-[calc(100vh-275px)]  md:h-[calc(100vh-250px)]"
    >
      <Table
        size="small"
        columns={Columns as any}
        dataSource={data}
        scroll={{ ...scroll }}
        // pagination={{ position: ["bottomRight"], total: 3, pageSize: 50 }}
        locale={{ emptyText: "Keine Tests vorhanden" }}
        footer={() => <div>Total Datensätze: {dataCount}</div>}
        loading={loading}
        pagination={{
          total: dataCount,
          pageSize: pageSize,
          current: currentTablePage || 1,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            onPaginationChange({ page, pageSize });
          },
        }}
      />
    </div>
  );
};
