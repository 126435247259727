import React, { useState } from "react";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  EmojiHappyIcon,
  ExclamationIcon,
  BeakerIcon,
  ClockIcon,
} from "@heroicons/react/solid";
import { Button } from "styled";

import { TestResult } from "generated/schema";

import { useStartTestResultTimerMutation } from "generated/schema";
import moment from "moment";
import { NotStartTest } from "./NotStartableTest";
import styled from "styled-components";

interface StartButtonProps {
  testResult: TestResult;
}
export const StartTestButton = (props: StartButtonProps) => {
  const testResult: TestResult = props?.testResult;

  const laborRequired = testResult?.testType?.laborRequired;

  let allowedToStart = true;
  if (laborRequired) {
    const evaluationHours = testResult?.testTypeOption?.evaluationHours || 9999;

    const now = moment().format("HH");

    if (evaluationHours <= 12) {
      if (parseInt(now) > 12) {
        allowedToStart = false;
      }
    }
  }

  const [started, setStarted] = useState(false);
  const [startTestResultTimerMutation, { data, loading, error }] =
    useStartTestResultTimerMutation({
      variables: {
        id: props?.testResult?.id,
      },
    });

  const handleClick = () => {
    setStarted(true);
    startTestResultTimerMutation();
  };

  if (!allowedToStart) {
    return <NotStartTest testResult={testResult} />;
  }

  return (
    <div>
      <Button
        loading={started}
        onClick={handleClick}
        className="w-48"
        type="primary"
      >
        {!started && (
          <div className="flex">
            <BeakerIcon className="h-5 w-5 text-white " />
            <div className=" w-full">Starten</div>
          </div>
        )}
      </Button>
    </div>
  );
};
