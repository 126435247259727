import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { TestTableWrapper } from "components/TestTable";
import { TestTableSearch } from "components/TestTable/Search";
import { TestPageMenu } from "./TestMenu";
import { CustomerTableSearch } from "components/CustomerTable/Search";
import {
  useTestResultsLazyQuery,
  useTestResultChangedSubscription,
  useTestResultCountLazyQuery,
} from "generated/schema";
import moment from "moment";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { Button, Radio, Space } from "antd";
import { CreateTestResult } from "components/TestResult/CreateTestResult";
import { MyDrawer } from "components/MyDrawer";
import { getTestResultTitle } from "components/TestTableResultButton";
import { TestResultView } from "components/TestResult";
import { AppCtx } from "context/AppState";
import { useLocation } from "react-router-dom";
export const TestPage = () => {
  const context = useContext(AppCtx);


  let location = useLocation();

  useEffect(() => {
    context?.setSelectedTestResult(null);
  }, [location]);

  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <PageBreadcrumb value={["Tests"]} />
          {/* <TestPageMenu defaultValue={"/user-groups"} /> */}

          <TestTableWrapper />

          {context?.selectedTestResult && (
            <div key={context?.selectedTestResult?.id}>
              <Space size="middle">
                <MyDrawer
                  open={true}
                  title={getTestResultTitle(context?.selectedTestResult)}
                  triggerElement={<></>}
                >
                  <TestResultView testResult={context?.selectedTestResult} />
                </MyDrawer>
              </Space>
            </div>
          )}
        </div>
      </PageWrapper>
    </div>
  );
};
