import { Button } from "antd";
import { CSVLink, CSVDownload } from "react-csv";
import { read, utils, writeFile } from "xlsx";
interface Props {
  headers: any[];
  data: any;
  fileName: any;
}
export const ExportCsvButton = ({ headers, data, fileName }: Props) => {
  const handleExport = () => {
    //const headings = [["Movie", "Category", "Director", "Rating"]];
    const cols = headers?.map((x) => x.name);

    const result = data?.map((x: any) => {
      const row: any = {};
      headers.forEach((y) => {
        if (y.transform) {
          row[y.value] = y.transform(x[y.value]);
        } else {
          row[y.value] = x[y.value];
        }
      });

      return row;
    });

    const headings = [cols];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(result);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, [], { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, fileName);
    writeFile(wb, fileName + ".xlsx");
  };

  return (
    <div>
      <Button onClick={handleExport} size="small" type="primary">
        Excel Export
      </Button>
    </div>
  );
};
