import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { PcrPlate, TestResult } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { Tooltip } from "antd";
import _get from "lodash/get";
import {
  getTestResultTitle,
  TableTestStateButton,
} from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestResultView } from "components/TestResult";
import formatCurrency from "utils/formatCurrency";
import { useNavigate } from "react-router-dom";

const NavigateToPlateCol = ({ data }: any) => {
  const navigate = useNavigate();

  return (
    <Button
      size="small"
      onClick={() => navigate("/pcr-fill-plates/" + data?.id)}
      type="primary"
    >
      Platte Ansehen
    </Button>
  );
};
export const Columns: ColumnsType<TestResult> = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: 50,
  },

  {
    title: "Labor",
    dataIndex: "Labor",
    key: "labor",
    width: 230,
    render: (text: string, row: TestResult) => {
      return (
        <div key={row?.testFinishedAt}>
          <div>Labor - TC Elite Personal GmbH</div>
        </div>
      );
    },
  },

  {
    title: "Feldgröße",
    dataIndex: "plateSize",
    key: "plateSize",
    width: 150,
    render: (text: any, row: any) => <div>{text}</div>,
  },
  {
    title: "Freie Plätze",
    dataIndex: "freePlaceCount",
    key: "freePlaceCount",
    render: (text: string, row: TestResult) => {
      const label = text;

      return <div>{label}</div>;
    },
    width: 100,
  },

  {
    title: "Angelegt am",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    render: (text: any, row: any) => <div>{formatDate(text)}</div>,
  },
  {
    title: "Angelegt von",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    render: (text: any, pcrPlate: PcrPlate) => {
      const pcrPlateCreatedByUser = pcrPlate?.createdBy?.name || "-";

      return <div>{pcrPlateCreatedByUser}</div>;
    },
  },
  {
    title: "Abschluss am",
    dataIndex: "finishedAt",
    key: "finishedAt",
    width: 150,
    render: (text: any, row: PcrPlate) => (
      <div>{formatDate(row?.finishedAt)}</div>
    ),
  },

  {
    title: "Ergebnis Hochgeladen",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 250,
    render: (text: any, row: PcrPlate) => (
      <div>{formatDate(row?.resultsUploadedAt)}</div>
    ),
  },

  {
    title: "Plattenansicht",
    dataIndex: "age",
    key: "age",

    render: (text: string, row: PcrPlate) => {
      return (
        <>
          <NavigateToPlateCol data={row} />
        </>
      );
    },
  },

  // {
  //   title: "Action",
  //   key: "action",
  //   render: (_, testResult: TestResult) => {
  //     return (
  //       <Space size="middle">
  //         <MyDrawer
  //           title={getTestResultTitle(testResult)}
  //           triggerElement={
  //             <Button size="small" type="primary">
  //               Edit
  //             </Button>
  //           }
  //         >
  //           <TestResultView testResult={testResult} />
  //         </MyDrawer>
  //       </Space>
  //     );
  //   },
  // },
];
