import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
} from "antd";
// import { AppCtx } from "context/AppState";
// import {
//   useCreateCustomerMutation,
//   namedOperations,
//   useUpdateCustomerMutation,
// } from "generated/schema";
import styled from "styled-components";
import {
  IdentificationIcon,
  CalendarIcon,
  AtSymbolIcon,
  PhoneIcon,
  ClipboardListIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import {
  TestCustomer,
  useCreateTestCustomerMutation,
  useUpdateTestCustomerMutation,
} from "generated/schema";
import { AppCtx } from "context/AppState";

const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  customer?: TestCustomer;
  onClose?: any;
}

export const CustomerForm = ({ customer, onClose }: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [form] = Form.useForm();
  const [createUser, { data, loading }] = useCreateTestCustomerMutation();
  const [updateCustomer, updateReq] = useUpdateTestCustomerMutation();
   const context = useContext(AppCtx);

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    const activeTestCenterId = context?.activeTestCenter?.id
    if(values) {
      values.testCenterId = activeTestCenterId;
    }

    if (customer?.id) {
      updateCustomer({ variables: { id: customer.id, input: values } });
    } else {
      createUser({ variables: { input: values } });
    }

    success();
    onClose && onClose();

    // if (initialValues) {
    //   const req = await updateUser({
    //     refetchQueries: [namedOperations.Query.customers],
    //     variables: { id: initialValues.id, input: { ...values } },
    //   });

    //   if (req.data) {
    //     onHide();
    //   }
    // } else {
    //   if (context?.activeTestCenter) {
    //     values.testCenterId = context?.activeTestCenter;
    //   }

    //   const req = await createUser({
    //     refetchQueries: [namedOperations.Query.customers],
    //     variables: { input: { ...values } },
    //   });

    //   if (req.data) {
    //     onHide();
    //   }
    // }
  };

  let initValues = {
    ...customer,
  };

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
      // labelCol={{ span: 10 }}
      // wrapperCol={{ span: 14  }}
    >
      <div className="sm:grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <div>
          <Form.Item
            name="gender"
            label="Anrede"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Select allowClear>
              <Option value="male">Herr</Option>
              <Option value="female">Frau</Option>
              <Option value="divers">Divers</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="firstName"
            label="Vorname"
            rules={[
              {
                required: true,
                message: errorMessage,
                whitespace: true,
              },
            ]}
          >
            <Input
              allowClear
              autoComplete="off"
              placeholder="Vorname"
              addonBefore={<IdentificationIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Nachname"
            rules={[
              {
                required: true,
                message: errorMessage,
                whitespace: true,
              },
            ]}
          >
            <Input
              allowClear
              autoComplete="off"
              placeholder="Nachname"
              addonBefore={<IdentificationIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="dateOfBirth"
            label="Geburtsdatum"
            rules={[
              {
                required: true,
                message: errorMessage,
                whitespace: true,
              },
              {
                validator: (_, value) => {
                  if (
                    /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/.test(
                      value
                    )
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "Bitte im Format dd.mm.yyyy eingeben, zum Beispiel : 01.01.1999"
                    );
                  }
                },
              },
            ]}
          >
            <Input
              autoComplete="off"
              allowClear
              addonBefore={<CalendarIcon className="w-5 h-5" />}
              placeholder="TT.MM.JJJJ"
            />
          </Form.Item>

          <Form.Item
            name="street"
            label="Strasse und Hausnummer"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              autoComplete="off"
              allowClear
              placeholder="Strasse und Hausnummer"
              addonBefore={<IdentificationIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="zipCode"
            label="PLZ"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              autoComplete="off"
              allowClear
              placeholder="PLZ"
              addonBefore={<IdentificationIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="city"
            label="Stadt"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              autoComplete="off"
              allowClear
              placeholder="Stadt"
              addonBefore={<IdentificationIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Telefon"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              autoComplete="off"
              placeholder="Telefon Nummer"
              allowClear
              addonBefore={<PhoneIcon className="w-5 h-5" />}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-Mail"
            rules={[
              {
                type: "email",
                message: errorMessage,
                required: false,
              },
            ]}
          >
            <Input
              autoComplete="off"
              allowClear
              placeholder="Email-Adresse"
              addonBefore={<AtSymbolIcon className="w-5 h-5" />}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="birthCity"
            label="Geburtsort:"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              autoComplete="off"
              allowClear
              placeholder="Geburtsort"
              addonBefore={<IdentificationIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="passNumber"
            label="Ausweis-/Pass-Nr.:"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              allowClear
              autoComplete="off"
              placeholder="Ausweis-/Pass-Nr.:"
              addonBefore={<IdentificationIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="insuranceName"
            label="Krankenkasse"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              allowClear
              placeholder="Krankenkasse"
              autoComplete="off"
              addonBefore={<ClipboardListIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="insuranceNumber"
            label="Vers. Nr.:"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              allowClear
              autoComplete="off"
              placeholder="Versicherungsnummer"
              addonBefore={<ClipboardListIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="info"
            label="Info"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              allowClear
              autoComplete="off"
              placeholder="Info (Freifeld)"
              addonBefore={<InformationCircleIcon className="w-5 h-5" />}
            />
          </Form.Item>

          <Form.Item
            name="note"
            label="Notiz"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input.TextArea />
          </Form.Item>
        </div>
      </div>
      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
