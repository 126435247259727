import { useEndMyWorkTimeMutation, WorkTime } from "generated/schema";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import "moment-duration-format";
import { Button, Divider, List, Popconfirm, Typography } from "antd";
import { AppCtx } from "context/AppState";

interface Props {
  workTime: WorkTime;
}
export const CheckOutButton = () => {
  const context = useContext(AppCtx);
  const lat = context?.lat;
  const lng = context?.lng;

  const [endWorkTime, { data, loading, error }] = useEndMyWorkTimeMutation({
    fetchPolicy: "no-cache",
  });

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    const testCenterId = context?.activeTestCenter?.id;
    await endWorkTime({
      variables: {
        input: { testCenterId, lat: String(lat), lng: String(lng) },
      },
    });

    setOpen(false);
    setConfirmLoading(false);

    context?.setActiveTestCenter(null)

    // localStorage.removeItem("jwtToken");

    // window.location.href = "/";
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <div>
      <Popconfirm
        title={"Sind Sie sich sicher?"}
        open={open}
        onConfirm={handleOk}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
      >
        <Button danger block type="primary" onClick={showPopconfirm}>
          Auschecken
        </Button>
      </Popconfirm>
    </div>
  );
};
