import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { TestTableWrapper } from "components/TestTable";

import moment from "moment";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { Button, Radio } from "antd";
import { CreateTestResult } from "components/TestResult/CreateTestResult";
import { PcrPlates } from "components/PcrPlates";
import { useParams } from "react-router-dom";
import { FillPlateWrapper } from "components/PcrPlates/FillPlate/Wrapper";
import { useNavigate } from "react-router-dom";
export const CreatePcrPlatePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          {id && (
            <Button
              onClick={() => navigate(-1)}
              size="small"
              type="primary"
            >
              Zurück
            </Button>
          )}

          {id && <PageBreadcrumb value={["PCR-Platten", "Platte ID: " + id]} />}
          {!id && <PageBreadcrumb value={["PCR-Platten"]} />}
          {/* <TestPageMenu defaultValue={"/user-groups"} /> */}

          {id && <FillPlateWrapper />}
          {!id && <PcrPlates />}
        </div>
      </PageWrapper>
    </div>
  );
};
