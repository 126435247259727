import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("jwtToken");

    if (tokenString) {
      //   const userToken = JSON.parse(tokenString);
      return tokenString;
    }


    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: any) => {
    localStorage.setItem("jwtToken", userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
