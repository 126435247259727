import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { CustomerTableWrapper } from "components/CustomerTable";
import { TestTableSearch } from "components/TestTable/Search";

import moment from "moment";
import { Breadcrumb, Button } from "antd";
import { CustomerTableSearch } from "components/CustomerTable/Search";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { AdminMenu } from "components/AdminPageMenu";
import { StaffTableWrapper } from "components/StaffTable";
import { UserGroupTableWrapper } from "components/UserGroupTable";
import { UserGroupForm } from "components/UserGroupTable/UserGroupForm";
export const UserGroupPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <AdminMenu
            defaultValue="user-groups"
            onChange={() => console.log("df")}
          />
          <PageBreadcrumb value={["Benutzergruppen"]} />

          <div className="mb-4 grid grid-cols-2">
            <div className="w-full md:w-1/2 ">
           
            </div>

            <div className="flex justify-end">
              <MyDrawer
                title={"Benutzergruppe Anlegen"}
                triggerElement={
                  <Button type="primary">Benutzergruppe Anlegen</Button>
                }
              >
                <UserGroupForm />
              </MyDrawer>
            </div>
          </div>

          <UserGroupTableWrapper />
        </div>
      </PageWrapper>
    </div>
  );
};
