import { NavTabs } from "components/SubMenu";
import { UsersIcon, PlusIcon, ClockIcon, HomeIcon } from "@heroicons/react/solid";

import { useNavigate } from 'react-router-dom';
interface Props {
  defaultValue : string, 
  onChange: any
}
export const AdminMenu = ({defaultValue, onChange} : Props) => {

  const navigate = useNavigate()
  return (
    <div>
      <div className="mb-4">
        <NavTabs
          defaultValue={defaultValue}
          items={[
            
            {
              value: "test-centers",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <ClockIcon className="h-4 w-4 " />
                  </div>
                  <div>Standorte</div>
                </div>
              ),
            },
            
            {
              value: "laboratories",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <UsersIcon className="h-4 w-4 " />
                  </div>
                  <div>Labore </div>
                </div>
              ),
            },
            {
              value: "employees",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <UsersIcon className="h-4 w-4 " />
                  </div>
                  <div>Mitarbeiter </div>
                </div>
              ),
            },
            {
              value: "user-groups",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <UsersIcon className="h-4 w-4 " />
                  </div>
                  <div>Benutzergruppen </div>
                </div>
              ),
            },
            {
              value: "roles",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <HomeIcon className="h-4 w-4 " />
                  </div>
                  <div>Rollen</div>
                </div>
              ),
            },


            {
              value: "courier-groups",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <UsersIcon className="h-4 w-4 " />
                  </div>
                  <div>Kurier-Gruppen </div>
                </div>
              ),
            },

            {
              value: "logos",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <UsersIcon className="h-4 w-4 " />
                  </div>
                  <div>Logo-Verwaltung</div>
                </div>
              ),
            },
            {
              value: "openingTimes",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <ClockIcon className="h-4 w-4 " />
                  </div>
                  <div>Terminplaner</div>
                </div>
              ),
            },

            // {
            //   value: "/user-grosups",
            //   label: (
            //     <div className="flex space-x-1 items-center">
            //       <div>
            //         <UsersIcon className="h-4 w-4 " />
            //       </div>
            //       <div>Timeline </div>
            //     </div>
            //   ),
            // },
          ]}
          onChange={(activeTab: any) => {
          
            navigate("/" + activeTab)
            onChange(activeTab)
          }}
        />
      </div>
    </div>
  );
};
