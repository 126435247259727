import { Button, Modal, Grid } from "antd";
import { useContext, useRef, useState } from "react";
import { notification, Space, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { QrcodeIcon } from "@heroicons/react/solid";
import MyQrCodeScanner from "./Html5QrcodePlugin";
import {
  useTestMeetingLazyQuery,
  TestMeeting,
  useCreateTestResultMutation,
} from "generated/schema";
import { AppCtx } from "context/AppState";

const { useBreakpoint } = Grid;
const { confirm } = Modal;

export const QrCodeScanner = () => {
  const appContext = useContext(AppCtx);
  const screens = useBreakpoint();

  const activeTestCenterId = appContext?.activeTestCenter?.id;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scannedData, setScannedData] = useState("");
  const [showApprove, setShowApprove] = useState(false);

  const lastScannedData = useRef();

  const [getMeeting, { error, data, loading }] = useTestMeetingLazyQuery({
    fetchPolicy: "network-only",
  });

  const [
    createTestResultMutation,
    {
      data: testResultData,
      loading: testResultLoading,
      error: testResultError,
    },
  ] = useCreateTestResultMutation();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showError = (text: string) => {
    message.error(text);
  };

  const showSuccess = (text: string) => {
    message.success(text);
  };

  const showConfirm = (testMeeting: TestMeeting, cb: any) => {
    confirm({
      title: "Termin bereits eingecheckt",
      icon: <ExclamationCircleOutlined />,
      content: "Möchten Sie diesen Kunden nocheinmal einchecken?",
      onOk() {
        cb && cb();
        setShowApprove(false);
      },
      onCancel() {
        console.log("Cancel");
        setShowApprove(false);
        handleOk();
      },
    });
  };

  const getInitialCwaValue = (testMeeting: TestMeeting) => {
    if (testMeeting.cwaAnonym) return "cwaAnonym";
    if (testMeeting.cwaNotAnonym) return "cwaNotAnonym";

    return "cwaNot";
  };

  const handleOnBook = async (x: TestMeeting) => {
    const input: any = {
      name: "",
      testCenterId: activeTestCenterId || x?.testCenter?.id,
      testTypeId: x?.testType?.id,
      testTypeOptionId: x?.testTypeOption?.id,
      customerId: x?.customer?.id,
      meetingId: x?.id,
      cwaOption: getInitialCwaValue(x),
    };

    const book = await createTestResultMutation({
      variables: {
        input: {
          ...input,
        },
      },
    });

    if (book?.data?.createTestResult) {
      showSuccess("Termin eingecheckt!");
    }

    handleOk();
  };

  const handleOnScan = async (e: any) => {
    const scannedCode = e;

    try {
      if (!scannedCode.startsWith("STC-MID-")) {
        showError("Kein gültiger STC QR-Code");
        return;
      }

      const id = scannedCode.replace("STC-MID-", "");

      const isIdInt = Number.isInteger(parseInt(id));

      if (!isIdInt) {
        return showError("Kein gültiger STC QR-Code");
      }

      const intId = parseInt(id);

      const result = await getMeeting({ variables: { id: intId } });

      if (!result?.data) {
        showError("Kein Termin mit dieser ID gefunden.");
        return;
      }

      const testMeeting = result?.data?.testMeeting;
      if (testMeeting) {
        if (testMeeting?.testCreatedAt) {
          if (!showApprove) {
            showConfirm(testMeeting, async () => {
              await handleOnBook(testMeeting);
              handleOk();
            });

            setShowApprove(true);
          }
          return;
        }

        await handleOnBook(testMeeting);
      }

      handleOk();
    } catch (e) {
      console.log("e", e);
    }
  };

  let qrButtonTitle = screens?.xs
    ? "QR-Code"
    : "Termin QR-Code einscannen";
  return (
    <>
      <Button type="primary" onClick={showModal}>
        <div className="flex space-x-2 justify-start items-center">
          <div>{qrButtonTitle}</div>
          <QrcodeIcon className="h-5 w-5" />
        </div>
      </Button>

      {isModalOpen && (
        <MyQrCodeScanner
          onDataScan={(e: any) => {
            handleOnScan(e);
          }}
          onHide={() => handleCancel()}
        />
      )}
    </>
  );
};
