import { PcrPlate, TestResult } from "generated/schema";
import React, { useState } from "react";

import { useTestResultsByIdsQuery } from "generated/schema";
import { MyDrawer } from "components/MyDrawer";
import { PcrTestResultWrapper } from "./PcrTestResultWrapper";
interface Props {
  testResult: TestResult;
  pcrPlate?: PcrPlate;
  cell?: any;
}
export const ResultNegativeCell = ({ testResult, pcrPlate }: Props) => {
  const customerName = `${testResult?.customer?.firstName || "-"} ${
    testResult?.customer?.lastName || "-"
  } `;

  const dateOfBirthday = testResult?.customer?.dateOfBirth;
  const customerId = testResult?.customer?.id || "";
  const firstName = testResult?.customer?.firstName;
  const lastName = testResult?.customer?.lastName;
  const testId = testResult?.id;
  const title = `TestId: ${testId},  (${customerId}) - ${firstName} ${lastName}, ${dateOfBirthday}  `;

  return (
    <div className=" border-b border-r w-20 h-20 text-xs flex flex-col cursor-pointer">
      <div className="flex-1  bg-green-300  hover:bg-green-100 ">
        {customerName}
      </div>
      <MyDrawer
        title={title}
        triggerElement={
          <div className="bg-gray-300 w-full">TestId {testResult?.id}</div>
        }
      >
        <PcrTestResultWrapper testResultId={testResult?.id} />
      </MyDrawer>
    </div>
  );
};
