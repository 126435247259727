import { Button, Divider, Popconfirm } from "antd";
import React, { useState } from "react";

import {
  TestResult,
  useCreateTestTransportJobMutation,
} from "generated/schema";
import { DeleteTransportJobButton } from "../DeleteTransportJobButton";
export const CreateTransportJobButton = ({ testResults, testCenter }: any) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    const testCenterId = testCenter?.id;

    const testResultIds = testResults?.map((x: TestResult) => {
      return x?.id;
    });

    await createTestTransportJobMutation({
      variables: { input: { name: "", testCenterId, testResultIds } },
    });
    setOpen(false);
    setConfirmLoading(false);
    window.location.reload();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const [createTestTransportJobMutation, { data, loading, error }] =
    useCreateTestTransportJobMutation({});

  return (
    <div className=" ">
      <Divider orientation="left" plain>
        Abholung
      </Divider>

      <div className="flex">
        <Popconfirm
          title={"Sind Sie sich sicher?"}
          open={open}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button block type="primary" onClick={showPopconfirm}>
            Abholung bestätigen
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};
