import { Button, Popconfirm } from "antd";
import { AppCtx } from "context/AppState";
import { useCreateTestResultMutation } from "generated/schema";
import React, { useContext, useState } from "react";
import { TestCustomer } from "generated/schema";
import { Modal } from "antd";
import { TestTypeSelectCheckIn } from "./CheckInCustomerTestTypeSelect";

interface Props {
  value: any;
  testCustomer: TestCustomer;
}
export const CheckInCustomerColumn = ({ value, testCustomer }: Props) => {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setModalOpen(true);

    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    // console.log("Clicked cancel button");
    setOpen(false);
  };

  const handleCancelModal = () => {
    // console.log("Clicked cancel button");
    setModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="Kunde einchecken"
        open={modalOpen}
        onOk={handleOk}
        onCancel={handleCancelModal}
        footer={null}
      >
        <TestTypeSelectCheckIn
          testCustomer={testCustomer}
          onChange={() => console.log("")}
        />
      </Modal>

      <Popconfirm
        title={"Sind Sie sich sicher?"}
        open={open}
        onConfirm={handleOk}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
      >
        <Button danger onClick={showPopconfirm} size="small" type="primary">
          Kunde manuell einchecken
        </Button>
      </Popconfirm>
    </div>
  );
};
