import React from "react";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  EmojiHappyIcon,
  ExclamationIcon,
  BeakerIcon,
  ClockIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import { Button } from "styled";

import { TestResult } from "generated/schema";
import formatDate from "utils/formatDate";
import { Tooltip } from "antd";
import { LaborRequiredTest } from "./LaborRequiredTest";
import styled from "styled-components";

import { Modal } from "antd";
import { useState } from "react";

interface Props {
  testResult: TestResult;
}

const WarnButton = styled(Button)`
  background: black;
  border-color: black;

  &:hover {
    background: gray;
    border-color: gray;
  }

  &:focus {
    background: black;
    border-color: black;
  }
`;

export const NotStartTest = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <WarnButton onClick={showModal} className="w-48" type="primary">
        <div className="flex">
          <ExclamationIcon className="h-5 w-5 text-white " />
          <div className=" w-full">Nicht möglich </div>
          <InformationCircleIcon className="w-5 h-5" />
        </div>
      </WarnButton>

      <Modal
        title="Nicht möglich"
        open={isModalOpen}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleCancel}
      >
        Wir können nicht garantieren, dass unser Kurierdienst die Probe
        rechtzeitig abholen kann. Ein <b>PCR-12-Stunden-Test</b> kann bis 12:00
        Uhr durchgeführt werden.
        <div className="mt-4">Diese Testdurchführung ist nicht möglich</div>
        <div className="mt-8">
          Alternativ können Sie einen PCR-24-Stunden Test erstellen.
        </div>
      </Modal>
    </div>
  );
};
