import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { TestTableWrapper } from "components/TestTable";
import { TestTableSearch } from "components/TestTable/Search";
// import { TestPageMenu } from "./TestMenu";
import { LogTableWrapper } from "./../../components/LogTable/index";
import { PageBreadcrumb } from "components/PageBreadcrumb";

export const LogEventsPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4 h-full">
          {/* <TestPageMenu defaultValue={"/user-groups"} /> */} 
           <div className="mb-4">
           <PageBreadcrumb value={["Log Events"]}/>
          </div>

          <LogTableWrapper />
        </div>
      </PageWrapper>
    </div>
  );
};
