import { NavTabs } from "components/SubMenu";
import {
  UsersIcon,
  PlusIcon,
  ClockIcon,
  HomeIcon,
  FolderRemoveIcon,
} from "@heroicons/react/solid";
import { useContext } from "react";
import { AppCtx } from "context/AppState";
import { RightsEnumType } from "generated/schema";

interface Props {
  defaultValue: string;
  onChange: any;
}
export const TestFormMenu = ({ defaultValue, onChange }: Props) => {
  const appContext = useContext(AppCtx);

  const hasUserRight = appContext?.hasUserRight
    ? appContext?.hasUserRight
    : () => {};

  return (
    <div>
      <div className="mb-4">
        <NavTabs
          defaultValue={defaultValue}
          items={[
            {
              value: "testResult",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <UsersIcon className="h-4 w-4 " />
                  </div>
                  <div>Testbogen </div>
                </div>
              ),
            },
            {
              value: "documents",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <ClockIcon className="h-4 w-4 " />
                  </div>
                  <div>Dokumente</div>
                </div>
              ),
            },
            {
              value: "customer",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <UsersIcon className="h-4 w-4 " />
                  </div>
                  <div>Kunden Stammdaten </div>
                </div>
              ),
            },
            {
              value: "activityLog",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <ClockIcon className="h-4 w-4 " />
                  </div>
                  <div>Aktivitätslog</div>
                </div>
              ),
            },

            {
              value: "logTable",
              hide: !hasUserRight(RightsEnumType.LogEventsRight),
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <ClockIcon className="h-4 w-4 " />
                  </div>
                  <div>Log-Daten</div>
                </div>
              ),
            },

            {
              value: "testCenter",
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <HomeIcon className="h-4 w-4 " />
                  </div>
                  <div>Teststation</div>
                </div>
              ),
            },

            {
              value: "deleteTest",
              hide: !hasUserRight(RightsEnumType.DeleteTestRight),
              label: (
                <div className="flex space-x-1 items-center">
                  <div>
                    <FolderRemoveIcon className="h-4 w-4 " />
                  </div>
                  <div>Admin</div>
                </div>
              ),
            },

            // {
            //   value: "/user-grosups",
            //   label: (
            //     <div className="flex space-x-1 items-center">
            //       <div>
            //         <UsersIcon className="h-4 w-4 " />
            //       </div>
            //       <div>Timeline </div>
            //     </div>
            //   ),
            // },
          ].filter((x) => !x.hide)}
          onChange={(activeTab: any) => {
            console.log(activeTab);
            onChange(activeTab);
          }}
        />
      </div>
    </div>
  );
};
