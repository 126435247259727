import { Select } from "antd";
import React from "react";
import { useTestCentersQuery } from "generated/schema";
import { Divider, List, Typography } from "antd";

const { Option } = Select;

interface Props {
  onChange: any;
}
export const TestCenterSelectTimes = ({ onChange }: Props) => {
  const { data, loading, error } = useTestCentersQuery({
    fetchPolicy: "network-only",
  });

  const handleChange = (value: string) => {
    const testCenter = data?.testCenters?.find((x) => x?.id == parseInt(value));
    onChange(testCenter);
  };

  return (
    <>
      <Select
        allowClear
        placeholder="Standort"
        loading={loading}
        style={{ width: "100%" }}
        onChange={handleChange}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => {
          return ((option?.children as unknown as string) || "")
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      >
        {data?.testCenters?.map((x) => {
          return <Option value={x?.id}>{x?.name}</Option>;
        })}
      </Select>
    </>
  );
};
