import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import {
  TestCenter,
  TestCenterTypeOption,
  TestCustomer,
  TestType,
} from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { MyDrawer } from "components/MyDrawer";
import { TestCenterFormWrapper } from "./TestCenterForm";
import { feralStateTypeMapping } from "./TestCenterForm";
import _get from "lodash/get";
import { ShowMore } from "./ShowMore";
export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: 50,
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (text: string, row: TestCustomer) => {
      const name = text || "-";
      return <div>{name}</div>;
    },
  },

  {
    title: "Anschrift",
    dataIndex: "street",
    key: "street",
    width: 250,
    render: (text: any, row: TestCenter) => {
      const street = `${row?.street || "-"}`;
      const city = `${row?.city || "-"}`;
      const zipCode = `${row?.zipCode || "-"}`;
      const federalState = `${row?.federalState || "-"}`;

      const phone = _get(feralStateTypeMapping, federalState, "-");

      const full = `${street}, ${zipCode} ${city}, ${phone}`;

      return (
        <div className="">{full}</div>
      );
    },
  },
  // {
  //   title: "Postleitzahl",
  //   dataIndex: "zipCode",
  //   key: "zipCode",
  //   width: 120,
  //   render: (text: any, row: TestCustomer) => {
  //     const city = text || "-";
  //     return <div>{city}</div>;
  //   },
  // },
  {
    title: "Notiz",
    dataIndex: "note",
    key: "city",
    width: 200,
    render: (text: any, row: TestCustomer) => {
      const phone = text || "-";
      return <div>{phone}</div>;
    },
  },
  // {
  //   title: "Bundesland",
  //   dataIndex: "federalState",
  //   key: "federalState",
  //   width: 200,
  //   render: (text: any, row: TestCustomer) => {
  //     const phone = _get(feralStateTypeMapping, text, "-");
  //     return <div>{phone}</div>;
  //   },
  // },

  // {
  //   title: "Einrichtung",
  //   dataIndex: "testCenterType",
  //   key: "testCenterType",
  //   render: (value: string, row: TestCenter) => {
  //     const testCenterType = row.testCenterType;

  //     let name = "-";

  //     if (testCenterType) {
  //       const testCenterTypeOption: TestCenterTypeOption = testCenterType;
  //       name = testCenterType?.name || "-";
  //     }

  //     return <div>{name}</div>;
  //   },
  //   width: 150,
  // },

  {
    title: "Benutzergruppe",
    dataIndex: "userGroup",
    key: "userGroup",
    render: (value: string, row: TestCenter) => {
      const name = row.userGroup?.name || "-";

      return <div>{name}</div>;
    },
    width: 175,
  },

  {
    title: "Kurier-Gruppe",
    dataIndex: "testCenterType",
    key: "testCenterType",
    render: (value: string, row: TestCenter) => {
      const v = row?.courierGroup?.name;

      let name = "-";

      if (v) {
        name = v;
      }

      return <div>{name}</div>;
    },
    width: 150,
  },
  // {
  //   title: "PCR-Labor",
  //   dataIndex: "laboratory",
  //   key: "laboratory",
  //   render: (value: string, row: TestCenter) => {
  //     const name = row?.laboratory?.name || [];

  //     return <div>{name}</div>;
  //   },
  //   width: 150,
  // },
  // {
  //   title: "Logo",
  //   dataIndex: "logo",
  //   key: "logo",
  //   render: (value: string, row: TestCenter) => {
  //     const v = row?.logo?.name;

  //     let name = "-";

  //     if (v) {
  //       name = v;
  //     }

  //     return <div>{name}</div>;
  //   },
  //   width: 150,
  // },

  // {
  //   title: "Kurier-Pickup-Slot",
  //   dataIndex: "courierPickOrder",
  //   key: "courierPickOrder",
  //   render: (value: string, row: TestCenter) => {
  //     const v = value;

  //     let name = "-";

  //     if (v) {
  //       name = v;
  //     }

  //     return <div>{name}</div>;
  //   },
  //   width: 150,
  // },
  {
    title: "Teststellen-ID",
    dataIndex: "testCenterId",
    key: "testCenterId",
    render: (value: string, row: TestCenter) => {
      const v = value;

      let name = "-";

      if (v) {
        name = v;
      }

      return <div>{name}</div>;
    },
    width: 150,
  },

  {
    title: "Services",
    dataIndex: "services",
    key: "services",
    render: (value: string, row: TestCenter) => {
      const services = row?.services || [];

      const tags: any = [];

      services
        .sort((x: TestType, y: TestType) => x?.id - y?.id)
        .map((testType: TestType) => {
          const color = testType?.color;
          const name = testType?.name || "gray";

          tags.push(<Tag color={color || "gray"}>{name}</Tag>);
        });

      return (
        <div className="w-full overflow-x-auto whitespace-nowrap">{tags}</div>
      );

      return <div>{tags}</div>;
    },
    width: 200,
  },

  {
    title: "Öffnungszeiten",
    dataIndex: "openingTimes",
    key: "services",
    render: (value: string, row: TestCenter) => {
      const moStart = row?.moStart || "-";
      const moEnd = row?.moEnd || "-";

      const saStart = row?.satStart || "-";
      const saEnd = row?.satEnd || "-";

      const sunStart = row?.sunStart || "-";
      const sunEnd = row?.sunEnd || "-";

      return (
        <ShowMore text="">
          <div>
            <div className="flex space-x-3">
              <div>
                Mo-Fr. {moStart}-{moEnd}
              </div>
              <div>
                Sa. {saStart}-{saEnd}
              </div>

              <div>
                So. {sunStart}-{sunEnd}
              </div>
            </div>
          </div>
        </ShowMore>
      );

      return (
        <div
          style={{ width: 280 }}
          className="overflow-scroll whitespace-nowrap"
        >
          <div className="flex space-x-3">
            <div>
              Mo-Fr. {moStart}-{moEnd}
            </div>
            <div>
              Sa. {saStart}-{saEnd}
            </div>

            <div>
              So. {sunStart}-{sunEnd}
            </div>
          </div>
        </div>
      );
    },
  },

  {
    title: "Active",
    dataIndex: "active",
    key: "active",
    width: 200,
    render: (value: string, row: TestCenter) => {
      const active = row?.active || false;

      const tags: any = [];

      if (active) {
        return <Tag color={"green"}>Aktiv</Tag>;
      }
      return <Tag color={"red"}>Inaktiv</Tag>;
    },
  },

  {
    title: "Action",
    key: "action",
    render: (_, row: TestCenter) => {
      const id = row?.id;

      const title = `Editiere Standort: ${id} `;
      return (
        <Space size="middle">
          <Space size="middle">
            <MyDrawer
              title={title}
              triggerElement={
                <Button size="small" type="primary">
                  Edit
                </Button>
              }
            >
              <TestCenterFormWrapper testCenter={row} />
            </MyDrawer>
          </Space>
        </Space>
      );
    },
  },
];
