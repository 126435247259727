import React, { useState } from "react";
import {
  BeakerIcon,
  MenuIcon,
  UsersIcon,
  LogoutIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
} from "@heroicons/react/solid";
import { Grid } from "antd";
import { Link, useLocation } from "react-router-dom";

const { useBreakpoint } = Grid;

export function Logo() {
  const screens = useBreakpoint();

  return (
    <div className="w-full ">
      <div className="flex">
       
          <div className="w-[260px]  min-w-[260px]">
            <img
              style={{ height: 50 }}
              src="/tcLogo.png"
              alt=""
            />
          </div>
     
      </div>
    </div>
  );
}

// {screens.xs == true && (
//     <div className="w-[60px]  min-w-[40px]">
//       <img
//         style={{ height: 50 }}
//         src="http://95.217.163.232:6869/blub/logo_xs.PNG"
//         alt=""
//       />
//     </div>
//   )}
