import { Button, Popconfirm } from "antd";
import React, { useState } from "react";
import {
  PcrPlate,
  TestResult,
  useRemoveFromPcrPlateMutation,
} from "generated/schema";

interface Props {
  pcrPlate: PcrPlate;
  testResult: TestResult;
  closeModal?: any;
}
export const RemoveFromPlateButton = ({ pcrPlate, testResult, closeModal }: Props) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [removeFromPcrPlateMutation, { data, loading, error }] =
    useRemoveFromPcrPlateMutation({});

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await removeFromPcrPlateMutation({
      variables: { id: pcrPlate?.id, input: { testId: testResult?.id } },
    });
    setOpen(false);
    
    setConfirmLoading(false);
    closeModal && closeModal()
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Sind Sie sich sicher?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <div className="flex justify-end">
        <Button onClick={showPopconfirm} danger type="primary">
          Probe aus Zelle löschen
        </Button>
      </div>
    </Popconfirm>
  );
};
