import { Alert, Button, Radio, Select, Space, Spin } from "antd";
import React, { useContext, useState } from "react";
import { useTestCentersQuery } from "generated/schema";
import { AppCtx } from "context/AppState";
const { Option } = Select;

interface Props {
  onChange: any;
}
export const TestCenterSelectWelcome = ({ onChange }: Props) => {
  const context = useContext(AppCtx);


  const [selectedId, setSelectedId] = useState(
    context?.activeTestCenter?.id || 0
  );
  const { data, loading, error } = useTestCentersQuery({
    fetchPolicy: "network-only",
  });

  const handleChange = (value: any) => {
    if (value == selectedId) {
      setSelectedId(0);
      context?.setActiveTestCenter(null);
    } else {
      const testCenter = data?.testCenters?.find((x) => x.id == value);

      context?.setActiveTestCenter(testCenter);
      setSelectedId(value);
    }
  };

  if (loading) {
    return <Spin />;
  }
  const testCenterData = data?.testCenters || [];

  const entries = selectedId
    ? testCenterData?.filter((x: any) => x?.id == selectedId)
    : testCenterData;

  return (
    <>
      <div style={{ maxHeight: 500, maxWidth: 550 }} className="overflow-auto">
        <Radio.Group
          defaultValue={context?.activeTestCenter?.id}
          onChange={(e) => handleChange(e?.target?.value)}
        >
          <Space direction="vertical">
            {entries.map((item: any) => {
              const id = item?.id;
              const city = item?.city || "";
              const zipCode = item?.zipCode || "";
              const name = item?.name || "n.A";
              const street = item?.street || "";
              const openingTimesTrimmed =
                (item?.openingTimes && (item?.openingTimes).trim()) || "";

              const nameRow = `${name}`;
              const nameAdd = `${street}, ${zipCode} ${city}`;

              return (
                <div>
                  <div className="bg-gray-50 p-2">
                    <Radio value={id}>
                      <div>
                        {nameRow}

                        <div>
                          <span className="font-normal">{nameAdd}</span>
                        </div>
                        <div
                          className="whitespace-pre font-normal text-gray-400"
                          style={{ resize: "none" }}
                        >
                          {openingTimesTrimmed}
                        </div>
                      </div>
                    </Radio>
                  </div>
                  {!context?.workTime && selectedId != 0 && (
                    <Button
                      onClick={() => handleChange(selectedId)}
                      className="mt-2"
                      block
                      type="text"
                      danger
                    >
                      Auswahl entfernen
                    </Button>
                  )}
                </div>
              );
            })}
          </Space>
        </Radio.Group>
      </div>
    
    </>
  );

  return (
    <>
      <Select
        allowClear
        placeholder="Standort"
        loading={loading}
        style={{ width: "100%" }}
        onChange={handleChange}
      >
        {data?.testCenters?.map((x) => {
          return <Option value={x?.id}>{x?.name}</Option>;
        })}
      </Select>
    </>
  );
};
