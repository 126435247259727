import { Button } from "antd";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { MyDrawer } from "components/MyDrawer";
import {
  Direction,
  useRoleChangedSubscription,
  useRolesLazyQuery,
  useTestCourierGroupCountLazyQuery,
  useTestCourierGroupsLazyQuery,
  useTestCourierGroupChangedSubscription,
} from "generated/schema";
import { useEffect, useState } from "react";
import { CustomerTableSearch } from "./Search";
import { TestTable } from "./Table";
import { CourierGroupForm } from "./CourierGroupForm";

const TABLE_PAGE_SIZE = 12;

export const CourierGroupsTableWrapper = () => {
  const [currentTablePage, setCurrentPageTable] = useState(1);

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "id",
    direction: Direction.Desc,
  });

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const [getData, { data, loading }] = useTestCourierGroupsLazyQuery({
    fetchPolicy: "network-only",
    variables: { orderBy, take, skip },
  });
  const [getDataCount, { data: customerCountData, loading: l }] =
    useTestCourierGroupCountLazyQuery({ fetchPolicy: "network-only" });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useTestCourierGroupChangedSubscription({});

  useEffect(() => {
    getData();
    getDataCount();
  }, [customerChangedData]);

  const resultData = data?.testCourierGroups || [];

  return (
    <div>
      <div className="mb-4 grid grid-cols-2">
        <div className="w-full md:w-1/2 ">
          {/* <CustomerTableSearch /> */}
        </div>

        <div className="flex justify-end">
          <MyDrawer
            title={"Kurier-Gruppe anlegen"}
            triggerElement={
              <Button type="primary">Kurier-Gruppe anlegen</Button>
            }
          >
            <CourierGroupForm />
          </MyDrawer>
        </div>
      </div>
      <TestTable
        dataCount={customerCountData?.testCourierGroupCount || 0}
        data={resultData}
        loading={loading}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
      />
    </div>
  );
};
