import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Checkbox, Divider, Alert } from "antd";
import { useParams } from "react-router-dom";

import { RadioChangeEvent } from "antd";
import { Radio, Space } from "antd";
import { Button } from "styled";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { Form, Input, Select, Spin } from "antd";
import {
  RightsEnumType,
  TestResult,
  TestType,
  useEnterTestResultMutation,
} from "generated/schema";
import {
  useTestTypesQuery,
  useTestTypeOptionsQuery,
  CashType,
} from "generated/schema";

import { SignaturePad } from "components/SignaturePad";
import formatCurrency from "utils/formatCurrency";
import { DownloadOutlined } from "@ant-design/icons";
import formatDate from "utils/formatDate";
import { DownloadReportButton } from "./DownloadReportButton";
import _get from "lodash/get";
import { CwaOptions } from "../CwaOptions";
import { AppCtx } from "context/AppState";
const { Option } = Select;
const SButton = styled(Button)`
  width: 300px;
  margin: 3px;
`;

interface TestValue {
  id?: number;
}

interface TestResultInputProps {
  value?: TestValue;
  onChange?: (value: TestValue) => void;
  onSelect?: any;
}

const ResultButtons: React.FC<TestResultInputProps> = ({
  value = {},
  onChange,
  onSelect,
}) => {
  const [clickedValue, setClickedValue] = useState(value?.id);

  const triggerChange: any = (changedValue: any) => {
    onChange?.({
      clickedValue,
      ...value,
      ...changedValue,
    });
  };

  const handleOnChange = (value: any) => {
    setClickedValue(value);
    triggerChange({ id: value });

    onSelect && onSelect(true);
  };

  return (
    <div className="md:flex">
      <SButton
        onClick={() => handleOnChange(2)}
        type={clickedValue == 2 ? "primary" : "default"}
      >
        Negativ / Negative
      </SButton>

      <SButton
        onClick={() => handleOnChange(1)}
        type={clickedValue == 1 ? "primary" : "default"}
        danger={clickedValue == 1}
      >
        Positiv / Positive
      </SButton>

      <SButton
        onClick={() => handleOnChange(3)}
        type={clickedValue == 3 ? "primary" : "default"}
      >
        Nicht eindeutig / Invalid
      </SButton>
    </div>
  );
};
interface Props {
  testResult: TestResult;
  onClose?: any;
}

export const TestForm = ({ testResult, onClose }: Props) => {
  const appContext = useContext(AppCtx);

  const hasUserRight = appContext?.hasUserRight
    ? appContext?.hasUserRight
    : () => {};

  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [enterTestResultMutation, { data, loading, error }] =
    useEnterTestResultMutation();

  const testTypesReq = useTestTypesQuery({ fetchPolicy: "no-cache" });
  const testTypeOptionsReq = useTestTypeOptionsQuery({
    fetchPolicy: "no-cache",
  });

  const [activeTestTypeId, setActiveTestTypeId] = useState();
  const [activeTestTypeOptionId, setActiveTestTypeOptionId] = useState();

  const testTypesData = testTypesReq?.data?.testTypes || [];
  const [form] = Form.useForm();
  const testTypeOptionId = Form.useWatch("testTypeOptionId", form);
  const selectedCashType = Form.useWatch("cashType", form);

  const selectedTestTypeId = activeTestTypeId || testResult?.testType?.id;

  const selectedTestType = testTypesData.find((testType) => {
    if (activeTestTypeId) return testType?.id == activeTestTypeId;

    return selectedTestTypeId == testType?.id;
  });

  let showEdit =
    testResult?.testType?.laborRequired && testResult?.specimenAt && false;

  if (!testResult?.specimenAt) {
    showEdit = true;
  }

  if (testResult?.result) {
    showEdit = false;
  }

  if (appContext?.user?.role?.id == 1) {
    showEdit = true;
  }

  if (appContext?.user?.role?.id == 2) {
    showEdit = true;
  }

  if (hasUserRight(RightsEnumType.LaborRequiredTestEnterResultRight)) {
    showEdit = true;
  }

  if (testTypesReq?.loading) {
    return <Spin />;
  }

  if (testTypeOptionsReq?.loading) {
    return <Spin />;
  }

  const testTypeOptionsData = testTypeOptionsReq?.data?.testTypeOptions || [];

  const onFinish = (values: any) => {
    console.log("Success:", values);

    const selectedTestTypeOption = selectedTestTypeOptions?.find((x) => {
      return x.id == (testTypeOptionId || testResult?.testTypeOption?.id);
    });

    const oegdHousedIn = values.oegdHousedIn;
    const oegdWorkIn = values.oegdWorkIn;

    let oegdQuestonnaire = {};

    if (oegdHousedIn) {
      oegdQuestonnaire = { oegdHousedIn: true, [oegdHousedIn]: true };
    }

    if (oegdWorkIn) {
      oegdQuestonnaire = { oegdWorkIn: true, [oegdWorkIn]: true };
    }

    enterTestResultMutation({
      variables: {
        id: testResult?.id,
        input: {
          testResultId: values?.testResult?.id,
          testProofText: values?.testProofText,
          testTypeId: values?.testTypeId,
          testTypeOptionId: values?.testTypeOptionId,
          ctValue: values?.ctValue,
          userSignature: values?.userSignature,
          customerSignature: values?.customerSignature,
          cashType: values?.cashType,
          price: testPrice,
          specimenApproved: values.specimenAt,
          pdfPasswordProtected: !values.pdfPasswordProtected,
          ...oegdQuestonnaire,
          oegdProofText: values.oegdProofText,
          cwaOption: values?.cwaOption,
          priceFreeReason: values?.priceFreeReason,
        },
      },
    });

    onClose && onClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const selectedTestTypeOptions =
    (selectedTestType?.options &&
      selectedTestType?.options?.filter((x) => x?.active == true)) ||
    [];

  const optionsLabel = selectedTestType?.optionsLabel;
  const laborRequired = selectedTestType?.laborRequired;
  const oegdProofTextRequired = selectedTestType?.oegdProofTextRequired;
  const oegdQuestionnaireRequired = selectedTestType?.oegdQuestionnaireRequired;
  let signatureRequired = selectedTestType?.signatureRequired;
  const optionsLabelDescription = selectedTestType?.optionsLabelDescription;
  const showCtFieldInput = selectedTestType?.showCtFieldInput;
  const showTestProofText = selectedTestType?.showProofTextInput;

  const selectedTestTypeOption = selectedTestTypeOptions?.find((x) => {
    return x.id == (testTypeOptionId || testResult?.testTypeOption?.id);
  });

  const showPrice =
    selectedTestTypeOption?.price != null && selectedTestTypeOption?.price > 0;
  const testResultInitialValues = testResult?.result?.id
    ? { testResult: { id: testResult?.result?.id } }
    : {};

  let testPrice = selectedTestTypeOption?.price;

  // testType PCR 12
  if (selectedTestTypeOption?.id == 1) {
    const pricePcr12 = testResult?.testCenter?.pricePcr12;

    if (pricePcr12) {
      testPrice = pricePcr12;
    }
  }

  if (selectedTestTypeOption?.id == 2) {
    const pricePcr24 = testResult?.testCenter?.pricePcr24;

    if (pricePcr24) {
      testPrice = pricePcr24;
    }
  }

  let showTestResultInput = false;
  let showCtField = false;

  if (laborRequired) {
    showTestResultInput = !!testResult?.specimenAt;
  }

  if (!laborRequired) {
    showTestResultInput = true;
  }

  if (laborRequired && testResult?.specimenAt) {
    showCtField = true;
  }

  let showHintSelbstauskunft = false;

  if (signatureRequired) {
    const disableDigitalSelbstauskunft =
      appContext?.activeTestCenter?.disableDigitalSelbstauskunft;

    if (disableDigitalSelbstauskunft) {
      signatureRequired = false;

      if (selectedTestType?.id == 1) {
        showHintSelbstauskunft = true;
      }
    }
  }

  const getInitialOegdValues = (testResult: TestResult) => {
    const oegdWorkInOtherFacility = testResult?.oegdWorkInOtherFacility;
    const oegdWorkInPublicFacility = testResult?.oegdWorkInPublicFacility;
    const oegdWorkIn = testResult?.oegdWorkIn;
    const oegdHousedInLivingFacility = testResult?.oegdHousedInLivingFacility;
    const oegdHousedInMedicalFacility = testResult?.oegdHousedInMedicalFacility;
    const oegdHousedIn = testResult?.oegdHousedIn;

    if (oegdHousedIn) {
      if (oegdHousedInMedicalFacility)
        return { oegdHousedIn: "oegdHousedInMedicalFacility" };
      if (oegdHousedInLivingFacility)
        return { oegdHousedIn: "oegdHousedInLivingFacility" };
    }

    if (oegdWorkIn) {
      if (oegdWorkInPublicFacility)
        return { oegdWorkIn: "oegdWorkInPublicFacility" };
      if (oegdWorkInOtherFacility)
        return { oegdWorkIn: "oegdWorkInOtherFacility" };
    }

    return { oegdHousedIn: null, oegdWorkIn: null };
  };

  const oegdInit = getInitialOegdValues(testResult);

  const missingCustomerValues: any = [];
  const requiredCustomerFieldsForLabor = [
    { key: "customer.gender", label: "Geschlecht" },
    { key: "customer.dateOfBirth", label: "Geburtsdatum" },
    { key: "customer.street", label: "Straße" },
    { key: "customer.firstName", label: "Vorname" },
    { key: "customer.lastName", label: "Nachname" },
    { key: "customer.city", label: "Stadt" },
    //{ key: "customer.birthCity", label: "Geburtsort" },
    { key: "customer.zipCode", label: "Postleitzahl" },
    //{ key: "customer.passNumber", label: "Ausweis-/Pass-Nr." },
  ].map((x: any) => {
    const v = _get(testResult, x.key);

    if (!v) missingCustomerValues.push(x);
    return v;
  });

  if (laborRequired) {
    const isCustomerValid = requiredCustomerFieldsForLabor.filter((x) => !x);

    // console.log("isCustomerValid", isCustomerValid);

    if (isCustomerValid.length > 0) {
      return (
        <div>
          <Alert
            message={
              <div>
                Nicht alle Kundeninformationen sind ausgefüllt, bitte füllen Sie
                alle notwendigen Felder (Kunden-Stammdaten Tab) aus.
              </div>
            }
            type="error"
          />

          <div className="p-4">
            <div className="text-lg font-bold">Fehlende Felder</div>
            <ul className="list-disc ml-4 mt-2">
              {missingCustomerValues.map((x: any) => {
                return <li>{x.label}</li>;
              })}
            </ul>
          </div>
        </div>
      );
    }
  }

  const getInitialCwaValue = (testResult: TestResult) => {
    if (testResult.cwaAnonym) return "cwaAnonym";
    if (testResult.cwaNotAnonym) return "cwaNotAnonym";

    return "cwaNot";
  };

  const cwaOption = getInitialCwaValue(testResult);

  // show only location based testTypes

  let testCenterServices = testTypesData;

  if (!hasUserRight(RightsEnumType.TestResultAllOptionRight)) {
    testCenterServices = testCenterServices.filter((x) => {
      const activeTestCenter = appContext?.activeTestCenter;

      const testCenterServices = activeTestCenter?.services || [];
      return testCenterServices.find((y: TestType) => y.id == x.id);
    });
  }

  return (
    <div>
      <Form
        scrollToFirstError={true}
        onFieldsChange={(e) => {
          setFormValuesChanged(true);
        }}
        form={form}
        name="testResultForm"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          testTypeId: testResult?.testType?.id,
          testProofText: testResult?.testProofText,
          testTypeOptionId: testResult?.testTypeOption?.id,
          ...testResultInitialValues,
          ctValue: testResult?.ctValue,
          userSignature: testResult?.userSignature,
          customerSignature: testResult?.customerSignature,
          cashType: testResult?.cashType,
          price: testResult?.price,
          specimenAt: !!testResult?.specimenAt,
          pdfPasswordProtected: !!!testResult?.pdfPasswordProtected,
          oegdProofText: testResult?.oegdProofText,
          ...oegdInit,
          cwaOption,
        }}
      >
        <Form.Item
          name="testTypeId"
          label="TestTyp"
          rules={[{ required: true }]}
        >
          <Select
            onChange={(e) => {
              form.setFieldsValue({ testTypeOptionId: null });
              setActiveTestTypeId(e);
            }}
            loading={loading}
            style={{ width: "100%" }}
          >
            {testCenterServices.map((x) => {
              return <Option value={x?.id}>{x?.name}</Option>;
            })}
          </Select>
        </Form.Item>

        {selectedTestTypeOptions.length > 0 && (
          <Form.Item
            name="testTypeOptionId"
            label={optionsLabel}
            rules={[{ required: true }]}
          >
            <Select loading={loading} style={{ width: "100%" }}>
              {selectedTestTypeOptions.map((x) => {
                return <Option value={x?.id}> {x?.name}</Option>;
              })}
            </Select>
          </Form.Item>
        )}
        {showTestProofText && (
          <div className="text-xs -mt-4">{optionsLabelDescription}</div>
        )}

        {showTestProofText && (
          <Form.Item
            label="Nachweis Info"
            name="testProofText"
            rules={[{ required: true, message: "Nachweis Info (Pflicht)" }]}
          >
            <Input />
          </Form.Item>
        )}

        {hasUserRight(RightsEnumType.LaborRequiredTestEnterResultRight) &&
          showCtFieldInput &&
          showCtField && (
            <Form.Item
              label="CT-Wert"
              name="ctValue"
              rules={[{ required: true, message: "CT-Wert (Pflicht)" }]}
            >
              <Input />
            </Form.Item>
          )}

        {oegdQuestionnaireRequired && (
          <>
            <div className="mb-3">
              Besondere Risikomerkmale einer Weiterverbreitung (sofern
              zutreffend bitte ankreuzen)
            </div>
            <div className="flex">
              <div className="flex-1">
                <div className="mb-2"> Betreut/untergebracht in:</div>
                <Form.Item label="" name="oegdHousedIn">
                  <Radio.Group
                    onChange={() => {
                      form.setFieldsValue({ oegdWorkIn: null });
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"oegdHousedInMedicalFacility"}>
                        Medizinischen Einrichtungen
                      </Radio>
                      <Radio value={"oegdHousedInLivingFacility"}>
                        Pflege- und anderen Wohneinrichtungen
                      </Radio>
                      <Radio value={null}>Keine</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div className="flex-1">
                <div className="mb-2"> Tätigkeit in Einrichtung:</div>
                <Form.Item label="" name="oegdWorkIn">
                  <Radio.Group
                    onChange={() => {
                      form.setFieldsValue({ oegdHousedIn: null });
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={"oegdWorkInPublicFacility"}>
                        Gemeinschaftseinrichtungen (z.B. Kita, Schule)
                      </Radio>
                      <Radio value={"oegdWorkInOtherFacility"}>
                        Sonstige Einrichtungen
                      </Radio>

                      <Radio value={null}>Keine</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
            {/* <Form.Item
              label="Vorgezeigtes Nachweisdokument"
              name="oegdProofText"
              rules={[
                {
                  required: true,
                  message: "Vorgezeigtes Nachweisdokument:  (Pflicht)",
                },
              ]}
            >
              <Input />
            </Form.Item> */}
          </>
        )}

        {showPrice && (
          <div>
            <Form.Item
              name={"cashType"}
              label="Zahlungsart"
              rules={[{ required: true, message: "Pflichtfeld" }]}
            >
              <Radio.Group>
                <Radio value={CashType.Card}>Kartenzahlung</Radio>
                <Radio value={CashType.Cash}>Barzahlung</Radio>
                {/* <Radio value={CashType.Free}>Keine Zahlung (0,00 €)</Radio> */}
              </Radio.Group>
            </Form.Item>
          </div>
        )}
        {selectedCashType == "free" && (
          <>
            <Alert
              message={
                <div>
                  Eine Testung mit einem Preis von 0,00 € ist nur in
                  Ausnahmefällen möglich, zum Beispiel wenn ein Test-Personal
                  getestet wird. Diese Optiom nur nach Absprache mit ihrem
                  Vorgesetztem auswählen.
                </div>
              }
              type="error"
            />
            <div className="mt-2">
              <Form.Item
                label="Begründung"
                name="priceFreeReason"
                rules={[{ required: true, message: "Begründung (Pflicht)" }]}
              >
                <Input />
              </Form.Item>
            </div>
          </>
        )}

        {selectedTestTypeOption && (
          <div className="flex  space-x-2 ">
            <div className="flex-1">Offener Betrag:</div>
            <div className="text-xl font-bold">
              {selectedCashType == "free"
                ? formatCurrency(0)
                : formatCurrency(testPrice)}
            </div>
          </div>
        )}

        {showHintSelbstauskunft && (
          <Alert
            message={
              <div>
                Bitte die Selbstauskunft in gedruckter Form mit dem Kunden
                ausfüllen!
              </div>
            }
            type="error"
          />
        )}

        {signatureRequired && (
          <>
            <Divider plain>Selbstauskunft</Divider>

            <div className="grid grid-cols-2">
              <Form.Item
                label="Kunde Unterschrift"
                name="customerSignature"
                rules={[{ required: true, message: "Pflichtfeld" }]}
              >
                <SignaturePad
                  initialValue={testResult?.customerSignature}
                  onSave={(base64: any) => {
                    form.setFieldsValue({ customerSignature: base64 });
                    setFormValuesChanged(true);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Mitarbeiter Unterschrift"
                name="userSignature"
                rules={[{ required: true, message: "Pflichtfeld" }]}
              >
                <SignaturePad
                  initialValue={testResult?.userSignature}
                  onSave={(base64: any) => {
                    form.setFieldsValue({ userSignature: base64 });
                    setFormValuesChanged(true);
                  }}
                />
              </Form.Item>
            </div>
          </>
        )}

        {laborRequired && (
          <div>
            <Divider plain>Probenentnahme & Dokumente</Divider>

            <div>
              {!testResult?.specimenAt && (
                <div className="mb-2">
                  <Alert
                    message={
                      <div>
                        Sofern Sie die Probe für den PCR Test entnommen haben
                        und diese zur Abholung bereit steht, bestätigen Sie
                        dieses
                      </div>
                    }
                    type="warning"
                  />
                </div>
              )}
            </div>

            <div className="md:flex items-center">
              <div className="flex-1">
                <Form.Item
                  name="specimenAt"
                  label={
                    <div className="font-bold text-xl uppercase">
                      Probeentnahme Bestätigen
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Bitte bestätigen",
                      validator: (_, value) => {
                        if (value) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Bitte bestätigen");
                        }
                      },
                    },
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox>Probeentnahme bestätigt</Checkbox>
                </Form.Item>
              </div>

              {/* <div className="flex space-x-2">
                <Button
                  onClick={downloadPdf}
                  type="primary"
                  icon={<DownloadOutlined />}
                >
                  Laufzettel
                </Button>
                <Button type="primary" icon={<DownloadOutlined />}>
                  OEGD-Schein
                </Button>
              </div> */}
            </div>
            {testResult?.specimenAt && (
              <div className="mt-2">
                <Alert
                  type="info"
                  message={
                    <div>
                      Probeentnahme bestätigt am:{" "}
                      {formatDate(testResult?.specimenAt)}
                    </div>
                  }
                />
              </div>
            )}
          </div>
        )}
        <div>
          <Divider plain>Corona-Warn App</Divider>
          <CwaOptions />
        </div>

        {showTestResultInput == true && (
          <>
            <Divider plain>Testergebnis</Divider>

            <Form.Item
              name="pdfPasswordProtected"
              label="Attest Passwortschutz"
              rules={[{ required: true, message: "Pflichtfeld" }]}
              valuePropName="checked"
            >
              <Checkbox>Kunde wünscht Attest ohne Passwortschutz</Checkbox>
            </Form.Item>
            {selectedTestType?.laborRequired &&
              hasUserRight(
                RightsEnumType.LaborRequiredTestEnterResultRight
              ) && (
                <Form.Item
                  name="testResult"
                  label="Test Ergebnis"
                  rules={[{ required: laborRequired ? false : true }]}
                >
                  <ResultButtons
                    onSelect={() => {
                      setFormValuesChanged(true);
                    }}
                  />
                </Form.Item>
              )}

            {!selectedTestType?.laborRequired && (
              <Form.Item
                name="testResult"
                label="Test Ergebnis"
                rules={[{ required: laborRequired ? false : true }]}
              >
                <ResultButtons
                  onSelect={() => {
                    setFormValuesChanged(true);
                  }}
                />
              </Form.Item>
            )}

            {selectedTestType?.laborRequired &&
              !hasUserRight(
                RightsEnumType.LaborRequiredTestEnterResultRight
              ) && (
                <div className="font-bold">
                  Ergebnis Erfassung erfolgt automatisch über das zuständige
                  Labor
                </div>
              )}
          </>
        )}
        {/* <div>
          <Divider plain>Testergebnis Dokumente</Divider>

          {testResult && <DownloadReportButton testResult={testResult} />}
        </div> */}
        {showEdit && (
          <Form.Item>
            <Button
              disabled={!formValuesChanged}
              block
              className="mt-6"
              type="primary"
              htmlType="submit"
            >
              Speichern
            </Button>
          </Form.Item>
        )}

        {!showEdit && <div>Testbogen kann nicht verändert werden.</div>}
      </Form>
    </div>
  );
};
