import {
  Direction,
  useTestStaffChangedSubscription,
  useTestStaffsLazyQuery,
  useTestStaffCountLazyQuery,
  useUserGroupsLazyQuery,
  useUserGroupCountLazyQuery,
  useUserChangedSubscription,
} from "generated/schema";
import { useEffect, useState } from "react";
import { TestTable } from "./Table";
import { TableFilters } from "./TableFilters";

const TABLE_PAGE_SIZE = 12;

export const UserGroupTableWrapper = () => {
  const [currentTablePage, setCurrentPageTable] = useState(1);
  const [filters, setFilters] = useState<any>([]);

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "userGroup.id",
    direction: Direction.Desc,
  });

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const variables = { orderBy, take, skip, filters };

  const [getData, { data, loading }] = useUserGroupsLazyQuery({
    fetchPolicy: "network-only",
    variables,
  });
  const [getDataCount, { data: customerCountData, loading: l }] =
    useUserGroupCountLazyQuery({ fetchPolicy: "network-only", variables });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useUserChangedSubscription({});

  useEffect(() => {
    getData();
    getDataCount();
  }, [customerChangedData]);

  const resultData = data?.userGroups || [];

  return (
    <div>
      <div className="mb-4 mt-2">
        <TableFilters
          filters={filters}
          setFilters={(filtersArr: any) => {
            setCurrentPageTable(1);
            setFilters(filtersArr);
          }}
        />
      </div>

      <TestTable
        dataCount={customerCountData?.userGroupCount || 0}
        data={resultData}
        loading={loading}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
      />
    </div>
  );
};
