import { PlusIcon } from "@heroicons/react/solid";
import { Button, Radio } from "antd";
import { CreateTestResult } from "components/TestResult/CreateTestResult";
import {
  Direction,
  Filter,
  useTestResultChangedSubscription,
  useTestResultCountLazyQuery,
  useTestResultsLazyQuery,
  usePcrPlatesLazyQuery,
  usePcrPlateCountLazyQuery,
} from "generated/schema";
import { useEffect, useMemo, useState } from "react";
import { TestTableSearch } from "./Search";
import { PcrPlateTable } from "./Table";
import { useNavigate } from "react-router-dom";

import { CreateNewPlateButton } from "./../CreateNewPlateButton";
const TABLE_PAGE_SIZE = 15;
export const PcrPlateTableWrapper = () => {
  const [currentTablePage, setCurrentPageTable] = useState(1);
  const navigate = useNavigate();

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "pcrPlate.id",
    direction: Direction.Desc,
  });

  const [filters, setFilters] = useState<any>({});
  const [active, setActive]: any = useState("all");
  const [activeFilter, setActiveFilter]: any = useState("all");
  const [inEngine, setInEngine]: any = useState(false);
  const [inProgress, setInProgress]: any = useState(false);
  const [completed, setCompleted]: any = useState(false);

  const filtersVariable = useMemo(() => {
    const value: Filter[] = [];

    for (let fieldName in filters) {
      const term = filters[fieldName]?.[0];

      if (!term) continue;

      value.push({
        fieldName,
        term,
        exact: false,
      });
    }

    return value;
  }, [filters]);

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const activeVariable = active === true || active === false ? { active } : {};
  const inEngineVariable =
    inEngine === true || inEngine === false ? { inEngine } : {};
  const inProgressVariable =
    inProgress === true || inProgress === false ? { inProgress } : {};

    const  completedVariable =
    completed === true || completed === false ? { completed } : {};

  const variables = {
    orderBy,
    take,
    skip,
    ...activeVariable,
    ...inEngineVariable,
    ...inProgressVariable,
    ...completedVariable,
  };

  const [getTests, { data, loading }] = usePcrPlatesLazyQuery({
    fetchPolicy: "network-only",
    variables,
  });
  const [getTestCount, { data: testCountData, loading: l }] =
    usePcrPlateCountLazyQuery({
      fetchPolicy: "network-only",
      variables: { ...activeVariable, ...inEngineVariable, ...inProgressVariable, ...completedVariable },
    });
  // const {
  //   data: testResultChanged,
  //   loading: testResultChangedLoading,
  //   error: testResultChangedError,
  // } = useTestResultChangedSubscription({});

  // useEffect(() => {
  //   getTests();
  //   getTestCount();
  // }, [testResultChanged]);

  useEffect(() => {
    getTests();
    getTestCount();
  }, []);

  const resultData = data?.pcrPlates || [];

  return (
    <div>
      <div className="mb-4 grid grid-cols-1 md:grid-cols-2">
        <div className="w-full ">
          <Radio.Group
            onChange={(e) => {

              const v = e?.target?.value

              setActive(false)
              setInEngine(false)
              setInProgress(false)
              setCompleted(false)
              setActiveFilter(v)
              if(v == "all") {
                setActive(e?.target?.value);
              }

              if(v == "inProgress") {
                setInProgress(true)
              }

              if(v == "inEngine") {
                setInEngine(true)
              }

              if(v == "completed") {
                setCompleted(true)
              }
             
            }}
            value={activeFilter}
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button value="all">Alle</Radio.Button>
            <Radio.Button value={"inProgress"}>In Bestückung</Radio.Button>
            <Radio.Button value={"inEngine"}>Im Gerät</Radio.Button>
            <Radio.Button value={"completed"}>Abgeschloßen</Radio.Button>
          </Radio.Group>
        </div>

        <div className="flex  md:justify-end space-x-1">
          <div className="mt-2 md:mb-0">
            <CreateNewPlateButton />
          </div>

         
        </div>
      </div>

      <PcrPlateTable
        dataCount={testCountData?.pcrPlateCount || 0}
        data={resultData}
        loading={loading}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
      />
    </div>
  );
};
