import React from "react";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  EmojiHappyIcon,
  ExclamationIcon,
  BeakerIcon,
  ClockIcon,
  XCircleIcon,
  TruckIcon
} from "@heroicons/react/solid";
import { Button } from "styled";
import { Tooltip } from "antd";

import { useCountdown } from "hooks/useCountdown";
import { TestResult } from "generated/schema";
import formatDate from "utils/formatDate";

interface StartButtonProps {
  testResult: TestResult;
}

export const SpecimenPickedUpByCourierButton = (props: StartButtonProps) => {
  return (
    <div>
      <strong className="inline-flex items-center h-8 overflow-hidden text-white bg-purple-500 rounded">
        <span className="px-2  font-normal">
          <div className="flex w-36 justify-center items-center">
            <div className=""><div className="mr-2 ">Kurier Abgeholt</div></div>
            <Tooltip title= {formatDate(props?.testResult?.testFinishedAt)}>
              <div className="">
                {formatDate(props?.testResult?.testFinishedAt, true)}
              </div>
            </Tooltip>
          </div>
        </span>

        <button
          className="inline-flex items-center justify-center w-8 h-8 bg-purple-600 transition-color hover:bg-purple-700 focus:outline-none focus:ring"
          type="button"
        >
          <TruckIcon className="h-5 w-5 text-white" />
        </button>
      </strong>
    </div>
  );
};
