import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Image, Button, Form, Input, message, Upload } from "antd";

import React, { useRef, useState } from "react";
import {
  useUploadFileMutation,
  useCreateFilesUploadMutation,
  useUpdateFilesUploadMutation,
  FilesUpload,
} from "generated/schema";
import { resolve } from "path";

interface Props {
  onClose?: any;
  filesUpload?: FilesUpload;
}

export const FileUploadForm = ({ onClose, filesUpload }: Props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [form] = Form.useForm();

  const [uploadFileMutation, { data, loading, error }] =
    useUploadFileMutation();

  const [createFilesUploadMutation, filesReq] = useCreateFilesUploadMutation();
  const [updateFilesUploadMutation, filesUpdateReq] =
    useUpdateFilesUploadMutation();

  const filesLoading = filesReq?.loading || filesUpdateReq.loading;
  const filesData = filesReq?.data || filesUpdateReq?.data;

  const handleFileInput = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const onFinish = async (values: any) => {
    let res = null;

 
    try {
      if (selectedFile) {
        res = await uploadFileMutation({
          variables: { file: selectedFile },
        });
      }
      const uploaded = res?.data?.uploadFile?.uploaded;
      const uploadedFileName = res?.data?.uploadFile?.fileName;

      if(!uploaded && selectedFile) {
        console.log('error during uploading')
        return 
      }
      if (filesUpload) {
        await updateFilesUploadMutation({
          variables: {
            id: filesUpload?.id,
            input: {
              name: values?.name,
              fileName: uploadedFileName,
            },
          },
        });
      } else {
        await createFilesUploadMutation({
          variables: {
            input: {
              name: values?.name,
              fileName: uploadedFileName,
            },
          },
        });
      }
    } catch (e) {
      console.log(e);
    }

    onClose && onClose();
  };

  return (
    <Form
      initialValues={filesUpload}
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        name="logo"
        label="Logo"
        valuePropName="fileList"
        rules={[
          {
            required: filesUpload?.id ? false : true,
            validator: (_, value) => {
              if (filesUpload?.id && !value) return Promise.resolve();
              const file = value.target.files[0];
              const fileType = file?.type;

              const limit = 9 * (1000 * 1024);

              const fileSizeToBig = file?.size > limit;
              console.log("file?.size ", file?.size);

              if (fileSizeToBig) {
                return Promise.reject(
                  "Datei ist zu groß. Max. erlaubte Datengröße 9MB"
                );
              }

              console.log("file?.size ", file?.size);

              if (fileType == "image/png" || fileType == "image/jpeg") {
                return Promise.resolve();
              } else {
                form.setFieldValue("logo", null);
                return Promise.reject(
                  "Unterstütze Datenformate:  .png, .jpg, .jpeg"
                );
              }
            },
          },
        ]}
      >
        <input
          accept="image/png, image/gif, image/jpeg"
          type="file"
          onChange={(e) => handleFileInput(e)}
        />
      </Form.Item>

      <div>
        {filesUpload?.logoBase64 && (
          <Image
            width={100}
            preview={false}
            src={"data:image/png;base64," + filesUpload?.logoBase64}
          />
        )}
      </div>
      <Form.Item>
        <Button loading={loading} block type="primary" htmlType="submit">
          Speichern
        </Button>
      </Form.Item>
    </Form>
  );
};
