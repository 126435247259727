export function validatePassword(value: any): any {
  const textLength = value?.length;
  const containsOneDigit = /.*[0-9].*/.test(value);

  const containsSpecialCharacters =
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);

  const containsCapitalLetter = !![...value].find(
    (x: string) => x === x.toUpperCase()
  );

  const textLengthValid = textLength > 11;

  const isValid =
    textLengthValid &&
    containsOneDigit &&
    containsSpecialCharacters &&
    containsCapitalLetter;

  return {
    isValid,
    textLengthValid,
    containsOneDigit,
    containsSpecialCharacters,
    containsCapitalLetter,
  };

  return;
}
