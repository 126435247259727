import { Laboratory, TestCustomer, User } from "generated/schema";
import { LaboratoryFormForm } from "./Form";

import {
  useRolesQuery,
  useTestCentersQuery,
  useUserGroupsQuery,
} from "generated/schema";
import { Spin } from "antd";
interface Props {
  laboratory?: Laboratory;
  onClose?: any;
}

export const LaboratoryForm = ({ laboratory, onClose }: Props) => {
 

  return (
    <div key={laboratory?.id}>
      <LaboratoryFormForm
       
        onClose={onClose}
        laboratory={laboratory}
      />
    </div>
  );
};
