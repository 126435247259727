import { Button, Modal } from "antd";
import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { useCreateTestResultMutation } from "generated/schema";
export const CreateTestResult: React.FC = () => {
  const [createTestResultMutation, { data, loading, error }] =
    useCreateTestResultMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);

    createTestResultMutation({
      variables: {
        input: {
          name: "",
          testCenterId: 2,
          testTypeId: 2,
          testTypeOptionId: 5,
          customerId: 1,
          noEmail : true
        },
      },
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <Button loading={loading} type="primary" onClick={showModal}>
        <div className="flex items-center">
          <PlusIcon className="w-4 h-4" />
          Neuen Test anlegen
        </div>
      </Button> */}
      {/* <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal> */}
    </>
  );
};
