import { AppCtx } from "context/AppState";
import React, { useContext, useEffect } from "react";
import {
  useWorkTimeCurrentUserLazyQuery,
  useWorkTimeChangedSubscription,
} from "generated/schema";
import { Alert, Button, Typography } from "antd";
import { TimeElapsed } from "./TimeElapsed";
import { CheckInButton } from "./CheckInButton";
import { WorkTimeSummary } from "./Summary";
import { CheckOutButton } from "./CheckOutButton";
const { Title } = Typography;
export const WorkTime = () => {
  const context = useContext(AppCtx);

  const activeTestCenter = context?.activeTestCenter;

  const [getData, { data, loading, error }] = useWorkTimeCurrentUserLazyQuery({
    fetchPolicy: "network-only",
  });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useWorkTimeChangedSubscription({});

  console.log('data', data)

  useEffect(() => {
    getData();
  
  }, [customerChangedData]);

  if (!activeTestCenter) {
    return <></>;
  }

  const workTimeArr = data?.workTimeCurrentUser || [];

  let workTime = null;
  if (workTimeArr.length) {
    workTime = workTimeArr[0];
  }

  if (!workTime) {
    return (
      <div>
        <Title level={3}>Zeiterfassung</Title>
        <div className="-mt-2 mb-2">
          Bitte starten Sie hier ihre Zeiterfassung
        </div>

        <div className="mb-2">
          <Alert
            message={
              <div>
                Bitte beachten Sie, dass Sie sich bei Arbeitsende austechen.
                Falls Sie sich nicht auschecken werden ihre Stunden nicht
                berücksichtigt.
              </div>
            }
            type="warning"
          />
        </div>
        <CheckInButton />
      </div>
    );
  }

  return (
    <div className="">
      <Title level={3}>Zeiterfassung</Title>
      <div className="flex gap-4 items-center divide-x-2">
        <div className="flex-1">
          <CheckOutButton />
        </div>

        <div className="pl-4 flex-1">
          {workTime && <TimeElapsed workTime={workTime} />}
        </div>
      </div>
      <div className="mt-8">
        <Alert
          message={
            <div>
              Bitte beachten Sie, dass Sie sich bei Arbeitsende austechen in dem
              Sie hier auf "Auschecken" klicken. Falls Sie sich nicht auschecken
              werden ihre Stunden nicht berücksichtigt.
            </div>
          }
          type="warning"
        />
      </div>
      <div className="mt-8">
        {workTime && <WorkTimeSummary workTime={workTime} />}
      </div>
    </div>
  );
};
