import { Button, Popconfirm } from "antd";
import React, { useState } from "react";
import {
  useCreatePcrPlateMutation,
  useCompletePcrPlateMutation,
  PcrPlate,
} from "generated/schema";

interface Props {
  pcrPlate: PcrPlate;
  block?: boolean;
  text?: string;
}

export const CompletePlateButton = ({ pcrPlate, block, text }: Props) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [completePcrPlateMutation, { data, loading, error }] =
    useCompletePcrPlateMutation({});

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await completePcrPlateMutation({ variables: { id: pcrPlate?.id } });
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Sind Sie sich sicher?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button block={block} type="primary" onClick={showPopconfirm}>
        {(text && text) || "Befüllung Manuell beenden"}
      </Button>
    </Popconfirm>
  );
};
