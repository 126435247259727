import { DownOutlined } from "@ant-design/icons";
import { TableColumnsType, Tag } from "antd";
import { Badge, Dropdown, Menu, Space, Table } from "antd";
import React from "react";
import analyticsTableColumn from "./columns";
import { Typography } from "antd";
import formatCurrency from "utils/formatCurrency";
import useWindowSize from "hooks/useWindowSize";

const { Text } = Typography;

const getTotalCount = (testCenters: any) => {
  let totalTestCount = 0;
  let testsNegativeCount = 0;
  let testsPositiveCount = 0;
  let testsInvalidCount = 0;
  let oedgTotal = 0;
  let buergerTotal = 0;
  let schnellTestTotal = 0;
  let pcrTotal = 0;
  let totalCash = 0;
  let totalCard = 0;
  for (const entry of testCenters) {
    const totalTestCount1 = entry?.totalTestCount || 0;

    totalTestCount += totalTestCount1;

    const sumCard1 = entry?.sumCard || 0;
    const sumCash1 = entry?.sumCash || 0;
    const testsPositiveCount1 = entry?.sumPositive || 0;
    const testsNegativeCount1 = entry?.sumNegative || 0;
    const testsInvalidCount1 = entry?.sumInvalid || 0;
    const oedgTotal1 = entry?.testTypeId2?.count || 0;
    const pcrTotal1 = entry?.testTypeId3?.count || 0;
    const buergerTotal1 = entry?.testTypeId1?.count || 0;
    const schnellTestTotal1 = entry?.testTypeId4?.count || 0;

    testsNegativeCount += testsNegativeCount1;
    testsPositiveCount += testsPositiveCount1;
    oedgTotal += oedgTotal1;
    pcrTotal += pcrTotal1;
    buergerTotal += buergerTotal1;
    schnellTestTotal += schnellTestTotal1;
    testsInvalidCount += testsInvalidCount1;
    totalCash += sumCash1;
    totalCard += sumCard1;
  }

  // console.log("totalTestCount", totalTestCount);
  // console.log("testsNegativeCount", testsNegativeCount);
  // console.log("testsPositiveCount", testsPositiveCount);
  // console.log("oedgTotal", oedgTotal);
  // console.log("pcrTotal", pcrTotal);
  // console.log("buergerTotal", buergerTotal);
  // console.log("schnellTestTotal", schnellTestTotal);
  // console.log("testsInvalidCount", testsInvalidCount);
  // console.log("totalCard", totalCard);
  // console.log("totalCash", totalCash);

  return {
    totalTestCount,
    testsNegativeCount,
    testsPositiveCount,
    oedgTotal,
    pcrTotal,
    buergerTotal,
    schnellTestTotal,
    testsInvalidCount,
    totalCard,
    totalCash,
  };
};

const App = ({
  dataFromApi,
  loading,
  searchActive,
  testTypes,
  testTypeOptions,
}: any) => {
  const size = useWindowSize();

  const data: any[] = [];

  const dataFromApiClean = dataFromApi || [];
  const totals: any = {}; //dataFromApi?.totals;

  const totalCount = getTotalCount(dataFromApi);

  for (const entry of dataFromApiClean) {
    const testCenter = entry?.testCenterName;
    const testCenterId = entry?.testCenterId;

    const name = testCenter?.name;
    const totalTestCount = entry?.totalTestCount || 0;
    const testsPositiveCount = entry?.sumPositive || 0;
    const invalidTotal = entry?.sumInvalid || 0;
    const testsNegativeCount = entry?.sumNegative || 0;
    const oedgTotal = entry?.testTypeId2?.count || 0;
    const pcrTotal = entry?.testTypeId3?.count || 0;
    const buergerTotal = entry?.testTypeId1?.count || 0;
    const schnellTestTotal = entry?.testTypeId4?.count || 0;
    const sumWithoutResult = entry?.sumWithoutResult || 0;
    data.push({
      key: testCenterId.toString(),
      id: testCenterId,
      name: testCenter,
      total: totalTestCount,
      positiveTotal: testsPositiveCount,
      negativeTotal: testsNegativeCount,
      invalidTotal: invalidTotal,
      upgradeNum: 500,
      oedgTotal,
      pcrTotal,
      buergerTotal,
      schnellTestTotal,
      pcr3Total: entry?.pcrTest?.pcr3 || "-",
      pcr6Total: entry?.pcrTest?.pcr6 || "-",
      pcr12Total: entry?.pcrTest?.pcr12 || "-",
      pcr24Total: entry?.pcrTest?.pcr24 || "-",
      bar: entry?.sumCash || 0,
      card: entry?.sumCard || 0,

      createdAt: "2014-12-24 23:12:00",
    });
  }

  return (
    <>
      <div key={size?.height}>
        <Table
          loading={loading}
          columns={analyticsTableColumn}
          // expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
          dataSource={data}
          size="small"
          pagination={false}
          scroll={{ x: 1500, y: size ? size.height - 350 : 500 }}
          summary={() => {
            if (searchActive) {
              return <></>;
            }

            const {
              totalTestCount,
              testsNegativeCount,
              testsPositiveCount,
              oedgTotal,
              pcrTotal,
              buergerTotal,
              schnellTestTotal,
              testsInvalidCount,
              totalCard,
              totalCash,
            } = totalCount;

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    Standort Anzahl: <Tag>{dataFromApiClean?.length || 0}</Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Tag> {formatCurrency(totalCash)} </Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Tag> {formatCurrency(totalCard)} </Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Tag>{testsPositiveCount}</Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <Tag>{testsNegativeCount}</Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <Tag>{testsInvalidCount}</Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <Tag>{totalTestCount}</Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <Tag>{buergerTotal}</Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                    <Tag>{schnellTestTotal}</Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <Tag>{oedgTotal}</Tag>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>
                    <Tag>{pcrTotal}</Tag>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={12}></Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </div>
    </>
  );
};

export default App;
