import {
  TestCenter,
  TestResult,
  useTestCourierGroupsQuery,
} from "generated/schema";
import { Button, Card, Divider, Radio, Spin, Tag } from "antd";
import { CourierGroupSelect } from "./CourierGroupSelect";
import {
  useTestResultsLazyQuery,
  Direction,
  useTestResultChangedSubscription,
  useTestResultsWithoutTransportJobsLazyQuery,
} from "generated/schema";
import { useEffect, useState } from "react";
import { CourierTable } from "./Table";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import moment from "moment";
import formatDate from "./../../utils/formatDate";
import { TestTableSearch } from "components/TestTable/Search";
import {
  CourierListHeader,
  CourierListEntry,
  CourierListDataHandler,
  TextExpand,
} from "./CourierList";

import { OnGoingTransportJobs } from "./OngoingTransportJobs/index";
import { TransportJobStatesFilter } from "./TransportJobStatesFilter";

import { CreateTransportJobButton } from "./ReadyToPickUpTransportJobs/CreateTransportJobButton";
import { DeliveredTransportJobs } from "./DeliveredTransportJobs";
import { CourierTestDetailsButton } from "./CourierTestDetailsButton";
enum TransportJobState {
  ReadyToPickUp,
  InTransport,
  Delivered,
}

export const CourierPlan = () => {
  const getValue = () => {
    try {
      return localStorage.getItem("courierId") || 1;
    } catch (e) {
      console.log(e);
      return 1;
    }
  };

  const [courierGroupId, setCourierGroupId] = useState(getValue());
  const [transportStateStep, setTransportStateStep] = useState(
    TransportJobState.ReadyToPickUp
  );

  const [getTests, { data, loading }] =
    useTestResultsWithoutTransportJobsLazyQuery({
      fetchPolicy: "no-cache",
    });

  const {
    data: testResultChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useTestResultChangedSubscription({});

  useEffect(() => {
    const testResult: TestResult = testResultChangedData?.testResultChanged;

    const laborRequiredTest = testResult?.testType?.laborRequired;

    if (laborRequiredTest) {
      getTests({
        variables: {
          active: false,
          filters: [
            {
              fieldName: "testCenter.courierGroup.id",
              term: String(courierGroupId),
              exact: true,
            },
            { fieldName: "testType.laborRequired", term: "true", exact: true },
            {
              fieldName: "testResult.specimenAt",
              term: "not null",
              exact: true,
            },

            {
              fieldName: "testResult.transportJob",
              term: "null",
              exact: true,
            },
          ],
          orderBy: { fieldName: "testResult.id", direction: Direction.Asc },
        },
      });
    }
  }, [testResultChangedData]);

  useEffect(() => {
    getTests({
      variables: {
        active: false,
        filters: [
          {
            fieldName: "testCenter.courierGroup.id",
            term: String(courierGroupId),
            exact: true,
          },
          { fieldName: "testType.laborRequired", term: "true", exact: true },
          { fieldName: "testResult.specimenAt", term: "not null", exact: true },

          {
            fieldName: "testResult.transportJob",
            term: "null",
            exact: true,
          },
        ],
        orderBy: { fieldName: "testResult.id", direction: Direction.Asc },
      },
    });
  }, [courierGroupId]);

  const testCenters: any = {};

  data?.testResultsWithoutTransportJobs.forEach((x) => {
    if (x?.testCenter?.id) testCenters[x?.testCenter?.id] = x?.testCenter;
  });

  const testCentersArr = Object.values(testCenters).sort(
    (x: any, y: any) => x?.courierPickOrder - y?.courierPickOrder
  );

  return (
    <div>
      <div className="mb-4 grid grid-cols-1 md:grid-cols-1">
        <div className="w-full ">
          <CourierGroupSelect onChange={(v: any) => setCourierGroupId(v)} />
          <TransportJobStatesFilter
            defaultValue={transportStateStep}
            onChange={(v: any) => {
              setTransportStateStep(v);
            }}
          />
        </div>

        <div className="flex  md:justify-end space-x-1">
          <div className="mt-2 md:mb-0"></div>

          {/* <div className="w-full md:w-1/2  mt-2 md:mb-0 ">
            <TestTableSearch />
          </div> */}
        </div>
      </div>

      {transportStateStep == TransportJobState.InTransport && (
        <div key={courierGroupId}>
          <OnGoingTransportJobs courierGroupId={courierGroupId} />
        </div>
      )}

      {transportStateStep == TransportJobState.Delivered && (
        <DeliveredTransportJobs courierGroupId={courierGroupId} />
      )}

      {transportStateStep == TransportJobState.ReadyToPickUp && (
        <div>
          <CourierListHeader>
            Kurierplanung
            <div className="text-xs font-normal">
              Anzahl Transportjobs: {testCentersArr.length}
            </div>
          </CourierListHeader>

          <div>
            <CourierListDataHandler loading={false} data={testCentersArr} />
            {testCentersArr.map((x: any) => {
              const testCenter: TestCenter = x;

              const name = `${testCenter?.name}`;
              const contactPerson = testCenter?.contactPerson;
              const telStation = testCenter?.tel;
              const address = `${testCenter?.street}, ${testCenter?.zipCode} ${testCenter?.city}`;
              const openingTimes = testCenter?.openingTimes;

              const testResults = data?.testResultsWithoutTransportJobs?.filter(
                (testResult) => testResult?.testCenter?.id == testCenter?.id
              );

              return (
                <CourierListEntry>
                  <div className="font-bold"> {name} </div>

                  <TextExpand
                    show={openingTimes || contactPerson || telStation}
                    lastRow={
                      address && (
                        <div className="whitespace-pre-line">{address}</div>
                      )
                    }
                  >
                    <div className="whitespace-pre-line">{openingTimes}</div>

                    {contactPerson && (
                      <div className="whitespace-pre-line">
                        Ansprechpartner: {contactPerson}
                      </div>
                    )}
                    {telStation && (
                      <div className="whitespace-pre-line">
                        Tel. Station : {telStation}
                      </div>
                    )}
                  </TextExpand>

                  <CourierTestDetailsButton testResults={testResults} />

                  <CreateTransportJobButton
                    testCenter={testCenter}
                    testResults={testResults}
                  />
                </CourierListEntry>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
