import {
  BeakerIcon,
  MenuIcon,
  UsersIcon,
  LocationMarkerIcon,
  ChevronRightIcon,
  ChartSquareBarIcon,
  CogIcon,
  UserGroupIcon,
  UserCircleIcon,
  HomeIcon,
  ClipboardIcon,
  TruckIcon,
  ChartBarIcon,
  EyeIcon,
  ClockIcon,
  CalendarIcon,
} from "@heroicons/react/solid";

import { RightsMap } from "const/rights";
import { RightsEnumType } from "generated/schema";

export const menuDefinition = [
  {
    name: "Tests",
    icon: <BeakerIcon className="h-5 w-5" />,
    href: "/test-results",
    key: "/test-results",
    rightId: 1,
  },
  {
    name: "Kunden",
    icon: <UsersIcon className="h-5 w-5" />,
    href: "/customers",
    key: "/customers",
    rightId: 7,
  },

  {
    name: "Kurierplan",
    icon: <TruckIcon className="h-5 w-5" />,
    href: "/courier-transport",
    key: "/courier-transport",
    rightId: 4,
  },

  {
    name: "PCR-Labor",
    icon: <ClipboardIcon className="h-5 w-5" />,
    key: "/pcr-plates",
    href: "/pcr-plates",
    rightId: 5,
  },

  {
    name: "Analytics",
    icon: <ChartBarIcon className="h-5 w-5" />,
    key: "/analytics",
    href: "/analytics",
    rightId: 9,
  },

  // {
  //   name: "Zeiterfassung",
  //   icon: <ClockIcon className="h-5 w-5" />,
  //   key: "/time-tracking",
  //   href: "/time-tracking",
  //   rightId: RightsMap[RightsEnumType.TimeTrackingViewRight],
  // },

  {
    name: "Terminplaner",
    icon: <CalendarIcon className="h-5 w-5" />,
    key: "/openingTimes",
    href: "/openingTimes",
    rightId: 9,
  },

  {
    name: "Admin",
    icon: <CogIcon className="h-5 w-5" />,
    key: "/admin",
    rightId: 6,
    items: [
      {
        label: (
          <div className="flex space-x-2">
            <HomeIcon className="h-5 w-5 mr-2" /> Standorte
          </div>
        ),
        key: "/test-centers",
        href: "/test-centers",
      },
      {
        label: (
          <div className="flex space-x-2">
            <HomeIcon className="h-5 w-5 mr-2" /> PCR-Labore
          </div>
        ),
        key: "/laboratories",
        href: "/laboratories",
      },
      {
        label: (
          <div className="flex">
            <UsersIcon className="h-5 w-5 mr-2" /> Mitarbeiter
          </div>
        ),
        key: "/employees",
        href: "/employees",
      },

      {
        label: (
          <div className="flex space-x-2">
            <UserGroupIcon className="h-5 w-5 mr-2" /> Benutzergruppen
          </div>
        ),
        key: "/user-groups",
        href: "/user-groups",
      },

      {
        label: (
          <div className="flex space-x-2">
            <UserCircleIcon className="h-5 w-5 mr-2" /> Rollen
          </div>
        ),
        key: "/roles",
        href: "/roles",
      },

      {
        label: (
          <div className="flex space-x-2">
            <HomeIcon className="h-5 w-5 mr-2" /> Kurier-Gruppen
          </div>
        ),
        key: "/courier-groups",
        href: "/courier-groups",
      },

      {
        label: (
          <div className="flex space-x-2">
            <HomeIcon className="h-5 w-5 mr-2" /> Logo-Verwaltung
          </div>
        ),
        key: "/logos",
        href: "/logos",
      },
      {
        label: (
          <div className="flex space-x-2 border-t-2 py-2 border-black ">
            <ClockIcon className="h-5 w-5 mr-2" /> Zeit-Erfassung
          </div>
        ),
        key: "/time-tracking",
        href: "/time-tracking",
      },

      {
        label: (
          <div className="flex space-x-2">
            <EyeIcon className="h-5 w-5 mr-2" /> Log-Events
          </div>
        ),
        key: "/log-events",
        href: "/log-events",
      },

      // {
      //   label: (
      //     <div className="flex space-x-2">
      //       <HomeIcon className="h-5 w-5 mr-2" /> Einstellungen
      //     </div>
      //   ),
      //   key: "/settings",
      //   href: "/settings",
      // },
    ],
  },
];
