import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
  Radio,
  Alert,
} from "antd";

import styled from "styled-components";
import {
  IdentificationIcon,
  CalendarIcon,
  AtSymbolIcon,
  PhoneIcon,
  ClipboardListIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import {
  User,
  useUpdateTestCustomerMutation,
  useUpdateUserGroupMutation,
  useCreateUserGroupMutation,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  UserGroup,
  Role,
} from "generated/schema";

import { validatePassword } from "utils/validatePassword";
const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  role?: Role;

  onClose?: any;
}

export const RolesFormForm = ({ role, onClose }: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [form] = Form.useForm();
  const [createRole, { data, loading }] = useCreateRoleMutation();
  const [updateRole, updateReq] = useUpdateRoleMutation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    if (role?.id) {
      updateRole({ variables: { id: role.id, input: values } });
    } else {
      createRole({ variables: { input: values } });
    }

    success();
    onClose && onClose();
  };

  let initValues = {
    ...role,
  };

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
    >
      <Form.Item
        label="Rollenname"
        name="name"
        rules={[{ required: true, message: errorMessage }]}
      >
        <Input allowClear autoComplete="off" placeholder="Rollenname" />
      </Form.Item>

      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
