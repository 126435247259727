import { Select } from "antd";
import React from "react";
import { useOpeningTimesQuery } from "generated/schema";
import moment from "moment";
import { CreateNextCalenderWeek } from "../CreateCalenderWeek";

const { Option } = Select;

interface Props {
  onChange: any;
  testCenterId: any;
  calendarWeek: any;
}
export const CalenderWeekSelect = ({
  onChange,
  testCenterId,
  calendarWeek,
}: Props) => {
  const { data, loading, error } = useOpeningTimesQuery({
    fetchPolicy: "network-only",
    variables: { testCenterId },
  });

  const handleChange = (value: string) => {
    const v = (data?.openingTimes || []).find((x) => x?.id == parseInt(value));
    onChange(v);
  };

  if (data?.openingTimes?.length == 0) {
    return <></>;
  }

  return (
    <div className="w-full">
      <div>
        <Select
          allowClear
          placeholder="Kalenderwoche"
          loading={loading}
          style={{ width: "100%" }}
          onChange={handleChange}
          optionFilterProp="children"
          showSearch
          filterOption={(input, option) => {
            const v = (option?.children as unknown as string) || "";
            return String(v[0]).toLowerCase() == input.toLowerCase();
          }}
        >
          {data?.openingTimes
            ?.map((x) => {
              if (!x?.calendarWeek) return null;
              var weeknumber = moment().week(x?.calendarWeek);
              const startAt = weeknumber.startOf("week").format("DD.MM.YYYY");
              const endAt = weeknumber.endOf("week").format("DD.MM.YYYY");

              return (
                <Option value={x?.id}>
                  {x?.calendarWeek} - {startAt} - {endAt}
                </Option>
              );
            })
            .filter(Boolean)}
        </Select>
      </div>
    </div>
  );
};
