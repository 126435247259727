import { Alert, Button, Divider, Popconfirm } from "antd";
import { AppCtx } from "context/AppState";
import React, { useContext, useState } from "react";

import {
  TestResult,
  useCreateTestTransportJobMutation,
  useFinishTestTransportJobMutation,
  useDeleteTestResultMutation,
  useCreateLogEventMutation,
  useDeleteTestTransportJobMutation,
} from "generated/schema";
export const DeleteTransportJobButton = ({ onClose, transportJob }: any) => {
  const context = useContext(AppCtx);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const roleId = context?.user?.role?.id;

  const isAdmin = roleId == 1 || roleId == 2;
  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await deleteTransportJob({
      variables: { id: transportJob.id },
    });

    setOpen(false);
    setConfirmLoading(false);
    onClose && onClose();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const [deleteTransportJob, { data, loading, error }] =
    useDeleteTestTransportJobMutation({});

  if (!isAdmin) {
    return <></>;
  }
  return (
    <div className=" ml-2">
      <div className="">
        <Popconfirm
          title={"Sind Sie sich sicher?"}
          open={open}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button danger block type="primary" onClick={showPopconfirm}>
            Transportauftrag löschen
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};
