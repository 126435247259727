import { TestCustomer, User, UserGroup } from "generated/schema";
import { UserGroupFormForm } from "./Form";

import {
  useRolesQuery,
  useTestCentersQuery,
  useUserGroupsQuery,
} from "generated/schema";
import { Spin } from "antd";
interface Props {
  userGroup?: UserGroup;
  onClose?: any;
}

export const UserGroupForm = ({ userGroup, onClose }: Props) => {
  return (
    <div key={userGroup?.id}>
      <UserGroupFormForm onClose={onClose} userGroup={userGroup} />
    </div>
  );
};
