import React, { useState } from "react";
import {
  BeakerIcon,
  MenuIcon,
  UsersIcon,
  LogoutIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
} from "@heroicons/react/solid";
import { Avatar, Grid } from "antd";
import { Link, useLocation } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { LoggedInUser } from "./LoggedInUser";
import MenuNav from "./Menu";
//import { GlobalTestCenterSelect } from "components/GlobalTestCenterSelect";
import { menuDefinition } from "./menuDef";
const { useBreakpoint } = Grid;

const MenuRow = ({ Icon, label, active, href, onClick }: any) => {
  const { pathname } = useLocation();

  return (
    <Link onClick={onClick} style={{ color: "black" }} to={href || "/"}>
      <div
        className={`flex  space-x-4 items-center  font-medium  cursor-pointer hover:bg-gray-100 p-2 rounded ${
          pathname == href ? "bg-gray-100" : ""
        }`}
      >
        {/* <div>{Icon && Icon}</div> */}
        <div>{label}</div>
      </div>
    </Link>
  );
};
export function NavTab({ breadCrumbLocation }: any) {
  const [menuOpen, setMenuOpen] = useState(false);
  const screens = useBreakpoint();

  if (screens.md || true) {
    return (
      <div className="w-full ">
        <div className="flex">
          {screens.xs == false && (
            <div className="w-[260px]  min-w-[260px]">
              <img style={{ height: 50 }} src="/tcLogo.png" alt="" />
            </div>
          )}
          {screens.xs == true && (
            <div className="w-[60px]  min-w-[40px]">
              <img style={{ height: 50 }} src="/tcLogoXs.png" alt="" />
            </div>
          )}
          <div className="flex-1  flex justify-center overflow-x-auto mr-6">
            <MenuNav />
          </div>

          <div className="flex justify-end  items-center">
            <LoggedInUser />
          </div>
        </div>
      </div>
    );
  }
}
