import { Button, Checkbox, Divider, Modal } from "antd";
import {
  useTestResultsGroupedLazyQuery,
  useTestResultsGroupedSingleDayLazyQuery,
} from "generated/schema";
import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { read, utils, writeFile } from "xlsx";
import _flatten from "lodash/flatten";

import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import React from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export const ExportExcelAnalytics = ({
  filters,
  tableData,
  onOnlyQuickTestChange,
  loading: exportDataLoading,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [onlyQuickTest, setOnlyQuickTest] = useState(false);

  const startDayInit = moment().utc().startOf("day").format();
  const startDayMoment = moment().utc().startOf("day");

  const [startDay, setStartDay] = useState(startDayInit);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    onOnlyQuickTestChange(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    onOnlyQuickTestChange(false);
  };

  const [getData, { data, loading, error }] =
    useTestResultsGroupedSingleDayLazyQuery({
      fetchPolicy: "network-only",
    });

  const getEmptyRow = () => {
    if (!onlyQuickTest) {
      return [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ];
    }

    return ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
  };

  const normalizeData = (result: any, singleDay: any) => {
    return result.map((x: any) => {
      const name = x?.testCenterName;
      const sumCard = x?.sumCard || 0;
      const sumCash = x?.sumCash || 0;
      const sumInvalid = x?.sumInvalid || 0;
      const sumNegative = x?.sumNegative || 0;
      const sumPositive = x?.sumPositive || 0;
      const totalTestCount = x?.totalTestCount || 0;
      const testCenterId = x?.testCenterId || 0;
      const sumWithoutResult = x?.sumWithoutResult || 0;
      const testType1 = x?.testTypeId1?.count || 0; // "Bürgertest"
      const testType2 = x?.testTypeId2?.count || 0; // PCR-OEGD
      const testType3 = x?.testTypeId3?.count || 0; // "PCR"
      const testType4 = x?.testTypeId4?.count || 0; // "Schnelltest"
      const testType5 = x?.testTypeId5?.count || 0;
      const testType6 = x?.testTypeId6?.count || 0;
      const testType7 = x?.testTypeId7?.count || 0;

      const testTypeOptionId1 = x?.testTypeOptionId1?.count || 0; // PCR-12 Stunde
      const testTypeOptionId2 = x?.testTypeOptionId2?.count || 0; // PCR-24 Stunde
      const testTypeOptionId3 = x?.testTypeOptionId3?.count || 0; // Schnelltest Eigenmotivation
      const testTypeOptionId13 = x?.testTypeOptionId13?.count || 0; // Personal-Testung

      const testTypeOptionId4 = x?.testTypeOptionId4?.count || 0; // OEGD - Test nach § 2 TestV Kontaktperson / CWA"
      const testTypeOptionId5 = x?.testTypeOptionId5?.count || 0; // "Test nach § 3 TestV Ausbruchsgeschehen"
      const testTypeOptionId6 = x?.testTypeOptionId6?.count || 0; // "Test nach § 4 Abs. 1 Nr. 1 und 2 TestV Verhütung der Verbreitung"
      const testTypeOptionId7 = x?.testTypeOptionId7?.count || 0; // "Bestätigungs-PCR nach §4 Satz 1 TestV nach positivem Antigentest / PCR-Pooling-Test"
      const testTypeOptionId8 = x?.testTypeOptionId8?.count || 0; // "Varianten-PCR nach §4b Satz 3 TestV nach positivem PCR-Test"

      const testTypeOptionId9 = x?.testTypeOptionId9?.count || 0; //  BT "Positiv getestete zur Beendigung der Isolation (Freitestung)"
      const testTypeOptionId10 = x?.testTypeOptionId10?.count || 0; // BT "Besucher, Pflegende, Bewohner, Patienten, Klienten in Pflege- und Sozialeinrichtungen"
      const testTypeOptionId11 = x?.testTypeOptionId11?.count || 0; // BT "Pflegende Angehörige"
      const testTypeOptionId12 = x?.testTypeOptionId12?.count || 0; //  BT "Leistungserbringer im Rahmen eines Persönlichen Budgets und bei ihnen Beschäftigte"

      const countPositiveTestType9 =
        x?.positiveByReasons[x.testTypeOptionId9?.id] || 0;
      const countPositiveTestType10 =
        x?.positiveByReasons[x.testTypeOptionId10?.id] || 0;
      const countPositiveTestType11 =
        x?.positiveByReasons[x.testTypeOptionId11?.id] || 0;
      const countPositiveTestType12 =
        x?.positiveByReasons[x.testTypeOptionId12?.id] || 0;
      const countPositiveTestType3 =
        x?.positiveByReasons[x.testTypeOptionId3?.id] || 0;
      const countPositiveTestType13 =
        x?.positiveByReasons[x.testTypeOptionId13?.id] || 0;

      const row: any = [
        { 0: testCenterId },
        { 1: name },
        { 2: sumCard },
        { 3: sumCash },
        { 4: sumPositive },
        { 5: sumNegative },
        { 6: sumInvalid },
        { 7: sumWithoutResult },
        { 8: totalTestCount },
        { 9: testType1 },
        { 11: testTypeOptionId9 },
        { 111: countPositiveTestType9 },
        { 12: testTypeOptionId10 },
        { 112: countPositiveTestType10 },

        { 13: testTypeOptionId11 },
        { 113: countPositiveTestType11 },

        { 14: testTypeOptionId12 },
        { 114: countPositiveTestType12 },
        { 15: testType4 },
        { 16: testTypeOptionId3 },
        { 116: countPositiveTestType3 },
        { 17: testTypeOptionId13 },
        { 117: countPositiveTestType13 },
      ];

      if (!onlyQuickTest) {
        const fields = [
          { 17: testType2 },
          { 18: testTypeOptionId4 },
          { 19: testTypeOptionId5 },
          { 20: testTypeOptionId6 },
          { 21: testTypeOptionId7 },
          { 22: testTypeOptionId8 },
          { 23: testType3 },
          { 24: testTypeOptionId1 },
          { 25: testTypeOptionId2 },
        ];

        for (const field of fields) {
          row.push(field);
        }
      }

      let excelRow = row.map((x: any, i: any) => {
        const r = Object.values(x)[0];
        return r;
      });

      if (singleDay) {
        excelRow = ["", ...excelRow];
      }

      return excelRow;
    });
  };

  const headers: any = [];
  const handleExport = async (singleDay: any) => {
    const fileName = !singleDay
      ? "Report-All"
      : "Report-" + moment(startDay).format("MM.YYYY");

    let cols: any = [
      { 0: "ID" },
      { 1: "Testcenter Name" },
      { 2: "Karte (EUR)" },
      { 3: "Bar (EUR)" },
      { 4: "Positiv" },
      { 5: "Negativ" },
      { 6: "Ungültig" },
      { 7: "Ohne Ergebnis" },
      { 8: "Total" },
      { 9: "Bürgertest" },
      { 11: "Positiv getestete zur Beendigung der Isolation (Freitestung)" },
      { 111: "P" },
      {
        12: "Besucher, Pflegende, Bewohner, Patienten, Klienten in Pflege- und Sozialeinrichtungen",
      },
      { 112: "P" },

      { 13: "Pflegende Angehörige" },
      { 113: "P" },

      {
        14: "Leistungserbringer im Rahmen eines Persönlichen Budgets und bei ihnen Beschäftigte",
      },
      { 114: "Anzahl Positiv" },

      { 15: "Schnelltest" },
      { 16: "Schnelltest Eigenmotivation" },
      { 116: "P" },
      { 17: "Personal-Testung" },
      { 117: "P" },
    ];

    if (!onlyQuickTest) {
      const fields = [
        { 17: "OEGD-Test" },
        { 18: "Test nach § 2 TestV Kontaktperson / CWA" },
        {
          19: "Test nach § 4 Abs. 1 Nr. 1 und 2 TestV Verhütung der Verbreitung",
        },
        {
          20: "Bestätigungs-PCR nach §4 Satz 1 TestV nach positivem Antigentest / PCR-Pooling-Test",
        },
        { 21: "Varianten-PCR nach §4b Satz 3 TestV nach positivem PCR-Test" },

        { 22: "Positiv getestete zur Beendigung der Isolation (Freitestung)" },
        { 23: "PCR-Test" },
        { 24: "PCR-12 Stunden" },
        { 25: "PCR-24 Stunden" },
      ];

      for (const field of fields) {
        cols.push(field);
      }
    }
    let tableCols = cols.map((x: any, i: any) => {
      const r = Object.values(x)[0];
      return r;
    });

    if (singleDay) {
      tableCols = ["Datum", ...tableCols];
    }
    const headings = [tableCols];

    let yo: any = normalizeData(tableData?.result || [], false);

    if (singleDay) {
      let totalTestCount = 0;
      const allFilters = filters ? filters : [];
      const data = await getData({
        variables: { startDay: startDay, filters: allFilters, onlyQuickTest },
      });

      const dataFromApi = data?.data?.testResultsGroupedSingleDay;

      const rows = [];
      const days = (dataFromApi && Object.keys(dataFromApi)) || [];
      const results: any = dataFromApi;
      let counter = 0;
      for (const key of days) {
        const result = results[key];
        counter++;
        const dayResult = result?.result || [];

        const totalTests = dayResult.reduce(function (
          accumulator: any,
          row: any
        ) {
          return accumulator + row.totalTestCount;
        },
        0);

        totalTestCount += totalTests;

        const dateRow: any = getEmptyRow();
        const emptyRow = getEmptyRow();

        dateRow[0] = moment(key).format("DD.MM.YYYY");
        let dayData = normalizeData(dayResult, true);

        if (dayData.length) {
          dayData = [emptyRow, dateRow, ...dayData];
        } else {
          dayData = [
            emptyRow,
            dateRow,
            emptyRow.map((x, i) => (i > 0 ? 0 : "")),
          ];
        }

        const final = dayData;

        if (days.length == counter) {
          const blankRow: any = getEmptyRow();

          blankRow[0] = "Summe Tests";
          blankRow[1] = totalTestCount;
          final.push(blankRow);
        }

        rows.push(final);
      }

      yo = _flatten(rows);
    }

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(yo);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, [], { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, fileName);
    writeFile(wb, fileName + ".xlsx");
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    const s = moment(date).startOf("month").format();

    setStartDay(s);
  };

  const onChangeOnlyQuickTest = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    setOnlyQuickTest(checked);
    onOnlyQuickTestChange(checked);
  };

  return (
    <div>
      <Button
        onClick={() => {
          setIsModalOpen(true);
        }}
        size="small"
        type="primary"
      >
        Excel Export
      </Button>

      <Modal
        // title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="my-2 font-bold">
          <Checkbox onChange={onChangeOnlyQuickTest}>Nur Antigen-Test</Checkbox>
        </div>

        <Divider plain>Exportiere die aktuelle Tabelle</Divider>
        <p>
          Die Tabelle wird mit allen Filtern, soweit angewandt, als EXCEL Datei
          exportiert.
        </p>

        <Button
          block
          loading={exportDataLoading}
          onClick={() => {
            handleExport(false);
          }}
          type="primary"
        >
          Export
        </Button>

        <div className="mt-4">
          <Divider plain>Monats-Report auf Tagesbasis</Divider>
          <p>
            Alle Testzentren die Tests durchgeführt haben, Reporting auf
            Tagesbasis, pro Monat.
          </p>
          <p>Monatsauswahl</p>

          <div className="mb-2">
            <DatePicker
              defaultValue={startDayMoment}
              style={{ width: "100%" }}
              onChange={onChange}
              picker="month"
            />
          </div>

          <Button
            block
            loading={loading}
            onClick={() => {
              handleExport(true);
            }}
            type="primary"
          >
            Export
          </Button>
        </div>
      </Modal>
    </div>
  );
};
