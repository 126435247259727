import { Button } from "antd";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { ExportCsvButton } from "components/ExportCsvButton";
import { MyDrawer } from "components/MyDrawer";
import {
  Direction,
  useTestCustomerChangedSubscription,
  useTestCenterCountLazyQuery,
  useTestCustomerCountLazyQuery,
  useTestResultsLazyQuery,
  useTestCustomersLazyQuery,
  useWorkTimesLazyQuery,
  useWorkTimeChangedSubscription,
  useWorkTimeCountLazyQuery,
  useWorkTimesGroupedLazyQuery,
} from "generated/schema";
import moment from "moment";
import { useEffect, useState } from "react";
import { TestTable } from "./Table";
import { TableFilters } from "./TableFilters";

const TABLE_PAGE_SIZE = 12;

export const WorkTimeTableMonthWrapper = () => {
  const [currentTablePage, setCurrentPageTable] = useState(1);
  const [filters, setFilters] = useState<any>([]);
  const [showTableFilters, setShowTableFilters] = useState(true);

  const m = moment().startOf("month").format();
  const [startDate, setStartDate] = useState(m);

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "workTime.id",
    direction: Direction.Desc,
  });

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const [getData, { data, loading }] = useWorkTimesGroupedLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getDataCount, { data: customerCountData, loading: l }] =
    useWorkTimeCountLazyQuery({
      fetchPolicy: "network-only",
    });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useWorkTimeChangedSubscription({});

  useEffect(() => {
    getData({ variables: { orderBy, take, skip, startDate, filters } });
    getDataCount();
  }, [customerChangedData, startDate, filters]);

  let resultData =
    (data?.workTimesGrouped && Object.values(data?.workTimesGrouped)) || [];

  for (const filter of filters) {
    const fn = filter.fieldName;
    const value = filter.term;
    if (filter.fieldName == "user.name" && filter.term != "") {
      resultData = resultData.filter((x: any) => {
        return x.user.toLowerCase().includes(value.toLowerCase());
      });
    }
  }

  return (
    <div>
      <div className="flex  md:justify-end mb-2">
        <ExportCsvButton
          fileName={"Stundenzettel-" + moment(startDate).format("MM.YYYY")}
          headers={[
            { name: "Monat", value: "month" },

            { name: "Id", value: "userId" },
            { name: "Personal-Nr", value: "workerId" },
            { name: "Name", value: "user" },
            {
              name: "Stunden",
              value: "hours",
              transform: (v: any) => parseFloat(v?.toFixed(2)),
            },
          ]}
          data={resultData}
        />
        {/* <MyDrawer
          title={"Neuen Kunden Anlegen"}
          triggerElement={<Button type="primary">Neuen Kunden anlegen</Button>}
        >
          <TestCustomerForm />
        </MyDrawer> */}
      </div>
      <div className="flex space-x-2">
        <div className="flex-1 overflow-auto ">
          {showTableFilters && (
            <TableFilters
              onChangeDatePicker={(date: any) => {
                const m = moment(date).startOf("month").format();

                setStartDate(m);
              }}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </div>
      </div>
      <TestTable
        dataCount={resultData?.length || 0}
        data={resultData}
        loading={loading}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
      />
    </div>
  );
};
