import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { TestCustomer, UserGroup } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { TableTestStateButton } from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { UserGroupForm } from "./UserGroupForm";

export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: 50,
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "name",

    render: (text: string, row: TestCustomer) => {
      const name = text || "-";
      return <div>{name}</div>;
    },
  },

  {
    title: "Vita1-Station",
    dataIndex: "vita1",
    key: "name",

    render: (text: string, row: UserGroup) => {
      if (!text) {
        return <Tag color="red">Nein</Tag>;
      }
      return <Tag color="green">Ja</Tag>;
    },
  },

  {
    title: "Action",
    key: "action",
    render: (_, row: UserGroup) => {
      const id = row?.id;
      const name = row?.name;
      // const customerFirstName = row?.firstName || "";
      // const customerLastName = row?.lastName || "";
      const title = `Editiere Benutzergruppe (${id}): ${name} `;
      return (
        <Space size="middle">
          <Space size="middle">
            <MyDrawer
              title={title}
              triggerElement={
                <Button size="small" type="primary">
                  Edit
                </Button>
              }
            >
              <UserGroupForm userGroup={row} />
            </MyDrawer>
          </Space>
        </Space>
      );
    },
  },
];
