import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Button, Radio } from "antd";
import { MyDrawer } from "components/MyDrawer";
import {
  Direction,
  useLogEventsLazyQuery,
  useLogEventCountLazyQuery,
  useLogEventChangedSubscription,
  Filter,
} from "generated/schema";
import { useEffect, useState } from "react";
import { TestTable } from "./Table";
import { TableFilters } from "./TableFilters";

const TABLE_PAGE_SIZE = 12;
interface Props {
  fixFilters?: Filter[];
}
export const LogTableWrapper = ({ fixFilters }: Props) => {
  const [currentTablePage, setCurrentPageTable] = useState(1);
  const [filters, setFilters] = useState<any>(fixFilters || []);
  const [showTableFilters, setShowTableFilters] = useState(true);

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "logEvent.id",
    direction: Direction.Desc,
  });

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const [getData, { data, loading }] = useLogEventsLazyQuery({
    fetchPolicy: "no-cache",
    variables: { orderBy, take, skip, filters },
  });
  const [getDataCount, { data: customerCountData, loading: l }] =
    useLogEventCountLazyQuery({
      variables: { filters },
      fetchPolicy: "no-cache",
    });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useLogEventChangedSubscription({});

  useEffect(() => {
    getData();
    getDataCount();
  }, [customerChangedData]);

  const resultData = data?.logEvents || [];

  return (
    <div>
      <div className="flex  md:justify-end mb-2"></div>
      <div className="flex space-x-2">
        <div className="flex-1 overflow-auto ">
          {showTableFilters && (
            <TableFilters
              filters={filters}
              setFilters={(currentFilters: any) => {
                setCurrentPageTable(1);
                setFilters(currentFilters);
              }}
            />
          )}
        </div>
      </div>

      <TestTable
        dataCount={customerCountData?.logEventCount || 0}
        data={resultData}
        //loading={loading}
        loading={false}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
      />
    </div>
  );
};
