import { TestCustomer, WorkTime } from "generated/schema";
import { WorkTimeForm } from './WorktimeForm';
interface Props {
  workTime?: WorkTime;
  onClose?: any;
}

export const WorkTimeFormWrapper = (props: Props) => {
  return (
    <div key={props?.workTime?.id}>
      <div>
        <WorkTimeForm onClose={props.onClose} workTime={props.workTime} />
      </div>
    </div>
  );
};
