import { Select } from "antd";

const { Option } = Select;


interface Props {
  onChange : any
}
export const TestResultSelect = ({onChange} : Props) => {

  const handleChange = (value: string) => {

    if (!value) {
      onChange({
        fieldName: "testResult.result.id",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "testResult.result.id",
      term: String(value),
      exact: true,
    };


    onChange(filter);
  };

  return (
    <>
      <Select
        allowClear
        placeholder="Testergebnis"
        style={{ width: 200 }}
        onChange={handleChange}
      >
        <Option value={1}>Positiv</Option>
        <Option value={2}>Negativ</Option>
        <Option value={3}>Ungültig</Option>
      </Select>
    </>
  );
};
