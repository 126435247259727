import { Select } from "antd";
import React from "react";
import { useTestCentersQuery } from "generated/schema";
const { Option } = Select;

interface Props {
  onChange: any;
}
export const TestCenterSelect = ({ onChange }: Props) => {
  const { data, loading, error } = useTestCentersQuery({
    fetchPolicy: "network-only",
  });

  const handleChange = (value: string) => {
    if (!value) {
      onChange({
        fieldName: "workTime.testCenter.id",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "workTime.testCenter.id",
      term: String(value),
      exact: true,
    };

    onChange(filter);
  };

  return (
    <>
      <Select
        allowClear
        placeholder="Standort"
        loading={loading}
        style={{ minWidth: 200 }}
        onChange={handleChange}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => {
          return ((option?.children as unknown as string) || "")
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      >
        {data?.testCenters?.map((x) => {
          return <Option value={x?.id}>{x?.name}</Option>;
        })}
      </Select>
    </>
  );
};
