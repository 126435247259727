import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { Laboratory, TestCustomer, User } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { TableTestStateButton } from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { LaboratoryForm } from "./LaboratoryForm";
export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: 50,
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (text: string, row: Laboratory) => {
      const name = `${row?.name}`;
      return <div>{name}</div>;
    },
  },

  {
    title: "Leiter",
    dataIndex: "leaderName",
    key: "name",
    width: 200,
    render: (text: string, row: Laboratory) => {
      const name = `${row?.leaderName}`;
      return <div>{name}</div>;
    },
  },

  {
    title: "Straße",
    dataIndex: "street",
    key: "email",
    width: 250,
    render: (text: any, row: Laboratory) => {
      const street = `${row?.street || "-"} `;

      return <div>{street}</div>;
    },
  },
  {
    title: "PLZ",
    dataIndex: "zipCode",
    key: "role",
    width: 120,
    render: (text: any, row: Laboratory) => {
      const zipCode = row?.zipCode || "-";
      return <div>{zipCode}</div>;
    },
  },

  {
    title: "Stadt",
    dataIndex: "city",
    key: "city",
    width: 120,
    render: (text: any, row: Laboratory) => {
      const city = row?.city || "-";
      return <div>{city}</div>;
    },
  },

 
  {
    title: "Logo",
    dataIndex: "logo",
    key: "active",
    width: 200,
    render: (text: any, row: Laboratory) => {
      const logo = row?.logo?.name || "-"
      return <div>{logo}</div>;
    },
  },

  {
    title: "Standard Labor",
    dataIndex: "default",
    key: "active",
    width: 200,
    render: (text: any, row: Laboratory) => {
      const logo = row?.default
      return <div>{logo ? "Ja" : "Nein"}</div>;
    },
  },


  {
    title: "Action",
    key: "action",
    render: (_, row: Laboratory) => {
      const id = row?.id;
      const customerFirstName = row?.name || "";
      const title = `Editiere PCR-Labor: (${id}) ${customerFirstName} `;

      return (
        <Space size="middle">
          <Space size="middle">
            <MyDrawer
              title={title}
              triggerElement={
                <Button size="small" type="primary">
                  Edit
                </Button>
              }
            >
              <LaboratoryForm laboratory={row} />
            </MyDrawer>
          </Space>
        </Space>
      );
    },
  },
];
