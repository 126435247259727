import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
  Radio,
  Alert,
} from "antd";

import styled from "styled-components";
import {
  IdentificationIcon,
  CalendarIcon,
  AtSymbolIcon,
  PhoneIcon,
  ClipboardListIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import {
  User,
  useUpdateTestCustomerMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  useCreateLaboratoryMutation,
  useUpdateLaboratoryMutation,
  UserGroup,
  Role,
  useFilesUploadsQuery,
  FilesUpload,
  Laboratory,
} from "generated/schema";

import { validatePassword } from "utils/validatePassword";
const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  laboratory?: Laboratory;

  onClose?: any;
}

export const LaboratoryFormForm = ({ laboratory, onClose }: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [form] = Form.useForm();
  const [create, { data, loading }] = useCreateLaboratoryMutation();
  const [update, updateReq] = useUpdateLaboratoryMutation();

  const reqFileUploads = useFilesUploadsQuery({ fetchPolicy: "network-only" });
  const uploadedFilesData = reqFileUploads?.data?.filesUploads || [];

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    if (laboratory?.id) {
      update({ variables: { id: laboratory.id, input: values } });
    } else {
      create({ variables: { input: values } });
    }

    success();
    onClose && onClose();
  };

  let initValues = {
    logoId: laboratory?.logo?.id,

    ...laboratory,
  };

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
      // labelCol={{ span: 10 }}
      // wrapperCol={{ span: 14  }}
    >
      <div className="sm:grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <div>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="PCR-Labor Name" />
          </Form.Item>

          <Form.Item
            name="leaderName"
            label="Leiter"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Leiter Name" />
          </Form.Item>

          <Form.Item
            name="street"
            label="Straße"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Straße" />
          </Form.Item>

          <Form.Item
            name="zipCode"
            label="Postleitzahl"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Postleitzahl" />
          </Form.Item>

          <Form.Item
            name="city"
            label="Stadt"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Stadt" />
          </Form.Item>

          <Form.Item
            name="tel"
            label="Telefon"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Tel. Nr" />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="logoId"
            label="Logo"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Select loading={reqFileUploads?.loading} allowClear>
              {uploadedFilesData.map((x: FilesUpload) => {
                return <Option value={x?.id}>{x?.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="freeText"
            label="Freitext"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input.TextArea placeholder="Freitext" />
          </Form.Item>
          <Form.Item name="default" label="Standard Labor">
            <Radio.Group>
              <Radio value={true}> Ja </Radio>
              <Radio value={false}> Nein </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
