import React, { useState } from "react";
import {
  TestResult,
  useTestResultPdfLazyQuery,
  useSendTestResultEmailAgainMutation,
} from "generated/schema";
import { Alert, Button, Dropdown, Menu, Popover } from "antd";
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";

import { MoreOutlined } from "@ant-design/icons";
import useToken from "hooks/useJwtToken";
import formatDate from "utils/formatDate";
import ReactJson from "react-json-view";
import { DemisAgainReport } from "./DemisAgainReport";

interface Props {
  testResult: TestResult;
}
export const DownloadDemisReportButton = ({ testResult }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const tokenString = localStorage.getItem("jwtToken");

  // return the headers to the context so httpLink can read them
  const authHeader = tokenString ? `Bearer ${tokenString}` : "";

  const getData = async (redo: boolean) => {
    let redoString = redo ? "/redo" : "";
    setIsLoading(true);

    const fileName = testResult?.demisReport?.fileName;
    const response = await fetch(
      "/api/v1/documents/demis/file/pdf/" + fileName,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          authorization: authHeader,
        },
      }
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      setIsLoading(false);
      throw new Error(message);
    }
    const pdfBlob = await response.blob();

    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([pdfBlob]));
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    setIsLoading(false);
  };

  if (testResult?.demisReport?.errors) {
    return (
      <>
        <Alert
          message={"Keine Rückmeldung möglich, Daten Überprüfen"}
          type="error"
        />

        <div className="grid grid-cols-2 mb-2">
          <div>gemeldet am:</div>
          <div>{formatDate(testResult?.demisReport?.errorAt)}</div>
        </div>

        <div className="mt-2">
          <ReactJson src={JSON.parse(testResult?.demisReport?.errors)} />
        </div>
      </>
    );
  }

  if (!testResult?.demisReport?.completedAt) {
    return <div>Test wird noch zurückgemeldet</div>;
  }
  return (
    <div className="">
      <div className="grid grid-cols-2 mb-2">
        <div>gemeldet am:</div>
        <div>{formatDate(testResult?.demisReport?.completedAt)}</div>
      </div>

      {testResult?.demisReport?.name && (
        <div className="grid grid-cols-2 mb-2">
          <div>Meldungs-ID:</div>
          <div>{testResult?.demisReport?.name}</div>
        </div>
      )}
      <Button
        block
        // disabled={!show}
        loading={isLoading}
        onClick={() => getData(false)}
        type="primary"
        icon={<DownloadOutlined />}
      >
        Demis-Report
      </Button>

      <DemisAgainReport testResult={testResult} />
    </div>
  );
};
