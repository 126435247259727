import React, { useState } from "react";

import styled from "styled-components";

const Tab = ({ label, value, activeValue, onClick }: any) => {
  if (value == activeValue) {
    return (
      <div>
        <ActiveTab
          onClick={() => onClick(value)}
          className="px-3  py-2 border border-gray-100 bg-white cursor-pointer border-b-0 text-blue-500 whitespace-nowrap  block shadow-xl"
        >
          {label}
        </ActiveTab>
      </div>
    );
  }
  return (
    <div
      onClick={() => onClick(value)}
      className="px-3  py-2 border border-gray-100 bg-gray-100 cursor-pointer whitespace-nowrap shadow-xl"
    >
      {label}
    </div>
  );
};

interface TabsProps {
  onChange: any;
  items: any[];
  defaultValue: string;
}
export function NavTabs({ onChange, items, defaultValue }: TabsProps) {
  const [activeValue, setActiveValue] = useState(defaultValue);

  const handleOnChange = (value: any) => {
    onChange && onChange(value);
    setActiveValue(value);
  };

  return (
    <div>
      <div className="flex space-x-[2px] mb-2 text-sm overflow-x-auto border-b ">
        {items?.map(({ label, value }): any => {
          return (
            <Tab
              label={label}
              value={value}
              activeValue={activeValue}
              onClick={(value: any) => handleOnChange(value)}
            />
          );
        })}
      </div>
    </div>
  );
}

const ActiveTab = styled.div``;
