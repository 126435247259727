import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Typography, Button, Divider, Alert, Input } from "antd";
import {
  usePcrPlateQuery,
  useAddToPcrPlateMutation,
  usePcrPlateChangedSubscription,
  usePcrPlateLazyQuery,
  PcrPlate,
} from "generated/schema";
import formatDate from "utils/formatDate";
import { CompletePlateButton } from "./CompletePlateButton";
import { PcrPlateView } from "./../PcrPlateView";

import _flatten from "lodash/flatten";
import { PlateCompleted } from "./PlateCompleted";
const { Title } = Typography;

function alphabetPosition(index: number) {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  return (alphabet[index] && alphabet[index].toLocaleUpperCase()) || "";
}

const generatePlateMatrix = () => {
  const rowCount = 8;
  const colCount = 12;

  const rows = [...new Array(rowCount)].map((x, i) => i);
  const columns = [...new Array(colCount)].map((x, i) => i);

  const cells: any = {};

  rows.forEach((x, rowIndex) => {
    const cols = columns.forEach((c, colIndex): any => {
      const posIndex = alphabetPosition(rowIndex) + (colIndex + 1);

      if (!cells[alphabetPosition(rowIndex)]) {
        cells[alphabetPosition(rowIndex)] = [];
      }
      cells[alphabetPosition(rowIndex)].push({ posIndex, data: null });
    });
  });

  return cells;
};

const getVerticalFillMatrix = () => {
  const initialPlateState = generatePlateMatrix();

  const plateFillState: any = Object.values(initialPlateState);

  const fillOrder = [];

  let counter = 0;
  for (let a = 0; a < 12; a++) {
    const a = plateFillState[0].shift();
    const b = plateFillState[1].shift();
    const c = plateFillState[2].shift();
    const d = plateFillState[3].shift();
    const e = plateFillState[4].shift();
    const f = plateFillState[5].shift();
    const g = plateFillState[6].shift();
    const h = plateFillState[7].shift();

    fillOrder.push({ ...a, slot: counter });
    counter++;

    fillOrder.push({ ...b, slot: counter });
    counter++;

    fillOrder.push({ ...c, slot: counter });
    counter++;

    fillOrder.push({ ...d, slot: counter });
    counter++;

    fillOrder.push({ ...e, slot: counter });
    counter++;

    fillOrder.push({ ...f, slot: counter });
    counter++;

    fillOrder.push({ ...g, slot: counter });
    counter++;

    fillOrder.push({ ...h, slot: counter });
    counter++;
  }

  return fillOrder;
};
interface Props {
  data: any;
}
export const FillPlate = ({ data }: Props) => {
  const textInput: any = useRef(null);

  const initialPlateState = getVerticalFillMatrix();
  const nextFreeSlotInitial = initialPlateState.find((x) => !x?.data);
  const [plateState, setPlateState] = useState(initialPlateState);
  const [scannedText, setScannedText]: any = useState();
  const [nextFreeSlot, setNextFreeSlot]: any = useState(nextFreeSlotInitial);
  const [isPlateFull, setIsPlateFull]: any = useState(false);

  const pcrPlate: PcrPlate = data?.pcrPlate;

  const pcrPlateCreatedByUser = pcrPlate?.createdBy?.name || "-";
  const pcrPlateFinishedByUser = pcrPlate?.finishedBy?.name || "-";
  const { id } = useParams();

  let paramId = (id && parseInt(id)) || 0;

  const [addToPcrPlateMutation, addPcrPlateReq] = useAddToPcrPlateMutation({});

  useEffect(() => {
    getDataToMatrix(data);
  }, []);

  const getDataToMatrix = (data: any) => {
    const pcrPlateEntries = data?.pcrPlate?.entriesJson || "[]";

    const plateStateObj: any = {};
    Object.values(plateState).forEach((x) => (plateStateObj[x.posIndex] = x));

    try {
      const pcrPlateEntriesJson = JSON.parse(pcrPlateEntries);

      for (const pcrPlateEntry of pcrPlateEntriesJson) {
        if (plateStateObj[pcrPlateEntry?.pcrPlateSlot]) {
          plateStateObj[pcrPlateEntry?.pcrPlateSlot]["data"] = pcrPlateEntry;
        }
      }

      const newPlateState: any = Object.values(plateStateObj);
      setPlateState(newPlateState);

      // Column a1 && a2 preserved for ref specimen
      const nextFreeSlot = newPlateState.find((x: any) => {
        return !x?.data && x.posIndex != "A1" && x.posIndex != "B1";
      });
      const nextNextSlot = nextFreeSlot?.slot;

      const isPlateFull = !!!plateState[nextNextSlot + 1];

      const preservedCells = 2;
      const count = pcrPlateEntriesJson.length;
      const plateSize = data?.pcrPlate?.plateSize - preservedCells;

      setIsPlateFull(count == plateSize);
      if (!nextFreeSlot) {
        return;
      }

      const nextFree = plateState[nextNextSlot];
      setNextFreeSlot(nextFree);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnScanSave = () => {
    // const nextFreeSlot = plateState.find((x) => !x?.data);
    // Column a1 && a2 preserved for ref specimen
    const nextFreeSlot = plateState.find((x: any) => {
      return !x?.data && x.posIndex != "A1" && x.posIndex != "B1";
    });

    const pcrPlateSlot = nextFreeSlot?.posIndex;

    let word_With_Numbers = scannedText;
    let scannedTextOnlyDigits = word_With_Numbers.replace(/\D/g, "");

    console.log(scannedTextOnlyDigits);

    const params = {
      variables: {
        id: paramId,
        input: { pcrPlateSlot, testId: parseInt(scannedTextOnlyDigits) },
      },
    };

    addToPcrPlateMutation(params);
    setScannedText("");
    if (textInput) {
      textInput?.current.focus();
    }
  };

  let notExistsError = false;
  let differentPlateError = false;
  let testAlreadyCompletedError = false;
  let alreadyOnPlateError = false;
  let slotIsOccupied = false;

  if (addPcrPlateReq.error) {
    addPcrPlateReq.error.graphQLErrors.map(({ message }, i) => {
      if (message === "already on plate") {
        notExistsError = false;
        testAlreadyCompletedError = false;
        differentPlateError = false;
        slotIsOccupied = false;

        alreadyOnPlateError = true;
      }

      if (message === "not exists") {
        notExistsError = true;
        testAlreadyCompletedError = false;
        differentPlateError = false;
        alreadyOnPlateError = false;
        slotIsOccupied = false;
      }

      if (message === "already on different plate") {
        notExistsError = false;
        testAlreadyCompletedError = false;
        differentPlateError = true;
        alreadyOnPlateError = false;
        slotIsOccupied = false;
      }

      if (message == "test has already an result") {
        notExistsError = false;
        testAlreadyCompletedError = true;
        differentPlateError = false;
        alreadyOnPlateError = false;
        slotIsOccupied = false;
      }

      if (message == "slot is occupied") {
        notExistsError = false;
        testAlreadyCompletedError = true;
        differentPlateError = false;
        alreadyOnPlateError = false;
        slotIsOccupied = true;
      }
    });
  }

  return (
    <div>
      <div className="2xl:grid 2xl:grid-cols-2">
        <div className="mr-auto ml-auto max-w-2xl 2xl:w-full">
          <div className="">
            <Title level={3}>PCR-Platte Bestückung</Title>

            <div className="w-72 text-xs">
              <div className="grid grid-cols-2">
                <div>Nummer PCR-Platte</div>
                <div className="text-indigo-500 font-bold">{id}</div>
              </div>
              <div className="grid grid-cols-2">
                <div>Plattengröße</div>
                <div className="text-indigo-500 font-bold">96</div>
              </div>
            </div>
            <div className="text-xs">
              Angelegt von: {pcrPlateCreatedByUser},{" "}
              {formatDate(data?.pcrPlate?.createdAt)}
            </div>
          </div>

          {pcrPlate?.finishedAt && (
            <div>
              {" "}
              <PlateCompleted pcrPlate={pcrPlate} />{" "}
            </div>
          )}

          {!isPlateFull && !pcrPlate?.finishedAt && (
            <div className="">
              <Divider plain>Scanner</Divider>
              <div className=" flex justify-center bg-yellow-100">
                <div className="">
                  <div className="flex justify-center items-center space-x-3 ">
                    <div>Nächstes freies Feld</div>
                    <div className="text-5xl text-center">
                      {nextFreeSlot?.posIndex}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="md:flex items-center mt-4">
                  <div className="mr-6">Barcode der Probe scannen:</div>
                  <div className="flex-1">
                    <Input
                      ref={textInput}
                      allowClear
                      value={scannedText}
                      onPressEnter={handleOnScanSave}
                      onChange={(e) => {
                        const v = e?.target?.value;

                        setScannedText(v);
                      }}
                      autoFocus
                      placeholder="Scannen"
                    />
                  </div>
                </div>

                {notExistsError && (
                  <div className="mt-2">
                    <Alert
                      description={
                        <div>Kein Test mit der eingegeben ID gefunden.</div>
                      }
                      type="error"
                    />
                  </div>
                )}

                {alreadyOnPlateError && (
                  <div className="mt-2">
                    <Alert
                      description={<div>Test bereits auf aktueller Platte</div>}
                      type="error"
                    />
                  </div>
                )}

                {slotIsOccupied && (
                  <div className="mt-2">
                    <Alert
                      description={
                        <div>Platz auf der Platte bereits belegt</div>
                      }
                      type="error"
                    />
                  </div>
                )}

                {differentPlateError && (
                  <div className="mt-2">
                    <Alert
                      description={
                        <div>
                          Eingegebener Test ist bereits auf einer anderen Platte
                        </div>
                      }
                      type="error"
                    />
                  </div>
                )}

                {testAlreadyCompletedError && (
                  <div className="mt-2">
                    <Alert
                      description={<div>Test wurde bereits ausgewertet</div>}
                      type="error"
                    />
                  </div>
                )}

                <div className="flex justify-end mt-2">
                  <Button
                    loading={addPcrPlateReq.loading}
                    disabled={!scannedText}
                    onClick={() => {
                      handleOnScanSave();
                    }}
                    type="primary"
                  >
                    Auf Platte setzen
                  </Button>
                </div>
              </div>

              <div className="mt-12">
                <Divider plain>Befüllung manuell abschließen</Divider>
                <div>
                  Falls Sie die PCR-Platte in ihrem aktuellen Zustand in das
                  Testgerät einsetzen und die Befüllung damit abschließen
                  möchte, so klicken Sie bitte auf den folgenden Button
                </div>

                <div className=" flex items-end justify-end">
                  <CompletePlateButton pcrPlate={data?.pcrPlate} />
                </div>
              </div>
            </div>
          )}

          {isPlateFull && (
            <div className="max-w-2xl ml-auto mr-auto ">
              <Divider plain>Platte ist voll</Divider>
              <div className=" flex items-end justify-end">
                <CompletePlateButton
                  block={true}
                  text={"Platte Abschließen"}
                  pcrPlate={data?.pcrPlate}
                />
              </div>
            </div>
          )}
        </div>

        <div className=" sm:mt-8 2xl:mt-0">
          <Divider plain>PCR-Platte Belegungsansicht</Divider>

          <div key={JSON.stringify(plateState)} className=" overflow-auto ">
            <div className="">
              <div style={{ width: "1000px" }}>
                <PcrPlateView
                  nextFreeSlot={nextFreeSlot}
                  pcrPlate={data?.pcrPlate}
                  data={plateState}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
