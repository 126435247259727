import { Role, TestCustomer, User, UserGroup } from "generated/schema";
import { RolesFormForm } from "./Form";

import {
  useRolesQuery,
  useTestCentersQuery,
  useUserGroupsQuery,
} from "generated/schema";
import { Spin } from "antd";
interface Props {
  role?: Role;
  onClose?: any;
}

export const RolesForm = ({ role, onClose }: Props) => {
  return (
    <div key={role?.id}>
      <RolesFormForm onClose={onClose} role={role} />
    </div>
  );
};
