import React from "react";
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  EmojiHappyIcon,
  ExclamationIcon,
  BeakerIcon,
  ClockIcon,
} from "@heroicons/react/solid";
import { Button } from "styled";

import { TestResult } from "generated/schema";
import { Popconfirm, message } from "antd";

import { useFinishTestResultTimerMutation } from "./../../generated/schema";
interface TimeRunningButtonProps {
  testResult: TestResult;
  duration : String
}
export const TestTableTestTimeRunningBtn = ({ duration, testResult }: TimeRunningButtonProps) => {
  const [finishTestResultTimerMutation, { data, loading, error }] =
    useFinishTestResultTimerMutation({
      variables: {
        id: testResult?.id,
      },
    });
  const confirm = (e: any) => {
    console.log(e);
    finishTestResultTimerMutation()
    message.success("Testdauer beendet");
  };

  function cancel(e: any) {
    console.log(e);
    //message.error("Click on No");
  }

  return (
    <Popconfirm
      title="Testdauer vorzeitig beenden?"
      onConfirm={confirm}
      onCancel={cancel}
      okText="Ja"
      cancelText="Abbrechen"
    >
      <div className=" text-white shadow-md bg-gray-500 hover:bg-gray-400 font-bold py-1 px-2 border-b-4 border-gray-300 hover:border-gray-200 rounded  inline-block w-48">
        <div className="relative">
          <div className="absolute -top-2 right-0">
            <ClockCircleOutlined style={{ color: "yellow" }} />
          </div>
        </div>
        {duration}
      </div>
    </Popconfirm>
  );
};
