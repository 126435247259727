import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Image, Popover } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { message, Popconfirm } from "antd";
import useToken from "hooks/useJwtToken";
import { AppCtx } from "context/AppState";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Space } from "antd";
import { XIcon } from "@heroicons/react/solid";
import { useLogoutLazyQuery } from "generated/schema";
import { TimeElapsed } from "components/WorkTime/TimeElapsed";
import { TestCenterMenuDetails } from "./TestCenterDetails";

const info = (cb: any) => {
  Modal.info({
    title: "Sie müssen sich zuerst Ausstechen",
    content: (
      <div>
        <p>Bitte stechen Sie sich aus</p>
      </div>
    ),
    onOk() {
      cb();
    },
  });
};

export const LoggedInUser = () => {
  const appContext = useContext(AppCtx);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const [logout, { data, error, loading }] = useLogoutLazyQuery({
    fetchPolicy: "network-only",
  });

  const name = appContext?.user?.name;

  let location = useLocation();

  useEffect(() => {
    setShow(false);
  }, [location]);

  const runLogout = async () => {
    await logout();
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("activeTestCenter");

    window.location.href = "/";
  };
  const confirm = async () => {
    setShow(false);
    const runningWorkTime = appContext?.workTime;
    if (runningWorkTime) {
      info(() => navigate("/center-select"));
    } else {
      runLogout();
    }
  };

  return (
    <div className="">
      <div
        onClick={() => setShow(!show)}
        className="flex items-center hover:bg-gray-100 cursor-pointer"
      >
        <Button type="link">
          <Avatar
            style={{ backgroundColor: "#40A9FF" }}
            icon={<UserOutlined />}
          />
        </Button>

        <div className="mt-1 w-20 overflow-auto">{name}</div>
      </div>

      {show && (
        <div className="absolute top-16 bg-white p-4 shadow-lg right-3">
          {appContext?.activeTestCenter && (
            <TestCenterMenuDetails setShow={setShow} testCenter={appContext?.activeTestCenter} />
          )}

          <Divider plain>Menü</Divider>

          <div className="space-y-2">
            {/* <Button
              onClick={() => {
                setShow(false);
                navigate("/center-select");
              }}
              block
              size="small"
              type="default"
            >
              Zeiterfassung
            </Button> */}

            <Button
              onClick={() => {
                setShow(false);
                navigate("/center-select");
              }}
              block
              size="small"
              type="default"
            >
              Testcenter Wechsel
            </Button>
          </div>

          <Divider plain>Logout</Divider>
          <div className="mt-2">
            <Popconfirm
              placement="bottomLeft"
              title={"Logout"}
              onConfirm={confirm}
              okText="Logout"
              cancelText=""
            >
              <Button block size="small" type="primary">
                Logout
              </Button>
            </Popconfirm>
          </div>
        </div>
      )}
    </div>
  );
};
