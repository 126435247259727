import type { DrawerProps, RadioChangeEvent } from "antd";
import { Button, Drawer, Radio, Space, Grid } from "antd";
import { TestResult } from "generated/schema";
import React, { useEffect, useRef, useState } from "react";
import { TestCenterDetails } from "../TestResultTestCenter";
import { TestForm } from "./Form";
import { TestFormMenu } from "./TestResultFormMenu";
import { Divider } from "antd";

import { useCreateLogEventMutation } from "generated/schema";

interface Props {
  testResult: TestResult;
  onClose?: any;
}

export const TestResultForm = (props: Props) => {
  const refContainer = useRef(0);

  const [createLogEventMutation, { data, loading, error }] =
    useCreateLogEventMutation({ fetchPolicy: "no-cache" });

  useEffect(() => {
    if (refContainer.current == 0) {
      const message = `Test: ${props?.testResult?.id} geöffnet`;

      createLogEventMutation({
        variables: {
          input: { testId: props?.testResult?.id, description: message },
        },
      });
    }

    refContainer.current++;
  }, []);

  return (
    <>
      <TestForm onClose={props?.onClose} testResult={props?.testResult} />
    </>
  );
};
