import { Select } from "antd";
import { useTestTypesQuery } from "generated/schema";

import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import moment from "moment";
import styled from "styled-components";

const SDatePicker = styled(DatePicker)`
  && {
    .ant-picker-input {
      width: 200px;
    }
  }
`;
const { Option } = Select;

interface Props {
  onChange: any;
  greaterThan: boolean;
}
export const TestCreateGreaterFilter = ({ onChange, greaterThan }: Props) => {
  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (!dateString) {
      handleChange(dateString);
      return;
    }
    if(greaterThan) {
      const m = moment(date).utc().startOf("day").format();

      handleChange(m);
      return
    }

    const m = moment(date).utc().endOf("day").format();

    handleChange(m);
  
  };

  const handleChange = (value: string) => {
    if (!value) {
      onChange({
        fieldName: greaterThan ? "testResult.createdAtGreaterThan" : "testResult.createdAt",
        delete: true,
        isDate: true,
        greaterThan: greaterThan,
      });
      return null;
    }

    const filter = {
      fieldName: greaterThan ? "testResult.createdAtGreaterThan" : "testResult.createdAt",
      term: String(value),
      isDate: true,
      greaterThan: greaterThan,
    };

    onChange(filter);
  };

  return (
    <>
      <SDatePicker
        placeholder={greaterThan ? "Test erstellt >=" :  "Test erstellt <="}
        allowClear
        style={{ width: 200 }}
        onChange={onDateChange}
      />
    </>
  );
};
