import { Form, Radio } from "antd";
import { TextExpand } from "components/CourierPlan/Columns";
import React from "react";

export const CwaOptions = () => {
  return (
    <Form.Item
      label="CWA-Option"
      rules={[
        {
          message: "errorMessage",
          required: true,
        },
      ]}
      name={"cwaOption"}
    >
      <Radio.Group className="space-y-2">
        <div className="bg-gray-50 p-2">
          <Radio value="cwaAnonym">
            <TextExpand
              lastRow={
                <span className="font-normal ">
                  Einwilligung zur pseudonymisierten Übermittlung{" "}
                  <span className="font-bold">
                    (<span className="underline underline-offset-1">nicht</span>
                    -namentliche Anzeige)
                  </span>
                </span>
              }
            >
              Hiermit erkläre ich mein Einverständnis zum Übermitteln meines
              Testergebnisses und meines pyseudonymen Codes an das Serversystem
              des RKI, damit ich mein Testergebnis mit der Corona Warn App
              abrufen kann. Das Testergebnis in der App kann hierbei nicht als
              namentlicher Testnachweis verwendet werden.
            </TextExpand>
          </Radio>
        </div>

        <div className="bg-gray-50 p-2">
          <Radio value="cwaNotAnonym">
            <TextExpand
              lastRow={
                <span className="font-normal">
                  Einwilligung zur personalisierten Übermittlung{" "}
                  <span className="font-bold">
                    {" "}
                    (namentlicher Testnachweis)
                  </span>
                </span>
              }
            >
              Hiermit erkläre ich mein Einverständnis zum Übermitteln meines
              Testergebnisses und meines pyseudonymen Codes an das Serversystem
              des RKI, damit ich mein Testergebnis mit der Corona Warn App
              abrufen kann. Das Testergebnis in der App kann hierbei nicht als
              namentlicher Testnachweis verwendet werden.
            </TextExpand>
          </Radio>
        </div>

        <div className="bg-gray-50 p-2">
          <Radio value="cwaNot">
            Ich will die Corona Warn App nicht benutzen
          </Radio>
        </div>
      </Radio.Group>
    </Form.Item>
  );
};
