import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { TestCenter, TestCustomer, TestResult } from "generated/schema";
import { Popover } from "antd";
import {
  InformationCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { TableTestStateButton } from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
export const TextExpand = ({ children, lastRow }: any) => {
  const [expand, setExpand] = useState(false);

  return (
    <div>
      <div className="flex">
        <div> {lastRow && lastRow}</div>

        {!expand && (
          <div
            onClick={() => setExpand(!expand)}
            className="select-none text-indigo-500 flex ml-2  hover:text-indigo-300 cursor-pointer"
          >
            mehr <ChevronDownIcon className="w-5 h-5" />
          </div>
        )}
        {expand && (
          <div
            onClick={() => setExpand(!expand)}
            className="select-none text-indigo-500 flex ml-2   hover:text-indigo-300 cursor-pointer"
          >
            weniger <ChevronUpIcon className="w-5 h-5" />
          </div>
        )}
      </div>

      {expand && children}
    </div>
  );
};
export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "Standort",
    dataIndex: "name",
    key: "name",
    width: 400,
    render: (text: string, row: any) => {
      const testCenter: TestCenter = row?.testCenter;
      const testResults: [TestResult] = row?.testResults;
      const name = `${testCenter?.name}`;
      const contactPerson = testCenter?.contactPerson;
      const telStation = testCenter?.tel;
      const address = `${ testCenter?.street}, ${ testCenter?.zipCode} ${ testCenter?.city}`
      
      return (
        <div>
          <div className="font-bold"> {name} </div>

          <TextExpand
            lastRow={
              address && <div className="whitespace-pre-line">{address}</div>
            }
          >
            <div className="whitespace-pre-line">
              {testCenter?.openingTimes}{" "}
            </div>

            {contactPerson && (
              <div className="whitespace-pre-line">
                Ansprechpartner: {contactPerson}{" "}
              </div>
            )}
            {telStation && (
              <div className="whitespace-pre-line">
                Tel. Station : {telStation}{" "}
              </div>
            )}
          </TextExpand>
        </div>
      );
    },
  },

  {
    title: "Tests wartend",
    dataIndex: "street",
    key: "street",
    width: 120,
    render: (text: any, row: any) => {
      const testCenter: TestCenter = row?.testCenter;
      const testResults: [TestResult] = row?.testResults;

      return <div>{"street"}</div>;
    },
  },
  {
    title: "Auslieferung spätestens",
    dataIndex: "city",
    key: "city",

    render: (text: any, row: TestCustomer) => {
      const city = text || "-";
      return <div>{city}</div>;
    },
  },
  {
    title: "Status",
    dataIndex: "phone",
    key: "phone",
    width: 200,
    render: (text: any, row: TestCustomer) => {
      const phone = text || "-";
      return <div>{phone}</div>;
    },
  },

  {
    title: "Action",
    key: "action",
    render: (_, row: TestCustomer) => {
      const id = row?.id;
      const customerFirstName = row?.firstName || "";
      const customerLastName = row?.lastName || "";
      const title = `Editiere Kunde: ${customerFirstName} ${customerLastName}`;
      return (
        <Space size="middle">
          <Space size="middle">
            <MyDrawer
              title={title}
              triggerElement={
                <Button size="small" type="primary">
                  Edit
                </Button>
              }
            >
              <TestCustomerForm customer={row} />
            </MyDrawer>
          </Space>
        </Space>
      );
    },
  },
];
