import React, { useState } from "react";
import { Spin } from "antd";
import { Direction, useTestCourierGroupsQuery } from "generated/schema";
import { Radio } from "antd";
import { Select } from "antd";
const { Option } = Select;

export const CourierGroupSelect = ({ onChange }: any) => {
  const [orderBy, setOrderBy] = useState({
    fieldName: "testCourierGroup.id",
    direction: Direction.Asc,
  });

  const { loading, data } = useTestCourierGroupsQuery({
    variables: { orderBy },
    fetchPolicy: "no-cache",
  });

  if (loading) return <Spin />;

  const getValue = () => {
    try {
      return localStorage.getItem("courierId") || "1";
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="">
        <div>
          <Select
            onChange={(e) => {
              try {
                localStorage.setItem("courierId", e);
              } catch (e) {
                console.log(e);
              }
              onChange && onChange(e);
            }}
            defaultValue={getValue()}
            style={{ width: "300px" }}
          >
            <Option value={null}>Keine Kurier Zuordnung</Option>;
            {data?.testCourierGroups?.map((x) => {
              return <Option value={String(x?.id)}>{x?.name}</Option>;
            })}
          </Select>
        </div>
      </div>
    </div>
  );
};
