import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { TestTableWrapper } from "components/TestTable";

import {
  useTestResultsLazyQuery,
  useTestResultChangedSubscription,
  useTestResultCountLazyQuery,
} from "generated/schema";
import moment from "moment";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { Button, Radio } from "antd";
import { CreateTestResult } from "components/TestResult/CreateTestResult";
import { PcrPlateTableWrapper } from "components/PcrPlates/PcrPlateTable";
export const PcrPlatePage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <PageBreadcrumb value={["PCR-Platten"]} />
          {/* <TestPageMenu defaultValue={"/user-groups"} /> */}


          <PcrPlateTableWrapper />
        </div>
      </PageWrapper>
    </div>
  );
};
