import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Spin } from "antd";
import React, { useState } from "react";

import styled from "styled-components";
import { CourierProgress } from "../CourierProgress";

const Header = styled.div`
  border-bottom: 1px solid #f0f0f0;
  background: #fafafa;
  padding: 8px;
`;

export const CourierListHeader = ({ children }: any) => {
  return (
    <div>
      <Header className="mt-3 font-medium ">{children}</Header>
    </div>
  );
};

export const CourierListEntry = ({ children }: any) => {
  return (
    <div className="border-b border-gray-100 my-2 p-2 w-full shadow-md  ">
      <div className="flex ">
        <div className="w-full ">
       
          {children}
        </div>
      </div>
    </div>
  );
};

export const CourierListDataHandler = ({ loading, data }: any) => {
  if (loading) {
    return (
      <div className="text-center p-2">
        <Spin />
      </div>
    );
  }

  if (!loading && data.length == 0) {
    return <div className="text-center p-2">Keine Fahrten gefunden</div>;
  }

  return <></>;
};

export const TextExpand = ({ children, lastRow, show }: any) => {
  const [expand, setExpand] = useState(false);

  if (!show) {
    return <div>{children}</div>;
  }
  return (
    <div>
      <div className="flex">
        <div> {lastRow && lastRow}</div>

        {!expand && (
          <div
            onClick={() => setExpand(!expand)}
            className="select-none text-indigo-500 flex ml-2  hover:text-indigo-300 cursor-pointer"
          >
            mehr <ChevronDownIcon className="w-5 h-5" />
          </div>
        )}
        {expand && (
          <div
            onClick={() => setExpand(!expand)}
            className="select-none text-indigo-500 flex ml-2   hover:text-indigo-300 cursor-pointer"
          >
            weniger <ChevronUpIcon className="w-5 h-5" />
          </div>
        )}
      </div>

      {expand && children}
    </div>
  );
};
