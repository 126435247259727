import { Select } from "antd";
import { useRolesQuery } from "generated/schema";

const { Option } = Select;

interface Props {
  onChange: any;
}
export const RoleFilter = ({ onChange }: Props) => {
  const { data, loading, error } = useRolesQuery({
    fetchPolicy: "network-only",
  });

  const handleChange = (value: string) => {

    if (!value) {
      onChange({
        fieldName: "user.role.id",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "user.role.id",
      term: String(value),
      exact: true,
    };

    onChange(filter);
  };

  return (
    <>
      <Select
        allowClear
        placeholder="Rolle"
        style={{ minWidth: 150 }}
        loading={loading}
        onChange={handleChange}
      >
        {data?.roles?.map((x) => {
          return <Option value={x?.id}>{x?.name}</Option>;
        })}
      </Select>
    </>
  );
};
