import {
  TestResult,
  TestTransportJob,
  useTestResultsQuery,
} from "generated/schema";
import { Button, Card, Divider, Radio, Spin } from "antd";

import {
  useTestResultsLazyQuery,
  Direction,
  useTestTransportJobsLazyQuery,
  useTestTransportJobChangedSubscription,
  useTestResultCountLazyQuery,
} from "generated/schema";
import { useEffect, useState } from "react";

import formatDate from "utils/formatDate";
import {
  CourierListHeader,
  CourierListEntry,
  CourierListDataHandler,
  TextExpand,
} from "components/CourierPlan/CourierList";

import moment from "moment";
import { ApproveDeliveredButton } from "./ApproveDeliveredButton";
import { CourierTestDetailsButton } from "../CourierTestDetailsButton";
export const OnGoingTransportJobs = ({ courierGroupId }: any) => {
  const [getTransportJobs, { data, loading }] = useTestTransportJobsLazyQuery({
    fetchPolicy: "no-cache",
  });

  const {
    data: dataChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useTestTransportJobChangedSubscription({ fetchPolicy: "no-cache" });

  useEffect(() => {
    getTransportJobs({
      variables: {
        filters: [
          {
            fieldName: "testCenter.courierGroup.id",
            term: String(courierGroupId),
            exact: true,
          },
          {
            fieldName: "testTransportJob.deliveredAt",
            term: "null",
            exact: true,
          },
          // { fieldName: "testType.transportJob.id", term: "null", exact: true },
        ],
        orderBy: { fieldName: "testTransportJob.id", direction: Direction.Asc },
      },
    });
  }, [dataChangedData, courierGroupId]);

  // if (loading) return <Spin />;

  const ongoingTransportJobsData = data?.testTransportJobs || [];

  return (
    <div>
      {loading && <Spin/>}
      <CourierListHeader>
        Kurierplanung
        <div className="text-xs font-normal">
          Anzahl Transportjobs: {ongoingTransportJobsData.length}
        </div>
      </CourierListHeader>

      <div>
        <CourierListDataHandler
          loading={loading}
          data={ongoingTransportJobsData}
        />
        {ongoingTransportJobsData.map((x: TestTransportJob) => {
      
          const name = `${x?.testCenter?.name}`;
          const contactPerson = x?.testCenter?.contactPerson;
          const telStation = x?.testCenter?.tel;
          const address = `${x?.testCenter?.street}, ${x?.testCenter?.zipCode} ${x?.testCenter?.city}`;
          const openingTimes = x?.testCenter?.openingTimes;

          const pickedUpAt = x?.createdAt
          const pickedUpByName = x?.pickedUpBy?.name || "-"

          const testResults = x?.testResults || [];

          return (
            <CourierListEntry>
              <div className="font-bold"> {name} </div>


              <TextExpand
                show={openingTimes || contactPerson || telStation}
                lastRow={
                  address && (
                    <div className="whitespace-pre-line">{address}</div>
                  )
                }
              >
                <div className="whitespace-pre-line">{openingTimes}</div>

                {contactPerson && (
                  <div className="whitespace-pre-line">
                    Ansprechpartner: {contactPerson}
                  </div>
                )}
                {telStation && (
                  <div className="whitespace-pre-line">
                    Tel. Station : {telStation}
                  </div>
                )}
              </TextExpand>

              <CourierTestDetailsButton testResults={testResults} />




              <div className=" ">
                <Divider orientation="left" plain>
                  Abholdetails
                </Divider>
                <div>
                  <div className="grid grid-cols-2">
                    <div>Fahrauftragkennung</div>
                    <div>{x?.id}</div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>Abgeholt von</div>
                    <div className="font-bold">
                      <div className="flex">
                        <div>{pickedUpByName}</div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2">
                    <div>Abgeholt um</div>
                    <div>{formatDate(new Date(pickedUpAt))}</div>
                  </div>
                </div>
              </div>
              <ApproveDeliveredButton transportJob={x} />
            </CourierListEntry>
          );
        })}
      </div>
    </div>
  );
};

interface TestButtonProps {
  // testResults : TestResult[]
  // testsGroupedByTypeArr : any
  transportJobId: number;
}

const LoadTestsButton = ({ transportJobId }: TestButtonProps) => {
  const { data, loading } = useTestResultsQuery({
    fetchPolicy: "network-only",
    variables: {
      filters: [
        {
          fieldName: "testResult.transportJob.id",
          term: String(transportJobId),
          exact: true,
        },

        // { fieldName: "testType.transportJob.id", term: "null", exact: true },
      ],
      // orderBy: { fieldName: "testTransportJob.id", direction: Direction.Asc },
    },
  });

  // if (loading) {
  //   return <Spin />;
  // }

  return (
    <div>
      {loading &&  <Spin/>}
      <CourierTestDetailsButton testResults={data?.testResults} />
    </div>
  );
};
