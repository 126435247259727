import React, { useEffect, useState, useContext } from "react";
import { PageWrapper } from "./../../layout/Page";
import { CustomerTableWrapper } from "components/CustomerTable";
import { TestTableSearch } from "components/TestTable/Search";
import { CourierPlan } from "components/CourierPlan";
import { PageBreadcrumb } from "components/PageBreadcrumb";
import { CustomerTableSearch } from "components/CustomerTable/Search";
export const CourierPlanPage = () => {
  return (
    <div>
      <PageWrapper>
        <div className="p-4  h-full">
          <PageBreadcrumb value={["Kurier Transportplan"]} />

          <CourierPlan />
        </div>
      </PageWrapper>
    </div>
  );
};
