import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

import client from "./apolloClient";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "routes/index";

import { ConfigProvider } from "antd";
import deDE from "antd/es/locale/de_DE";
import { AppStateProvider } from "context/AppState";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ConfigProvider locale={deDE}>
      <AppStateProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
        </AppStateProvider>
      </ConfigProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
