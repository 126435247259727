import React, { useContext, useEffect, useState } from "react";
import { LoginForm } from "./LoginForm";
import { Logo } from "components/Logo";
import { QrCodeScanner } from "components/MyQrCodeScanner";
import { useGeolocated } from "react-geolocated";
import { Alert, Spin } from "antd";
import { AppCtx } from "context/AppState";

export const LoginPage = () => {
  const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 0,
  };

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        ...options,
      },
      userDecisionTimeout: 5000,
    });

  const context = useContext(AppCtx);

  const error = isGeolocationAvailable && isGeolocationEnabled;

  const lat = coords?.latitude;
  const lng = coords?.longitude;

  return (
    <div>
      <div className="w-screen h-screen">
        <div className="p-4">
          <div className="max-w-md ml-auto mr-auto">
            <div className="pt-32 md:pt-52">
              <div className="flex items-center justify-center ">
                <Logo />
              </div>

              <div className="mt-6">
                {lat && lng && <LoginForm lat={lat} lng={lng} />}

                {!isGeolocationEnabled && (
                  <Alert
                    message={
                      <div>
                        {!error && (
                          <>
                            <div className="font-normal">
                              Eine Anmeldung ohne Standortfreigabe ist nicht
                              möglich.
                            </div>
                            <div>
                              Bitte akzeptieren Sie die <b>Standortfreigabe</b>
                            </div>
                          </>
                        )}

                        {error && (
                          <>
                            <div className="font-normal">{error}</div>
                          </>
                        )}
                      </div>
                    }
                    type="error"
                  />
                )}
              </div>

              <div className="space-x-2 flex justify-end">
                <a
                  target="_blank"
                  href="https://tc-elite.de/datenschutzerklaerung/"
                >
                  Datenschutz
                </a>

                <a
                  target="_blank"
                  href="https://tc-elite.de/agbs/"
                >
                  AGB's
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
