import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
  Radio,
  Alert,
} from "antd";

import styled from "styled-components";
import {
  IdentificationIcon,
  CalendarIcon,
  AtSymbolIcon,
  PhoneIcon,
  ClipboardListIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import {
  User,
  useUpdateTestCustomerMutation,
  useUpdateUserGroupMutation,
  useCreateUserGroupMutation,
  UserGroup,
  Role,
} from "generated/schema";

import { validatePassword } from "utils/validatePassword";
const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  userGroup?: UserGroup;

  onClose?: any;
}

export const UserGroupFormForm = ({ userGroup, onClose }: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [form] = Form.useForm();
  const [changePwd, setChangePwd] = useState(!userGroup?.id);
  const [createUserGroup, { data, loading }] = useCreateUserGroupMutation();
  const [updateUserGroup, updateReq] = useUpdateUserGroupMutation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    if (userGroup?.id) {
      updateUserGroup({ variables: { id: userGroup.id, input: values } });
    } else {
      createUserGroup({ variables: { input: values } });
    }

    success();
    onClose && onClose();
  };

  let initValues = {
    ...userGroup,
  };

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
      // labelCol={{ span: 10 }}
      // wrapperCol={{ span: 14  }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: errorMessage }]}
      >
        <Input
          allowClear
          autoComplete="off"
          placeholder="Benutzergruppen Name"
        />
      </Form.Item>

      <div>
        <Alert
          message={
            <div>
              Wenn diese Eigenschaft aktiv gesetzt ist, wird das Rechnungs Modul
              freigeschaltet. Bitte schalten Sie diese Eigenschaft mit "JA" auf
              Teststationen die nicht von der VITA1 betrieben werden.
            </div>
          }
          type="info"
        />
      </div>

      <Form.Item
        label="Vita1 Stationen"
        name="vita1"
        rules={[{ required: false, message: errorMessage }]}
      >
        <Radio.Group>
          <Radio value={true}> Ja </Radio>
          <Radio value={false}> Nein </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
