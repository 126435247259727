import { Button, Modal } from "antd";
import React, { useContext, useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import {
  useCreateTestResultMutation,
  useTestCustomersQuery,
  useTestTypesQuery,
} from "generated/schema";
import { AppCtx } from "context/AppState";

import { Select } from "antd";
import { useTestCentersQuery, useTestCustomerQuery } from "generated/schema";
const { Option } = Select;

interface Props {
  onChange: any;
}

export const TestCustomers = ({ testCenterId, onChange }: any) => {
  const f = {
    fieldName: "testCenter.id",
    term: String(testCenterId),
    exact: true,
  };

  const { data: customerData, error: customerError } = useTestCustomersQuery({
    fetchPolicy: "no-cache",
    variables: { skip: 0, take: 100, filters: [f] },
  });

  return (
    <Select
      allowClear
      placeholder="Kunde"
      loading={false}
      style={{ minWidth: 200 }}
      onChange={(x) => onChange(x)}
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) => {
        return ((option?.children as unknown as string) || "")
          .toLowerCase()
          .includes(input.toLowerCase());
      }}
    >
      {customerData?.testCustomers?.map((x) => {
        return <Option value={x?.id}>{x?.name}</Option>;
      })}
    </Select>
  );
};

export const CreateTestResultCockpit: React.FC = () => {
  const [testCenterId, setTestCenterId] = useState(1);
  const [customerId, setCustomerId] = useState(1);
  const [testTypeId, setTestTypeId] = useState(2);
  const [testOptionTypeId, setOptionTypeId] = useState(1);

  const { data, loading, error } = useTestCentersQuery({
    fetchPolicy: "network-only",
  });

  const { data: testTypeData } = useTestTypesQuery({
    fetchPolicy: "network-only",
  });

  const [createTestResultMutation, createReq] = useCreateTestResultMutation();

  const appContext = useContext(AppCtx);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);

    createTestResultMutation({
      variables: {
        input: {
          name: "",
          testCenterId,
          testTypeId: testTypeId,
          testTypeOptionId: testOptionTypeId,
          customerId: customerId,
          noEmail: true,
        },
      },
    });
  };

  const createTestH = () => {
    setIsModalOpen(true);

    createTestResultMutation({
      variables: {
        input: {
          name: "",
          testCenterId,
          testTypeId: testTypeId,
          testTypeOptionId: testOptionTypeId,
          customerId: customerId,
          noEmail: false,
        },
      },
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log("terst", customerId);
  if (appContext?.user?.role?.id == 1) {
    return (
      <>
        <div className="grid grid-cols-1 space-y-2 p-6">
          <Select
            allowClear
            placeholder="Standort"
            loading={false}
            style={{ minWidth: 200 }}
            onChange={(x) => setTestCenterId(parseInt(x))}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => {
              return ((option?.children as unknown as string) || "")
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {data?.testCenters?.map((x) => {
              return <Option value={x?.id}>{x?.name}</Option>;
            })}
          </Select>

          {testCenterId && (
            <TestCustomers
              onChange={(x: any) => {
                debugger;
                setCustomerId(x);
              }}
              testCenterId={testCenterId}
            />
          )}

          <Select
            allowClear
            placeholder="Test-Typ"
            style={{ minWidth: 150 }}
            loading={loading}
            onChange={(x) => {
              const testType = testTypeData?.testTypes?.find((y) => y.id == x);
              const testTypeOption = testType?.options?.[0];

              setTestTypeId(parseInt(x));
              if (testTypeOption) setOptionTypeId(testTypeOption?.id);
            }}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => {
              return ((option?.children as unknown as string) || "")
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {testTypeData?.testTypes?.map((x) => {
              return <Option value={x?.id}>{x?.name}</Option>;
            })}
          </Select>

          <div className="grid grid-cols-2 space-x-4">
            <Button
              loading={createReq?.loading}
              type="primary"
              onClick={createTestH}
            >
              <div className="flex items-center">
                <PlusIcon className="w-4 h-4" />
                Neuen Test anlegen
              </div>
            </Button>

            <Button
              loading={createReq?.loading}
              type="primary"
              onClick={showModal}
            >
              <div className="flex items-center">
                <PlusIcon className="w-4 h-4" />
                Neuen Test anlegen h
              </div>
            </Button>
          </div>
        </div>
      </>
    );
  }

  return <div>Empty</div>;
};
