import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  message,
  Radio,
  Alert,
} from "antd";

import styled from "styled-components";
import {
  IdentificationIcon,
  CalendarIcon,
  AtSymbolIcon,
  PhoneIcon,
  ClipboardListIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import {
  User,
  useUpdateTestCustomerMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  UserGroup,
  Role,
} from "generated/schema";

import { validatePassword } from "utils/validatePassword";
const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  user?: User;
  userGroups?: any;
  testCenters?: any;
  roles?: any;
  onClose?: any;
}

export const StaffForm = ({
  user,
  userGroups,
  testCenters,
  roles,
  onClose,
}: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [form] = Form.useForm();
  const [changePwd, setChangePwd] = useState(!user?.id);
  const [createUser, { data, loading }] = useCreateUserMutation();
  const [updateUser, updateReq] = useUpdateUserMutation();

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);

    const fixTestCenterId =
      (values?.fixTestCenterId && values?.fixTestCenterId) || 0;

    values.fixTestCenterId = fixTestCenterId;

    if (values.workerId) {
      values.workerId = String(values.workerId);
    }

    if (user?.id) {
      updateUser({ variables: { id: user.id, input: values } });
    } else {
      createUser({ variables: { input: values } });
    }

    success();
    onClose && onClose();
  };

  let initValues = {
    active: true,
    smsPinRequired: false,
    userGroupId: user?.userGroup?.id,
    roleId: user?.role?.id,
    fixTestCenterId: user?.fixTestCenter?.id || 0,
    ...user,
  };

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
      // labelCol={{ span: 10 }}
      // wrapperCol={{ span: 14  }}
    >
      <div className="sm:grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <div>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              disabled={!!user?.id}
              allowClear
              autoComplete="off"
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item
            label="E-Mail"
            name="email"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="E-Mail" />
          </Form.Item>

          <Form.Item
            name="firstName"
            label="Vorname"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Vorname" />
          </Form.Item>

          <Form.Item
            name="workerId"
            label="Mitarbeiter ID"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Mitarbeiter ID" />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Nachname"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input allowClear autoComplete="off" placeholder="Nachname" />
          </Form.Item>

          <Form.Item
            name="mobilePhone"
            label="Mobil"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              allowClear
              autoComplete="off"
              placeholder="Mobil Handynummer"
            />
          </Form.Item>
          {user?.id && (
            <Checkbox
              onChange={() => setChangePwd(!changePwd)}
              value={changePwd}
            >
              Passwort ändern
            </Checkbox>
          )}
          {changePwd && (
            <div>
              <div className="mb-2"></div>

              <div>
                <Form.Item
                  name="password"
                  label="Passwort"
                  rules={[
                    { required: true },
                    {
                      validator: (_, value) => {
                        const {
                          isValid,
                          textLengthValid,
                          containsOneDigit,
                          containsSpecialCharacters,
                          containsCapitalLetter,
                        } = validatePassword(value);

                        if (isValid) {
                          return Promise.resolve();
                        } else {
                          if (!containsOneDigit)
                            return Promise.reject(
                              "Fehler : Enthält keine Zahl"
                            );

                          if (!containsSpecialCharacters)
                            return Promise.reject(
                              "Fehler: Enthält kein Sonderzeichen"
                            );

                          if (!containsCapitalLetter)
                            return Promise.reject(
                              "Fehler: Enthält kein Großbuchstaben"
                            );

                          if (!textLengthValid)
                            return Promise.reject(
                              "Fehler: Zeichenlänge falsch"
                            );
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password allowClear placeholder="Passwort" />
                </Form.Item>

                <div className="mb-2">
                  <Alert
                    message={
                      <div>
                        Das Kennwort muss mind. 12 Zeichen lang sein! Das
                        Kennwort muss mindestens eine Ziffer, einen
                        Großbuchstaben, einen Kleinbuchstaben und ein
                        Sonderzeichen enthalten!
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          <Form.Item
            name="userGroupId"
            label="Benutzergruppe"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Select
              showSearch
              placeholder="Benutzergruppe"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (option?.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              allowClear
            >
              {userGroups?.map((x: UserGroup) => (
                <Select.Option value={x?.id}>{x?.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="roleId"
            label="Rolle"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Select
              showSearch
              placeholder="Rolle"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (option?.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              allowClear
            >
              {roles?.map((x: Role) => (
                <Select.Option value={x?.id}>{x?.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="fixTestCenterId"
            label="Fixer Standort"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Select
              showSearch
              placeholder="Fixer Standort"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const value = (option?.children as unknown as string) || "";
                return value.toLowerCase().includes(input.toLowerCase());
              }}
              allowClear
            >
              <Select.Option value={0}>Nein</Select.Option>
              {testCenters?.map((x: Role) => (
                <Select.Option value={x?.id}>{x?.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item name="smsPinRequired" label="SMS-Pin erforderlich">
            <Radio.Group>
              <Radio value={true}>Ja</Radio>
              <Radio value={false}>Nein</Radio>
            </Radio.Group>
          </Form.Item> */}
          <Form.Item name="trackWorkTimes" label="Arbeitszeiterfassung">
            <Radio.Group>
              <Radio value={true}> Aktiv </Radio>
              <Radio value={false}> Befreit </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="active" label="Aktiv">
            <Radio.Group>
              <Radio value={true}> Aktiv </Radio>
              <Radio value={false}> Inaktiv </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
