import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { TestCustomer, User } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { TableTestStateButton } from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { UserForm } from "./StaffForm";
import { LogTableWrapper } from "components/LogTable";
export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: 50,
  },

  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    width: 200,
    render: (text: string, row: User) => {
      const name = `${row?.username}`;
      return <div>{name}</div>;
    },
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (text: string, row: User) => {
      const name = `${row?.name}`;
      return <div>{name}</div>;
    },
  },

  {
    title: "E-Mail",
    dataIndex: "email",
    key: "email",
    width: 250,
    render: (text: any, row: User) => {
      const email = `${row?.email || "-"} `;

      return <div>{email}</div>;
    },
  },
  {
    title: "Rolle",
    dataIndex: "role",
    key: "role",
    width: 120,
    render: (text: any, row: User) => {
      const role = row?.role?.name || "-";
      return <div>{role}</div>;
    },
  },

  {
    title: "Benutzergruppe",
    dataIndex: "userGroup",
    key: "userGroup",
    width: 200,
    render: (text: any, row: User) => {
      const role = row?.userGroup?.name || "-";
      return <div>{role}</div>;
    },
  },

  {
    title: "Fixer Standort",
    dataIndex: "fixTestCenter",
    key: "userGroup",
    width: 200,
    render: (text: any, row: User) => {
      const role = row?.fixTestCenter?.name || "-";
      return <div>{role}</div>;
    },
  },

  {
    title: "Tel",
    dataIndex: "phone",
    key: "phone",
    width: 200,
    render: (text: any, row: User) => {
      const phone = row?.mobilePhone || "-";
      return <div>{phone}</div>;
    },
  },

  {
    title: "Aktiv",
    dataIndex: "active",
    key: "active",
    width: 200,
    render: (text: any, row: User) => {
      const phone = (row?.active && "Ja") || "Nein";
      return <div>{phone}</div>;
    },
  },

  {
    title: "Log-Daten",
    key: "log",
    render: (_, row: User) => {
      const id = row?.id;
      const customerFirstName = row?.firstName || "";
      const customerLastName = row?.lastName || "";
      const title = `Log-Daten MA: (${id}) ${customerFirstName} ${customerLastName}`;

      return (
        <Space size="middle">
          <Space size="middle">
            <MyDrawer
              title={title}
              triggerElement={
                <Button size="small" type="primary">
                  Log-Daten
                </Button>
              }
            >
              <LogTableWrapper
                fixFilters={[
                  {
                    fieldName: "user.id",
                    term: String(id),
                    exact: true,
                  },
                ]}
              />
            </MyDrawer>
          </Space>
        </Space>
      );
    },
  },
  {
    title: "Action",
    key: "action",
    render: (_, row: User) => {
      const id = row?.id;
      const customerFirstName = row?.firstName || "";
      const customerLastName = row?.lastName || "";
      const title = `Editiere MA: (${id}) ${customerFirstName} ${customerLastName}`;

      return (
        <Space size="middle">
          <Space size="middle">
            <MyDrawer
              title={title}
              triggerElement={
                <Button size="small" type="primary">
                  Edit
                </Button>
              }
            >
              <UserForm user={row} />
            </MyDrawer>
          </Space>
        </Space>
      );
    },
  },
];
