import { Table } from "styled";

import { Space, Tag, Grid } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import { Button } from "styled";
import { Link } from "react-router-dom";
import { TestCustomer } from "generated/schema";
import { Popover } from "antd";
import { InformationCircleIcon } from "@heroicons/react/solid";
import formatDate from "utils/formatDate";
import { TableTestStateButton } from "components/TestTableResultButton";
import { MyDrawer } from "components/MyDrawer";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { CheckInCustomerColumn } from "./CheckInCustomer";
export const Columns: ColumnsType<TestCustomer> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 75,
  },
  {
    title: "Geburtsdatum",
    dataIndex: "dateOfBirth",
    key: "dateOfBirth",
    width: 175,
    render: (text: any, row: any) => {
      return <div>{text}</div>;
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (text: string, row: TestCustomer) => {
      const name = `${row?.firstName} ${row?.lastName}`;
      return <div>{name}</div>;
    },
  },

  {
    title: "Anschrift",
    dataIndex: "street",
    key: "street",
    width: 220,
    render: (text: any, row: TestCustomer) => {
      const street = `${row?.street || "-"} ${row?.streetNr || ""}`;

      return (
        <div className="overflow-x-auto ">
          <div className="w-48">{street}</div>
        </div>
      );
    },
  },
  {
    title: "Wohnort",
    dataIndex: "city",
    key: "city",
    width: 120,
    render: (text: any, row: TestCustomer) => {
      const city = text || "-";
      return <div>{city}</div>;
    },
  },
  {
    title: "Tel",
    dataIndex: "phone",
    key: "phone",
    width: 200,
    render: (text: any, row: TestCustomer) => {
      const phone = text || "-";
      return <div>{phone}</div>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (text: string, row: TestCustomer) => {
      const email = text || "-";
      return <div>{email}</div>;
    },
    width: 250,
  },
  {
    title: "Standort",
    dataIndex: "-",
    key: "testCenter",
    render: (text: string, row: TestCustomer) => {
      
      const testCenter = row?.testCenter?.name
      const email = testCenter || "-";
      return <div>{email}</div>;
    },
    width: 250,
  },

  {
    title: "CheckIn",
    key: "checkIn",
    width: 250,
    render: (_, testCustomer: TestCustomer) => {
      return <CheckInCustomerColumn value={_} testCustomer={testCustomer} />;
    },
  },

  {
    title: "Action",
    key: "action",
    render: (_, row: TestCustomer) => {
      const id = row?.id;
      const customerFirstName = row?.firstName || "";
      const customerLastName = row?.lastName || "";
      const title = `Editiere Kunde: ${customerFirstName} ${customerLastName}`;
      return (
        <Space size="middle">
          <Space size="middle">
            <MyDrawer
              title={title}
              triggerElement={
                <Button size="small" type="primary">
                  Edit
                </Button>
              }
            >
              <TestCustomerForm customer={row} />
            </MyDrawer>
          </Space>
        </Space>
      );
    },
  },
];
