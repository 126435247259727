import { Button, Popconfirm } from "antd";
import React, { useState } from "react";
import { useCreatePcrPlateMutation } from "generated/schema";
import { useNavigate } from "react-router-dom";

import { message, Space } from "antd";

export const CreateNewPlateButton: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const success = () => {
    message.success("Neue Platte angelegt");
  };
  const [createPcrPlateMutation, { data, loading, error }] =
    useCreatePcrPlateMutation({});

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    const data = await createPcrPlateMutation({ variables: { input: {} } });
    setOpen(false);
    setConfirmLoading(false);

    if (data?.data?.createPcrPlate?.id) {
      success();
      navigate("/pcr-fill-plates/" + data?.data?.createPcrPlate?.id);
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Sind Sie sich sicher?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
    >
      <Button type="primary" onClick={showPopconfirm}>
        Neue Platte Anlegen
      </Button>
    </Popconfirm>
  );
};
