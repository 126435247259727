import { FederalStateType, TestCenter, TestCustomer } from "generated/schema";
import { useState } from "react";
import { TestCenterForm } from "./Form";
import { TestCenterMenu } from "./TestCenterMenu";
import { TestCenterOpeningTimesForm } from "./OpeningTimesForm";
interface Props {
  testCenter?: TestCenter;
  onClose?: any;
}

export const feralStateTypeMapping = {
  [FederalStateType.BadenWuerttemberg]: "Baden-Württemberg",
  [FederalStateType.Bayern]: "Bayern",
  [FederalStateType.Berlin]: "Berlin",
  [FederalStateType.Brandenburg]: "Brandenburg",
  [FederalStateType.Bremen]: "Bremen",
  [FederalStateType.Hamburg]: "Hamburg",
  [FederalStateType.Hessen]: "Hessen",
  [FederalStateType.MecklenburgVorpommern]: "Mecklenburg Vorpommern",
  [FederalStateType.Niedersachsen]: "Niedersachsen",
  [FederalStateType.NordrheinWestfalen]: "Nordrhein-Westfalen",
  [FederalStateType.RheinlandPfalz]: "Rheinland-Pfalz",
  [FederalStateType.Saarland]: "Saarland",
  [FederalStateType.Sachsen]: "Sachsen",
  [FederalStateType.SachsenAnhalt]: "Sachsen-Anhalt",
  [FederalStateType.SchleswigHolstein]: "Schleswig-Holstein",
  [FederalStateType.Thueringen]: "Thüringen",
};

export const TestCenterFormWrapper = (props: Props) => {
  const [activeMenu, setActiveMenu] = useState("testCenter");
  return (
    <div key={props?.testCenter?.id}>
      <TestCenterMenu
        defaultValue={activeMenu}
        onChange={(v: any) => setActiveMenu(v)}
      />

      {activeMenu == "testCenter" && (
        <TestCenterForm
          onClose={props?.onClose}
          testCenter={props?.testCenter}
        />
      )}
      {activeMenu == "openingTimes" && (
        <TestCenterOpeningTimesForm
          onClose={props?.onClose}
          testCenter={props?.testCenter}
        />
      )}
    </div>
  );
};
