import { Button, Modal } from "antd";
import React, { useContext, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { UserGroupsSelect } from "./UserGroupsSelect";

import { TestCenterSelect } from "./TestCenterSelect/index";
import { TestResultSelect } from "./TestResultSelect";
import { TestTypeSelect } from "./TestTypeSelect";
import { Input } from "antd";
import { TestIdFilter } from "./TestIdFilter";
import { TestCustomerNameFilter } from "./TestCustomerNameFilter";
import { TestOptionTypeSelect } from "./TestTypeOptionSelect";
import { AppCtx } from "context/AppState";
import { DateOfBirthFilter } from "./DateOfBirthFilter";
import { TestCreateGreaterFilter } from "./TestCreateGreaterFilter";
interface Props {
  filters: any;
  setFilters: any;
}
export const TableFilters = ({ filters, setFilters }: Props) => {
  const context = useContext(AppCtx);

  const onFilterChange = (v: any) => {
    if (v?.delete == true) {
      const newFilters = filters.filter(
        (x: any) => x?.fieldName != v?.fieldName
      );
      setFilters(newFilters);
      return;
    }

    const newFilters = filters.filter((x: any) => x?.fieldName != v?.fieldName);
    newFilters.push(v);

    setFilters(newFilters);
    return;
  };

  const adminView =
    context?.user?.role?.id == 1 || context?.user?.role?.id == 2;

  return (
    <>
      <div className="flex space-x-2 w-full overflow-auto bg-gray-100 p-2">
        <TestIdFilter onChange={onFilterChange} />
        <TestCustomerNameFilter onChange={onFilterChange} />
        <DateOfBirthFilter onChange={onFilterChange} />

        {adminView && <UserGroupsSelect onChange={onFilterChange} />}

        {adminView && <TestCenterSelect onChange={onFilterChange} />}

        <TestTypeSelect onChange={onFilterChange} />
        <TestOptionTypeSelect onChange={onFilterChange} />

        <TestResultSelect onChange={onFilterChange} />
        <TestCreateGreaterFilter greaterThan={true} onChange={onFilterChange} />
        <TestCreateGreaterFilter
          greaterThan={false}
          onChange={onFilterChange}
        />
      </div>
    </>
  );
};
