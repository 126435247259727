import { Input, Select } from "antd";
import React from "react";
import { useTestCentersQuery } from "generated/schema";
const { Option } = Select;

interface Props {
  onChange: any;
}
export const TestIdFilter = ({ onChange }: Props) => {
  const handleChange = (e: any) => {
    const value = e?.target.value;

    if (!value) {
      onChange({
        fieldName: "user.name",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "user.name",
      term: String(value),
      exact: false,
    };

    onChange(filter);
  };

  return (
    <>
      <Input
        allowClear
        //@ts-ignore
        onChange={handleChange}
        style={{ minWidth: 200, maxWidth: 200 }}
        placeholder="Mitarbeiter"
      />
    </>
  );
};
