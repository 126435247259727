import { Button } from "antd";
import { TestCustomerForm } from "components/Customer/CustomerForm";
import { MyDrawer } from "components/MyDrawer";
import {
  Direction,
  useTestCustomerChangedSubscription,
  useTestCenterCountLazyQuery,
  useTestCustomerCountLazyQuery,
  useTestResultsLazyQuery,
  useTestCustomersLazyQuery,
  useWorkTimesLazyQuery,
  useWorkTimeChangedSubscription,
  useWorkTimeCountLazyQuery
} from "generated/schema";
import { useEffect, useState } from "react";
import { TestTable } from "./Table";
import { TableFilters } from "./TableFilters";
const TABLE_PAGE_SIZE = 12;

export const WorkTimeTableWrapper = () => {
  const [currentTablePage, setCurrentPageTable] = useState(1);
  const [filters, setFilters] = useState<any>([]);
  const [showTableFilters, setShowTableFilters] = useState(true);

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "workTime.id",
    direction: Direction.Desc,
  });

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const [getData, { data, loading }] = useWorkTimesLazyQuery({
    fetchPolicy: "network-only",
    variables: { orderBy, take, skip, filters },
  });
  const [getDataCount, { data: customerCountData, loading: l }] =
  useWorkTimeCountLazyQuery({
      fetchPolicy: "network-only",
      variables: { filters },
    });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useWorkTimeChangedSubscription({});

  useEffect(() => {
    getData();
    getDataCount();
  }, [customerChangedData]);

  const resultData = data?.workTimes || [];

  return (
    <div>
      <div className="flex  md:justify-end mb-2">
        {/* <MyDrawer
          title={"Neuen Kunden Anlegen"}
          triggerElement={<Button type="primary">Neuen Kunden anlegen</Button>}
        >
          <TestCustomerForm />
        </MyDrawer> */}
      </div>
      <div className="flex space-x-2">
        <div className="flex-1 overflow-auto ">
          {showTableFilters && (
            <TableFilters filters={filters} setFilters={setFilters} />
          )}
        </div>
      </div>
      <TestTable
        dataCount={customerCountData?.workTimeCount || 0}
        data={resultData}
        loading={loading}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
      />
    </div>
  );
};
