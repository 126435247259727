import { Button, Divider, Popconfirm } from "antd";
import React, { useState } from "react";

import {
  TestResult,
  useCreateTestTransportJobMutation,
  useFinishTestTransportJobMutation,
} from "generated/schema";
import { DeleteTransportJobButton } from "../DeleteTransportJobButton";
export const ApproveDeliveredButton = ({ transportJob }: any) => {
  console.log("transportJobtransportJob", transportJob);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await finishTransportJobMutation({
      variables: { id: transportJob.id },
    });
    setOpen(false);
    setConfirmLoading(false);

    window.location.reload()
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const [finishTransportJobMutation, { data, loading, error }] =
    useFinishTestTransportJobMutation({});

  return (
    <div className=" ">
      <Divider orientation="left" plain>
        Laborzustellung bestätigen
      </Divider>

      <div className="flex">
        <Popconfirm
          title={"Sind Sie sich sicher?"}
          open={open}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button block type="primary" onClick={showPopconfirm}>
            Laborzustellung bestätigen
          </Button>
        </Popconfirm>
        <DeleteTransportJobButton transportJob={transportJob} />
      </div>
    </div>
  );
};
