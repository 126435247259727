import React, { useState } from "react";
import { Tag, Button } from "antd";
import { HomeIcon } from "@heroicons/react/solid";
import { TestResult } from "generated/schema";

interface Props {
  testResult: TestResult;
}
export const TestCenterDetails = ({ testResult } : Props) => {

  const testCenterId = testResult?.testCenter?.id || "-"
  const testCenterName = testResult?.testCenter?.name || "-"
  const testCenterNameStreet = testResult?.testCenter?.street || "-"
  const testCenterCity = testResult?.testCenter?.city || "-"
  const testCenterZipCode = testResult?.testCenter?.zipCode || "-"
  const [showMore, setShowMore] = useState(false);
  return (
    <div>
      <div className="flex space-x-1 items-center">
        <div>
          <HomeIcon className="w-4 h-4" />
        </div>
        <div className="font-bold"> Standort</div>
      </div>
      <div className="">

        <div>{testCenterName} (ID: {testCenterId})</div>

        {showMore && (
          <>
            <div>{testCenterNameStreet}</div>
            <div>{testCenterZipCode} {testCenterCity}</div>
          </>
        )}

        <div
          onClick={() => setShowMore(!showMore)}
          className="text-blue-400 hover:text-blue-600 cursor-pointer"
        >
          {showMore ? "weniger" : "mehr"}
        </div>
      </div>
    </div>
  );
};
