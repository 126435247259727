import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Typography, Button, Divider, Alert, Input, Spin } from "antd";
import {
  usePcrPlateQuery,
  useAddToPcrPlateMutation,
  usePcrPlateChangedSubscription,
  usePcrPlateLazyQuery,
} from "generated/schema";

import { FillPlate } from ".";
import _flatten from "lodash/flatten";
const { Title } = Typography;

export const FillPlateWrapper = () => {
  const textInput: any = useRef(null);

  const { id } = useParams();

  let paramId = (id && parseInt(id)) || 0;

  const [getData, { data, loading, error }] = usePcrPlateLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      id: paramId,
    },
  });

  const {
    data: dataChanged,
    loading: dataChangedLoading,
    error: dataChangedError,
  } = usePcrPlateChangedSubscription({});

  useEffect(() => {
    getData();
  }, [dataChanged]);

  if (error) {
    return (
      <Alert message={"ID " + paramId + " existiert nicht"} type="error" />
    );
  }

  if (!data) {
    return <Spin />;
  }

  return (
    <div key={JSON.stringify(data?.pcrPlate)}>
      <FillPlate data={data} />
    </div>
  );
};
