import { useEffect, useRef, useState } from "react";

import { useTimer } from "react-timer-hook";

export const useCountdown = (date: any) => {
  const time = new Date(date);
  time.setSeconds(time.getSeconds());

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp: time,
    onExpire: () => console.warn("onExpire called"),
  });

  return {
    days,
    hours,
    minutes,
    seconds,
  };
  // //COUNTDOWN
  // const [timerDays, setTimerDays]: any = useState("00");
  // const [timerHours, setTimerHours]: any = useState("00");
  // const [timerMinutes, setTimerMinutes]: any = useState("00");
  // const [timerSeconds, setTimerSeconds]: any = useState("00");

  // let interval: any = useRef();

  // const setCountDown = (countdownDate: any) => {
  //   const now = new Date().getTime();
  //   const distance = countdownDate - now;

  //   const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor(
  //     (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   );
  //   const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //   const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //   if (distance < 0) {
  //     // Stop
  //     clearInterval(interval.current);
  //     console.log("clearIn");
  //     return false;
  //   } else {
  //     // Update
  //     setTimerDays(days);
  //     setTimerHours(hours);
  //     setTimerMinutes(minutes);
  //     setTimerSeconds(seconds);
  //   }
  // };
  // const startTimer = () => {
  //   const countdownDate = new Date(date).getTime();
  //   const needsCountdown = setCountDown(countdownDate);

  //   if (needsCountdown === false) {
  //     return;
  //   }
  //   interval = setInterval(() => {
  //     setCountDown(countdownDate);
  //   }, 1000);
  // };

  // useEffect(() => {
  //   startTimer();
  //   return () => {
  //     clearInterval(interval.current);
  //   };
  // });
  // // Logic
  // return {
  //   days: timerDays,
  //   hours: timerHours,
  //   minutes: timerMinutes,
  //   seconds: timerSeconds,
  // };
};
