import React, { useState } from "react";
import { Typography, Button, Divider } from "antd";
import { OpenPlatesSelect } from "./OpenPlatesSelect";
import { CreateNewPlateButton } from "./CreateNewPlateButton";
import { PageBreadcrumb } from "components/PageBreadcrumb";
const { Title } = Typography;

export const PcrPlates = () => {
  return (
    <div>
      <PcrPlateCreateOrSelect />
    </div>
  );
};

export const PcrPlateCreateOrSelect = () => {
  return (
    <div>
      <Title level={3}>PCR-Test Vorbereitung</Title>

      <div className="">
        <Title level={5}>
          Bitte wählen Sie die PCR-Platte aus, die Sie vorbereiten möchten oder
          legen Sie einen neuen Testvorgang für eine neue PCR-Platte an.
        </Title>
      </div>

      <div className="">
        <div>
          <Divider orientation="left" plain>
            Aktuell noch nicht abgeschloßene Platten
          </Divider>
          <OpenPlatesSelect />
        </div>
        <div className="absolute left-2 right-2 border-t-8 border-gray-100 mt-12"></div>

        <div className="mt-12 border-t-8 border-gray-50">
          <Divider orientation="left" plain>
            Wenn Sie eine neue Platte anlegen möchten
          </Divider>

          <CreateNewPlateButton />
        </div>
      </div>
    </div>
  );
};
