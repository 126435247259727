import { Select } from "antd";
import React, { useState } from "react";
import { usePcrPlatesQuery } from "generated/schema";
import formatDate from "utils/formatDate";

import { useNavigate } from "react-router-dom";
import { Direction } from "generated/schema";
const { Option } = Select;

export const OpenPlatesSelect = () => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState({
    fieldName: "id",
    direction: Direction.Desc,
  });
  
  const { data, loading, error } = usePcrPlatesQuery({
    fetchPolicy: "network-only",
    variables : {orderBy}
  });


  const handleChange = (value: string) => {
    console.log(`selected ${value}`);

    navigate("/pcr-fill-plates/" + value);
  };
  return (
    <>
      <Select
        placeholder="Bitte wählen"
        style={{ width: "100%" }}
        onChange={handleChange}
      >
        {data?.pcrPlates?.map((x) => {
          return (
            <Option value={x?.id}>
              {x?.id} {formatDate(x?.createdAt)}
            </Option>
          );
        })}
      </Select>
    </>
  );
};
