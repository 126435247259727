import { DownOutlined } from "@ant-design/icons";
import { Button, TableColumnsType, Tag } from "antd";
import { Badge, Dropdown, Menu, Space, Table } from "antd";
import React from "react";
import formatCurrency from "utils/formatCurrency";
const columns: TableColumnsType<any> = [
  { title: "Id", dataIndex: "id", key: "id", width: 50 },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 300,

    sorter: (a, b) => {
      return a?.name.localeCompare(b?.name);
    },
  },
  {
    title: "Bar",
    dataIndex: "bar",
    key: "bar",
    width: 100,
    render: (text, row) => formatCurrency(text),
    sorter: (a, b) => {
      return a?.bar - b?.bar;
    },
  },
  {
    title: "Karte",
    dataIndex: "card",
    key: "card",
    width: 100,
    render: (text, row) => formatCurrency(text),
    sorter: (a, b) => {
      return a?.card - b?.card;
    },
  },
  {
    title: "Positiv",
    dataIndex: "positiveTotal",
    key: "version",
    render: (text, row) => <Tag color="error">{text}</Tag>,
    width: 90,
    sorter: (a, b) => {
      return a?.positiveTotal - b?.positiveTotal;
    },
  },
  {
    title: "Negativ",
    dataIndex: "negativeTotal",
    key: "upgradeNum",
    render: (text, row) => <Tag color="success">{text}</Tag>,
    width: 90,
    sorter: (a, b) => {
      return a?.negativeTotal - b?.negativeTotal;
    },
  },

  {
    title: "Ungültig",
    dataIndex: "invalidTotal",
    key: "invalidTotal",
    render: (text, row) => <Tag color="success">{text}</Tag>,
    width: 90,
    sorter: (a, b) => {
      return a?.invalidTotal - b?.invalidTotal;
    },
  },

  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    width: 100,
    sorter: (a, b) => {
      return a?.total - b?.total;
    },
  },

  {
    title: "Bürgertest",
    dataIndex: "buergerTotal",
    key: "buergerTotal",
    width: 100,
    sorter: (a, b) => {
      return a?.buergerTotal - b?.buergerTotal;
    },
  },
  {
    title: "Schnelltest",
    dataIndex: "schnellTestTotal",
    key: "buergerTotal",
    width: 100,
    sorter: (a, b) => {
      return a?.schnellTestTotal - b?.schnellTestTotal;
    },
  },
  {
    title: "OEDG-Test",
    dataIndex: "oedgTotal",
    key: "creator",
    sorter: (a, b) => {
      return a?.oedgTotal - b?.oedgTotal;
    },
  },
  {
    title: "PCR-Test",
    dataIndex: "pcrTotal",
    key: "pcrTotal",
    sorter: (a, b) => {
      return a?.pcrTotal - b?.pcrTotal;
    },
  },
  // { title: "PCR-24", dataIndex: "pcr24Total", key: "pcr24Total" },
  // { title: "PCR-12", dataIndex: "pcr12Total", key: "pcr12Total" },
  // { title: "PCR-6", dataIndex: "pcr6Total", key: "pcr6Total" },
  // { title: "PCR-3", dataIndex: "pcr3Total", key: "pcr3Total" },

  // {
  //   title: "Action",
  //   key: "operation",
  //   render: () => (
  //     <Button size="small" type="primary">
  //       Details
  //     </Button>
  //   ),
  // },
];

export default columns;
