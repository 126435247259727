import { Divider, List, Typography } from "antd";
import React from "react";
import { TestResult } from "generated/schema";
import moment from "moment";
import Timeline from "./Timeline";
import formatDate from "utils/formatDate";
interface Props {
  testResult: TestResult;
}

export const TestResultActivityLog = ({ testResult }: Props) => {
  const registerAt = testResult?.createdAt;
  const testStartAt = testResult.testStartAt;
  const testStartBy = testResult.testStartBy?.name;
  const testResultEnterAt = testResult?.testResultEnteredAt;
  const testFinishedBy = testResult?.testFinishedBy?.name;
  const specimenTookAt = testResult?.specimenAt;
  const specimenTookBy = testResult?.specimenBy?.name;
  const pickUpBy = testResult?.transportJob?.pickedUpBy?.name;
  const pickUpAt = testResult?.transportJob?.createdAt;
  const deliveredAt = testResult?.transportJob?.deliveredAt;
  const deliveredBy = testResult?.transportJob?.deliveredBy?.name;

  const plateAt = testResult?.pcrPlate?.createdAt || "-";
  const plateBy = testResult?.pcrPlate?.createdBy?.name || "-";
  const plateId = testResult?.pcrPlate?.id || "-"

  const laborRequired = testResult?.testType?.laborRequired;

  return (
    <>
      {/* <div>
        <Timeline  testResult={testResult}/>
      </div> */}

      <Divider plain>Testzentrum</Divider>
      <div className="divide-y divide-dashed bg-gray-50 p-2">
        <div className="flex ">
          <div className="flex-1">Registriert um</div>
          <div>{formatDate(registerAt)}</div>
        </div>

        <div className="flex ">
          <div className="flex-1">Test Startzeit</div>
          <div>{testStartAt && formatDate(testStartAt) || "-"} </div>
        </div>

        <div className="flex ">
          <div className="flex-1">Gestartet von </div>
          <div>{testStartBy}</div>
        </div>

        <div className="flex ">
          <div className="flex-1">Testergebnis eingetragen um</div>
          <div>{testResultEnterAt && formatDate(testResultEnterAt) || "-"}</div>
        </div>

        <div className="flex ">
          <div className="flex-1">Testergebnis eingetragen von</div>
          <div>{testResultEnterAt && testFinishedBy || "-"}</div>
        </div>
      </div>

      {laborRequired && (
        <div>
          <div>
            <Divider plain>Kurierdienst</Divider>

            <div className="divide-y divide-dashed bg-gray-50 p-2">
              <div className="flex ">
                <div className="flex-1">Abstrich genommen am</div>
                <div>{specimenTookAt && formatDate(specimenTookAt) || "-"}</div>
              </div>

              <div className="flex ">
                <div className="flex-1">Abstrich genommen von </div>
                <div>{specimenTookBy}</div>
              </div>

              <div className="flex ">
                <div className="flex-1">Abstrich abgeholt am</div>
                <div>{pickUpAt && formatDate(pickUpAt) || "-"}</div>
              </div>

              <div className="flex ">
                <div className="flex-1">Kurierfahrer</div>
                <div>{pickUpBy}</div>
              </div>

              <div className="flex ">
                <div className="flex-1">Laborzustellung am</div>
                <div>{deliveredAt && formatDate(deliveredAt) || "-"}</div>
              </div>

       
            </div>
          </div>

          <div>
            <Divider plain>Labor</Divider>

            <div className="divide-y divide-dashed bg-gray-50 p-2">
              <div className="flex ">
                <div className="flex-1">Laborzustellung angekommen um</div>
                <div>{deliveredAt && formatDate(deliveredAt) || "-"}</div>
              </div>

              <div className="flex ">
                <div className="flex-1">Probe auf Platte ID:</div>
                <div>{plateId}</div>
              </div>
              <div className="flex ">
                <div className="flex-1">Probe auf Platte gesetzt von</div>
                <div>{plateBy}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
