import React, { useContext, useEffect, useState } from "react";

import {
  useTestMeetingsQuery,
  Direction,
  useCreateTestResultMutation,
  useTestMeetingChangedSubscription,
  TestMeeting,
  useTestMeetingsLazyQuery,
} from "generated/schema";
import { PlusIcon, CalendarIcon } from "@heroicons/react/solid";
import { Button, Drawer, Radio, Spin, Grid, Tag } from "antd";
import { MyDrawer } from "components/MyDrawer";
import moment from "moment";
import { AppCtx } from "context/AppState";
import { Input, Space } from "antd";
import { TestCenterSelectList } from "./ListTestCenterSelect";
const { Search } = Input;

const { useBreakpoint } = Grid;

export const TestMeetings = () => {
  const [searchTerm, setSearchTerm]: any = useState();
  const [dataOrder, setDataOrder]: any = useState(Direction.Desc);

  const context = useContext(AppCtx);

  const testCenterId = context?.activeTestCenter?.id;

  const [selectedTestCenterId, setSelectedTestCenterId]: any =
    useState(testCenterId);

  const [getData, { data, error, loading }] = useTestMeetingsLazyQuery({
    fetchPolicy: "network-only",
  });

  const [
    createTestResultMutation,
    {
      data: testResultData,
      loading: testResultLoading,
      error: testResultError,
    },
  ] = useCreateTestResultMutation();

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useTestMeetingChangedSubscription({});

  useEffect(() => {
    const filters = [
      {
        fieldName: "testMeeting.testCreatedAt",
        term: "null",
        exact: true,
      },
    ];

    if (selectedTestCenterId) {
      filters.push({
        fieldName: "testCenter.id",
        term: String(selectedTestCenterId),
        exact: true,
      });
    }

    getData({
      variables: {
        filters,
        orderBy: {
          fieldName: "testMeeting.meetingBookedAt",
          direction: dataOrder,
        },
      },
    });
  }, [customerChangedData, dataOrder, selectedTestCenterId]);

  const getInitialCwaValue = (testMeeting: TestMeeting) => {
    if (testMeeting.cwaAnonym) return "cwaAnonym";
    if (testMeeting.cwaNotAnonym) return "cwaNotAnonym";

    return "cwaNot";
  };

  const handleOnBook = async (x: TestMeeting, testCenterId : string) => {
    let oegdQuestionnaireString = x?.oegdQuestionnaire;
    let oegdQuestionnaireJson = {};

    if (oegdQuestionnaireString) {
      try {
        oegdQuestionnaireJson = JSON.parse(oegdQuestionnaireString);
      } catch (e) {}
    }
    const input: any = {
      name: "",
      testCenterId: testCenterId || x?.testCenter?.id,
      testTypeId: x?.testType?.id,
      testTypeOptionId: x?.testTypeOption?.id,
      customerId: x?.customer?.id,
      meetingId: x?.id,
      cwaOption: getInitialCwaValue(x),
      ...oegdQuestionnaireJson,
    };

    await createTestResultMutation({
      variables: {
        input: {
          ...input,
        },
      },
    });
  };

  const openingTimesHour = Array.from(Array(24).keys());

  const hourRange = openingTimesHour.map((x) => {
    const m = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return m.add(x, "hour");
  });
  const meetingsData = data?.testMeetings || [];

  const onSearch = (e: any) => {
    const value = e?.target?.value;
    setSearchTerm(value);
  };

  let meetingsFilters = meetingsData;
  if (searchTerm) {
    meetingsFilters = meetingsData.filter((x: TestMeeting) => {
      const customerName = x?.customer?.name || "";
      const cName = customerName.toLocaleLowerCase();
      const cSearch = searchTerm.toLocaleLowerCase();
      return cName.includes(cSearch);
    });
  }

  const adminViewCenterSelect =
    context?.user?.role?.id == 1 || context?.user?.role?.id == 2;

  return (
    <div>
      {adminViewCenterSelect && (
        <div className="mb-2">
          <TestCenterSelectList
            defaultValue={selectedTestCenterId}
            onChange={(l: any) => {
              setSelectedTestCenterId(l);
            }}
          />
        </div>
      )}

      {!loading && meetingsData.length == 0 && (
        <div>Keine Termine vorhanden</div>
      )}

      <div className="">
        <Search
          placeholder="Name"
          allowClear
          enterButton="Suche"
          size="middle"
          onChange={onSearch}
        />
        <div className="text-xs mb-2 mt-2 bg-gray-50 flex">
          <div className="flex-1">Anzahl Termine: {meetingsFilters.length}</div>

          <div className="flex space-x-2">
            {dataOrder == Direction.Asc && (
              <div
                onClick={() => {
                  setDataOrder(Direction.Desc);
                }}
                className="cursor-pointer"
              >
                Aufsteigend sortieren
              </div>
            )}
            {dataOrder == Direction.Desc && (
              <div
                onClick={() => {
                  setDataOrder(Direction.Asc);
                }}
                className="cursor-pointer"
              >
                Absteigend sortieren
              </div>
            )}
          </div>
        </div>

        {loading && <Spin />}
        <div className="mt-6  space-y-4 ">
          {meetingsFilters.map((x) => {
            const customerName = x?.customer?.name;
            const customerBirth = x?.customer?.dateOfBirth;
            const street = x?.customer?.street;
            const zipCode = x?.customer?.zipCode;
            const city = x?.customer?.city;
            const testTypeName = x?.testType?.name;
            const testCenterName = x?.testCenter?.name;

            const meeting = moment(x?.meetingBookedAt).format("HH:mm");
            const meetingA = moment(x?.meetingBookedAt).format("DD.MM.YYYY");
            const meetingDay = moment(x?.meetingBookedAt).format("dddd");
            const meetingCreatedAt = moment(x?.createdAt).format(
              "DD.MM.YYYY HH:mm"
            );

            const customerCity = street + " " + zipCode + " " + city;

            //const adminView = context?.user?.role?.id == 1 || context?.user?.role?.id == 2

            const adminView = true;

            return (
              <div className=" p-2">
                <div className="text-center bg-gray-100 grid grid-cols-3">
                  <Tag color={x?.testType?.color || "gray"}>
                    MID: {x?.id}, {testTypeName}
                  </Tag>

                  <div className="col-span-2 text-right">{testCenterName}</div>
                </div>

                <div className="   flex items-center">
                  <div className="flex-1 ">
                    <div className=" w-full">
                      <span className="font-bold">{customerName}</span>,{" "}
                      {customerBirth}
                      <div>{customerCity}</div>
                      <div className="text-xs">
                        Termin Erstellt am: {meetingCreatedAt}
                      </div>
                    </div>
                  </div>
                  <div className="text-3xl text-right font-bold">
                    <span className="font-normal text-base">
                      <span className="font-bold">{meetingDay}, </span>
                      {meetingA}{" "}
                    </span>
                    {meeting}
                  </div>
                </div>

                <div className="text-right">
                  {adminView && (
                    <Button
                      onClick={() => handleOnBook(x, testCenterId)}
                      style={{ width: 300 }}
                      type={"primary"}
                      size="small"
                    >
                      <span className="text-xs">({customerName})</span>{" "}
                      <span className="ml-2">Einchecken</span>
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
