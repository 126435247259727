import React, { useState, useContext } from "react";
import { Form, Input, Select, Button, message } from "antd";

import styled from "styled-components";

import {
  User,
  useUpdateTestCustomerMutation,
  useUpdateUserMutation,
  useCreateUserMutation,
  UserGroup,
  Role,
  WorkTime,
  useUpdateWorkTimeMutation,
} from "generated/schema";

import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import moment from "moment";

const success = () => {
  message.success("Erfolgreich");
};

const { Option } = Select;

const errorMessage = "Pflichtfeld!";

interface Props {
  workTime?: WorkTime;
  userGroups?: any;
  testCenters?: any;
  roles?: any;
  onClose?: any;
}

export const WorkTimeForm = ({ workTime, onClose }: Props) => {
  const [formValuesChanged, setFormValuesChanged] = useState(false);
  const [endAt, setEndAt]: any = useState(null);
  const [startAt, setStartAt]: any = useState(null);
  const [form] = Form.useForm();
  const [updateWorkTime, updateReq] = useUpdateWorkTimeMutation();

  const user = workTime?.user;
  const onFinish = async (values: any) => {
    if (workTime)
      updateWorkTime({
        variables: {
          id: workTime.id,
          input: {
            startAt: startAt && moment(startAt).format(),
            endAt: endAt && moment(endAt).format(),
          },
        },
      });

    success();
    onClose && onClose();
  };

  let initValues = {
    ...workTime,
    ...user,
    endAt: workTime?.endAt ? moment(workTime?.endAt) : null,
    startAt: workTime?.startAt ? moment(workTime?.startAt) : null,
  };

  //DatePicker        form.setFieldsValue({ note: "Hi, lady!" });

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    // console.log(date, dateString);

    if (!date) {
      setEndAt(null);
      return;
    }

    setEndAt(moment(dateString, "DD.MM.YYYY HH:mm"));
  };

  const onChangeStartTs: DatePickerProps["onChange"] = (date, dateString) => {
    // console.log(date, dateString);
    console.log(dateString);

    if (!date) {
      setStartAt(null);
      return;
    }
    setStartAt(moment(dateString, "DD.MM.YYYY HH:mm"));
  };

  return (
    <SForm
      onFieldsChange={(e) => {
        setFormValuesChanged(true);
      }}
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initValues}
      scrollToFirstError
    >
      <div className="sm:grid grid-cols-1 sm:grid-cols-2 gap-x-2">
        <div>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              disabled={true}
              allowClear
              autoComplete="off"
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item
            label="E-Mail"
            name="email"
            rules={[{ required: false, message: errorMessage }]}
          >
            <Input
              disabled={true}
              allowClear
              autoComplete="off"
              placeholder="E-Mail"
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name="firstName"
            label="Vorname"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              disabled={true}
              allowClear
              autoComplete="off"
              placeholder="Vorname"
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Nachname"
            rules={[{ required: true, message: errorMessage }]}
          >
            <Input
              disabled={true}
              allowClear
              autoComplete="off"
              placeholder="Nachname"
            />
          </Form.Item>
        </div>
      </div>
      <div>
        <Form.Item
          name="startAt"
          label="Startzeit"
          rules={[{ required: true, message: errorMessage }]}
        >
          <DatePicker
            format={"DD.MM.YYYY HH:mm"}
            showTime
            allowClear
            onChange={onChangeStartTs}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="endAt"
          label="Endzeit"
          rules={[{ required: true, message: errorMessage }]}
        >
          <DatePicker
            format={"DD.MM.YYYY HH:mm"}
            showTime
            allowClear
            style={{ width: "100%" }}
            onChange={onChange}
          />
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          disabled={!formValuesChanged}
          block
          type="primary"
          htmlType="submit"
        >
          Speichern
        </Button>
      </Form.Item>
    </SForm>
  );
};

const SForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 0 0px;
  }
`;
