import { TestCustomer, User } from "generated/schema";
import { StaffForm } from "./Form";

import {
  useRolesQuery,
  useTestCentersQuery,
  useUserGroupsQuery,
} from "generated/schema";
import { Spin } from "antd";
interface Props {
  user?: User;
  onClose?: any;
}

export const UserForm = ({ user, onClose }: Props) => {
  const rolesReq = useRolesQuery({ fetchPolicy: "network-only" });
  const testCentersReq = useTestCentersQuery({ fetchPolicy: "network-only" });
  const userGroupsReq = useUserGroupsQuery({ fetchPolicy: "network-only" });

  if (rolesReq.loading || testCentersReq.loading || userGroupsReq.loading) {
    return <Spin />;
  }

  return (
    <div key={user?.id}>
      <StaffForm
        userGroups={userGroupsReq?.data?.userGroups}
        testCenters={testCentersReq?.data?.testCenters}
        roles={rolesReq?.data?.roles}
        onClose={onClose}
        user={user}
      />
    </div>
  );
};
