import { Select } from "antd";

const { Option } = Select;


interface Props {
  onChange : any
}
export const ActiveSelect = ({onChange} : Props) => {

  const handleChange = (value: string) => {

    if (!value) {
      onChange({
        fieldName: "user.active",
        delete: true,
      });
      return null;
    }

    const filter = {
      fieldName: "user.active",
      term: String(value),
      exact: true,
    };


    onChange(filter);
  };

  return (
    <>
      <Select
        allowClear
        placeholder="Aktiv"
        style={{ width: 100 }}
        onChange={handleChange}
      >
        <Option value={"true"}>Aktiv</Option>
        <Option value={"false"}>Inaktiv</Option>
      </Select>
    </>
  );
};
