import { CreateTestResult } from "components/TestResult/CreateTestResult";
import { TestTableSearch } from "components/TestTable/Search";
import {
  Direction,
  useRoleChangedSubscription,
  useRolesLazyQuery,
  useRoleCountLazyQuery,
  useFilesUploadsLazyQuery,
  useFilesUploadCountLazyQuery,
  useFilesUploadChangedSubscription,
} from "generated/schema";
import { useEffect, useState } from "react";
import { TestLogoTable } from "./Table";
import { FileUploadForm } from "./FileUploadForm";
import { MyDrawer } from "components/MyDrawer";
import { Button } from "antd";
import { TestCustomerForm } from "components/Customer/CustomerForm";
const TABLE_PAGE_SIZE = 12;

export const FilesUploadTableWrapper = () => {
  const [currentTablePage, setCurrentPageTable] = useState(1);

  const onPaginationChange = ({ page, pageSize }: any) => {
    setCurrentPageTable(page);
  };

  const [orderBy, setOrderBy] = useState({
    fieldName: "id",
    direction: Direction.Desc,
  });

  const take = TABLE_PAGE_SIZE;
  const skip = currentTablePage == 1 ? 0 : take * currentTablePage - take;

  const [getFiles, { data, loading }] = useFilesUploadsLazyQuery({
    fetchPolicy: "network-only",
    variables: { orderBy, take, skip },
  });
  const [getDataCount, { data: customerCountData, loading: l }] =
    useFilesUploadCountLazyQuery({ fetchPolicy: "network-only" });

  const {
    data: customerChangedData,
    loading: testResultChangedLoading,
    error: testResultChangedError,
  } = useFilesUploadChangedSubscription({});

  useEffect(() => {
    getFiles();
    getDataCount();
  }, [customerChangedData]);

  const resultData = data?.filesUploads || [];

  return (
    <div>
      <div className="mb-4 ">
        <div className="w-full "></div>

        <div className="flex  justify-end space-x-1">
          <div className="flex justify-end">
            <MyDrawer
              title={"Logo hochladen"}
              triggerElement={
                <Button type="primary">Logo hochladen</Button>
              }
            >
              <FileUploadForm />
            </MyDrawer>
          </div>
        </div>
      </div>

      <TestLogoTable
        dataCount={customerCountData?.filesUploadCount || 0}
        data={resultData}
        loading={loading}
        onPaginationChange={onPaginationChange}
        currentTablePage={currentTablePage}
        pageSize={TABLE_PAGE_SIZE}
      />
    </div>
  );
};
