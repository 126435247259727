import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Image, Button, Form, Input, message, Upload, Alert } from "antd";

import React, { useRef, useState } from "react";
import {
  useUploadFileMutation,
  useCreateFilesUploadMutation,
  useUpdateFilesUploadMutation,
  FilesUpload,
  useUploadPcrResultFileMutation,
} from "generated/schema";

interface Props {
  onClose?: any;
  filesUpload?: FilesUpload;
  pcrPlateId: number
}

export const UploadPcrPlateResultForm = ({ onClose, filesUpload, pcrPlateId }: Props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [form] = Form.useForm();

  const [uploadPcrResultFileMutation, { data, error, loading }] =
    useUploadPcrResultFileMutation();

  const handleFileInput = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const onFinish = async (values: any) => {
    let res = null;

    try {
      if (selectedFile) {
        res = await uploadPcrResultFileMutation({
          variables: { id: pcrPlateId, file: selectedFile },
        });
      }

      if (error && selectedFile) {
        console.log("error during uploading");
        return;
      }
    } catch (e) {
      console.log(e);
    }

    onClose && onClose();
  };

  return (
    <div>
      <div className="mb-4">
        <Alert
          message={
            <div>
              Bitte wählen Sie die PCR-Platte aus, für welche Sie die
              Ergebnisdatei im .txt-Format hochladen möchten. Wählen Sie
              anschließend die .txt Datei aus und starten Sie den Upload.
            </div>
          }
          type="warning"
        />
      </div>
      <Form
        initialValues={filesUpload}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name="csvFile"
          label="CSV-Datei"
          valuePropName="fileList"
          rules={[
            {
              required: filesUpload?.id ? false : true,
              validator: (_, value) => {
                if (filesUpload?.id && !value) return Promise.resolve();
                const file = value.target.files[0];
                const fileType = file?.type;

                const limit = 9 * (1000 * 1024);

                const fileSizeToBig = file?.size > limit;
                console.log("file?.size ", file?.size);

                if (fileSizeToBig) {
                  return Promise.reject(
                    "Datei ist zu groß. Max. erlaubte Datengröße 9MB"
                  );
                }

                if (fileType == "text/csv") {
                  return Promise.resolve();
                } else {
                  form.setFieldValue("csvFile", null);
                  return Promise.reject("Unterstütze Datenformate:  .csv");
                }
              },
            },
          ]}
        >
          <input
            accept=".csv"
            type="file"
            onChange={(e) => handleFileInput(e)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={!!!selectedFile}
            loading={loading}
            block
            type="primary"
            htmlType="submit"
          >
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
