import { WorkTime } from "generated/schema";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment-duration-format";
import { Divider, List, Typography } from "antd";
const data = [
  "Racing car sprays burning fuel into crowd.",
  "Japanese princess to wed commoner.",
  "Australian walks 100km after outback crash.",
  "Man charged over missing wedding girl.",
  "Los Angeles battles huge wildfires.",
];
interface Props {
  workTime: WorkTime;
}
export const TimeElapsed = (workTime: Props) => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const startAt = workTime?.workTime?.startAt;
  const startAtMs = moment(startAt).format("x");
  const startAtMsInt = parseInt(startAtMs);

  const now = moment.utc().valueOf();
  const duration = moment
    .duration((now - startAtMsInt) / 1000, "seconds")
    .format("hh:mm:ss");

  return (
    <div>
      <div className="">Zeit</div>
      <div className="text-xl">{duration}</div>
    </div>
  );
};
