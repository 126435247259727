import { Alert, Button, Divider, Popconfirm } from "antd";
import { AppCtx } from "context/AppState";
import React, { useContext, useState } from "react";

import { useRunDemisAgainMutation } from "generated/schema";
export const DemisAgainReport = ({ onClose, testResult }: any) => {
  const context = useContext(AppCtx);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const roleId = context?.user?.role?.id;

  const isAdmin = roleId == 1 || roleId == 2;
  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await runDemisAgain({
      variables: { id: testResult.id },
    });

    setOpen(false);
    setConfirmLoading(false);
    onClose && onClose();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const [runDemisAgain, { data, loading, error }] = useRunDemisAgainMutation({
    fetchPolicy: "no-cache",
  });

  if (!isAdmin) {
    return <></>;
  }
  return (
    <div className="mt-2">
      <div className="">
        <Popconfirm
          title={"Sind Sie sich sicher?"}
          open={open}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button danger block type="primary" onClick={showPopconfirm}>
            Demis nocheinmal melden
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};
